import React from 'react';
import './InkyPenCart.scss';
import { Link } from 'react-router-dom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
    AddToCartAction,
    DeductFromCartAction,
    FetchCartAtom,
    RemoveAllOfProductFromCartAction,
    ShowCartAtom,
} from '../../store/CartStore';
import { Cart } from '../../Models/Cart';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from '../../store/OverlayStore';
import { ReactComponent as CancelIcon, ReactComponent as PlusIcon } from '../Assets/icons/PLUS.svg';
import { ReactComponent as ArrowIcon } from '../Assets/icons/buttonArrow.svg';
import { ReactComponent as MinusIcon } from '../Assets/icons/MINUS.svg';
import { CartItem, VariantType } from '../../Models/CartItem';
import { showScroll } from '../../utils/utils';
import InkyMageImg from '../../Devon/Components/InkyMageImg/InkyMageImg';
import { LoginStatusAtom } from '../../store/AccountStore';

interface ShowCartItemsProps {
    isScrollable?: boolean;
    fromSuccessCheckout?: boolean;
    CloseCarts?: (a: boolean) => void;
}

export function ShowCartItems({
                                  CloseCarts,
                                  isScrollable = true,
                                  fromSuccessCheckout = false,
                              }: ShowCartItemsProps): JSX.Element {
    const cartAnyType = useAtomValue(FetchCartAtom);

    // Hack to fix types. Remove this when we are in TS strict-mode.
    const cart = cartAnyType as Cart;
    // No cart
    if (!cart) {
        return null;
    }

    // No items in cart
    if (cart.cartItems?.length === 0) {
        return (<h3 className='no-items-in-cart'>No items in cart</h3>);
    }

    


    return <React.Fragment>

        <ShowProductList cartItems={cart.cartItems} isScrollable={isScrollable}
                         CloseCarts={CloseCarts}
                         fromSuccessCheckout={fromSuccessCheckout} />

    </React.Fragment>;
}

// ProductList is a presentational component which can be used either in cart and success checkout page.
// Just pass the array of type CartItem, and it will render the data
// Instead ShowCartItems has access to the API to fetch the cart and pass to ShowProductList to present them.

interface ShowProductListProps {
    isScrollable?: boolean;
    fromSuccessCheckout?: boolean;
    CloseCarts?: (a: boolean) => void;
    cartItems?: CartItem[];
}

const ShowProductList = ({
                             isScrollable = true,
                             fromSuccessCheckout = false,
                             cartItems,
                         }: ShowProductListProps): JSX.Element => {

    const [, removeFromCart] = useAtom(RemoveAllOfProductFromCartAction);
    const [, deductFromCartAction] = useAtom(DeductFromCartAction);
    const [, addToCartAction] = useAtom(AddToCartAction);
    const [showCart,setShowCart ] = useAtom(ShowCartAtom);
    const [checkoutOverlayVisible,setCheckoutOverlayVisible ] = useAtom(CheckoutOverlayVisible);
    
    const uniqueCartItems = []
    
    cartItems?.forEach(item => {
        if (!uniqueCartItems.find(x => x.product.id == item.product.id && x.variant.id == item.variant.id)) {
            uniqueCartItems.push(item);
        }
    });

    function findAmount(productID, variantId): number {
        return cartItems.filter(x => x.product.id == productID && x.variant.id == variantId).length;
    }

    function getTotalPriceForProduct(productID: number, variantId: number, price: Dinero.Dinero): Dinero.Dinero {
        return price.multiply(findAmount(productID, variantId));
    }
    
    function isLessThanOrderAmountIfOrderAmount(cartItem:CartItem){
        return !(cartItem.variant.cartLimit === undefined ||
            cartItem.variant.cartLimit === null ||
            findAmount(cartItem.product.id, cartItem.variant.id) < cartItem.variant.cartLimit)
    }

    return <div className={'cart-items-content'}
                style={{
                    overflowY: isScrollable ? 'auto' : 'hidden', maxHeight: isScrollable ? '40vh' : 'unset',
                    minHeight: isScrollable && '116px',
                }}>
        {uniqueCartItems.map(((cartItem, index) => {
            return <div key={index}
                        className={`cart-item ${index === 0 && 'cart-item-first'} ${fromSuccessCheckout && 'cart-item-success'}`}>
                <Link onClick={(): void => {
                    if(showCart){
                        setShowCart(false);
                    }
                    if(checkoutOverlayVisible){
                        setCheckoutOverlayVisible(false);
                    }
                }} to={'/product/' + cartItem.product.id.toString()} className={'thumbnail'}>
                    <InkyMageImg
                        src={cartItem.product?.thumbnails[0]?.url}
                        alt={'cover for ' + cartItem.product.name}
                        className={'cart-item-thumbnail'}
                        s={{width: 60}}
                        m={{width: 60}}
                        l={{width: 60}}
                        xl={{width: 60}}
                    />
                </Link>
                <Link onClick={(): void => {
                    console.log("yeef",showCart)
                    if(showCart){
                        setShowCart(false);
                    }
                    if(checkoutOverlayVisible){
                        setCheckoutOverlayVisible(false);
                    }
                }} to={'/product/' + cartItem.product.id.toString()} className={'metadata'}>
                    <span className={'distribution-type'}>{cartItem.distributionType}</span>
                    <h3>{cartItem.product.name}</h3>
                </Link>
                <div className={`amount-cart ${cartItem.distributionType === VariantType.Print && 'amount-cart-physical'}`}>
                    {cartItem.distributionType === VariantType.Print && <button
                        className={`button-no-style ${findAmount(cartItem.product.id, cartItem.variant.id) === 1 ? "button-no-style-no-hover" : ""}`}
                        style={{ fill: findAmount(cartItem.product.id, cartItem.variant.id) === 1 ? 'grey' : '' }}
                        onClick={(e) => {
                            e.preventDefault();
                            if (findAmount(cartItem.product.id, cartItem.variant.id) === 1) {
                                return
                            }
                            deductFromCartAction({ productId: cartItem.product.id, variantId: cartItem.variant.id });
                        }
                        }>
                        <MinusIcon width={15} />
                    </button>}
                    <span className='quantity'>
                        {findAmount(cartItem.product.id, cartItem.variant.id)}
                    </span>
                    {cartItem.distributionType === VariantType.Print
                        &&
                        <button
                            className={`button-no-style ${isLessThanOrderAmountIfOrderAmount(cartItem) ? "button-no-style-no-hover" : ""}`}
                            onClick={(e) => {
                                e.preventDefault();
                                addToCartAction({ productId: cartItem.product.id, variantId: cartItem.variant.id });
                            }
                            }
                            style={{fill:(isLessThanOrderAmountIfOrderAmount(cartItem))?'grey':""}}
                            disabled={isLessThanOrderAmountIfOrderAmount(cartItem)}

                        >
                            <PlusIcon width={15} />
                        </button>}

                </div>
                <div className={'price'}>
                    {getTotalPriceForProduct(cartItem.product.id, cartItem.variant.id, cartItem.price).toFormat()}{" "}
                    {cartItem.isOnSale&&(<span>{getTotalPriceForProduct(cartItem.product.id, cartItem.variant.id, cartItem.fullPrice).toFormat()}</span>)}
                </div>
                {!fromSuccessCheckout && <div className={'actions'}>
                    <button aria-label='remove-from-cart' className='button-no-style' onClick={(e) => {
                        e.preventDefault();
                        removeFromCart({ productId: cartItem.product.id, variantId: cartItem.variant.id });
                    }}>
                        <CancelIcon width={20} className={'cart-item-cancel-icon'} />
                    </button>
                </div>}
            </div>;
        }))}
    </div>;
};

interface ShowCartItemsTitleProps {
    isScrollable?: boolean;
    fromSuccessCheckout?: boolean;
}

export const ShowCartItemsTitle = ({ fromSuccessCheckout = false }: ShowCartItemsTitleProps): JSX.Element => {
    return <React.Fragment>

        <div
            className={`cart-headers ${fromSuccessCheckout && 'cart-headers-success'}`}>
            <span className='cart-header-one' style={{ color: fromSuccessCheckout && '#000000' }}>Product</span>
            <span className=''></span>
            <span className='cart-header-two' style={{ color: fromSuccessCheckout && '#000000' }}>Quantity</span>
            <span className='cart-header-three' style={{ color: fromSuccessCheckout && '#000000' }}>Price</span>
        </div>

    </React.Fragment>;
};

export function InkyPenCart({ CloseCarts }) {
    const [showCart, setShowCart] = useAtom(ShowCartAtom);
    const [isLoggedIn] = useAtom(LoginStatusAtom);
    const cartAnyType = useAtomValue(FetchCartAtom);
    const SetLoginOverlay = useSetAtom(LoginOverlayVisible);

    // Checkout actions
    const [, setCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    // Hack to fix types. Remove this when we are in TS strict-mode.
    const cart = cartAnyType as Cart;

    function showTotal(): JSX.Element {
        if (!cart) return;
        if (cart.cartItems?.length == 0) return null;

        return (<div className={'total-cart'}>
            <div className={'subtotal'}>
                <span className={'label-cart'}>Subtotal</span>
                <span className={'value'}>{Cart.getCartTotal(cart).toFormat()}</span>
            </div>
            <div className={'disclaimer cart-tax'}>Taxes Determined at checkout</div>
        </div>);
    }

    function showCheckoutCTA(): JSX.Element {
        if (!cart) return;
        if (cart.cartItems?.length == 0) return;
        return (<div className={'cart-checkout'} onClick={(e): void => {
            e.preventDefault();
            if(isLoggedIn === "LoggedIn"){
                setCheckoutOverlay(true);
                setShowCart(false)
            } else {
                SetLoginOverlay(true)
                setShowCart(false)
                LoginTypeStore.setLoginType(LoginType.Checkout);
            }
            
        }}><Link onClick={e => e.preventDefault()} to={'#'}>Checkout</Link>
            <ArrowIcon fill={'white'} />
        </div>);
    }

    return (<div className={'InkypenCart'} onClick={(e) => {
        e.stopPropagation();
        showScroll();
        setShowCart(false);
    }}>
        <div className={'cart-section'} onClick={e => e.stopPropagation()}>


            {cart.cartItems?.length !== 0 && <span className='cart-order-title'>Cart Order</span>}
            {cart.cartItems?.length !== 0 && <ShowCartItemsTitle />}


            <ShowCartItems CloseCarts={CloseCarts} />


            {showTotal()}
            {showCheckoutCTA()}
            <div className={'cart-cta'} onClick={(e) => {
                e.preventDefault();
                showScroll();
                setShowCart(!showCart);
            }}>
                <button aria-label={'continue-shopping'}>Continue Shopping
                </button>
            </div>
        </div>
    </div>);
}