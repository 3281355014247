import React from 'react';
import apiClient from '../../API/InkyAPI';
import Axios, { CancelTokenSource } from 'axios';
import { KlaviyoMessage } from '../../Models/KlaviyoMessage';
import { Purchase } from '../../Models/Purchase';
import { OrderItem } from '../../Models/OrderItem';
import OrderConfirmationModel from '../../Models/OrderConfirmationModel';
import { VariantType } from '../../Models/CartItem';


interface KlaviyoManagerProps {

}

interface KlaviyoManagerState {
    publicKey: string;
    tokenSource: CancelTokenSource;
    productNames: string[];
    pageName: string;
}

class IsOnListData{
    listId: string;
    isOnList: boolean;
}

class ListData{
    listId: string;
    email: string;
}

class KlaviyoManager extends React.Component<KlaviyoManagerProps, KlaviyoManagerState>{


    constructor(props: KlaviyoManagerProps) {
        super(props);

        this.state = {
            publicKey: "RzdzF7",
            tokenSource: null,
            productNames: [],
            pageName: "cdn.inkypen.co/"
        };
    }

    register(email: string): void{
        this.identify(email);
        //this.state.tokenSource = Axios.CancelToken.source();
        this.setState({
            tokenSource: Axios.CancelToken.source()
        });

        const event = this.CallEvent("NewUser");
        console.log(event);
    }

    async isOnList(email: string): Promise<IsOnListData[]>{
        const encodedEmail = encodeURIComponent(email);
        const fetch = await apiClient.getResponse<IsOnListData[]>(`/klaviyo/IsOnList?email=` + encodedEmail, null);
        return fetch as unknown as IsOnListData[];
    }

    async AddProfileToList(listId: string): Promise<void> {
        const email = await this.GetActiveAccount();
        const params = [];

        const data = new ListData();
        data.listId = listId;
        data.email = email;

        const post = await apiClient.post('/klaviyo/addtolist', data, this.state.tokenSource);
        console.log(post);
    }

    // Use only for non-logged in users
    async AddEmailToList(listId: string, email: string): Promise<void> {
        const data = new ListData();
        data.listId = listId;
        data.email = email;

        const post = await apiClient.post('/klaviyo/addtolist', data, this.state.tokenSource);
        console.log(post);
    }

    async RemoveFromList(listId: string): Promise<void>{
        const email = await  this.GetActiveAccount();

        const data = new ListData();
        data.listId = listId;
        data.email = email;

        const post = await apiClient.post('/klaviyo/removefromlist', data, this.state.tokenSource);
        console.log(post);
    }

    async CallEvent(eventName: string): Promise<void> {
        let email;
        await apiClient.account(this.state.tokenSource)
            .then(res => email = res.email)
            .catch(err => console.error("error getting account: " + err));

        if (email === undefined){
            console.error(`Attempted calling the ${eventName} event without being logged in`);
            return;
        }

        const blob =  {
            "token": this.state.publicKey,
            "event": eventName,
            "customer_properties": {
                "email": email
            }
        };

        await this.sendEvent(this.getParam(JSON.stringify(blob)));
    }

    async sendCustomerRequest(request: string, sEmail: string, reason: string, useAccountEmail: boolean): Promise<void> {
        let email = sEmail;

        if (useAccountEmail){
            await apiClient.account(Axios.CancelToken.source())
                .then(res => email = res.email)
                .catch(err => console.error("error getting account: " + err));

            if (email == undefined){
                console.error(`Attempting to send Customer Request without an email`);
                return;
            }
        }

        const date = Date.now();
        const unixTimestamp = Math.floor(date / 1000);

        const blob = {
            "token": this.state.publicKey,
            "event": "SentCustomerRequest",
            "customer_properties": {
                "$email": email,
            },
            "properties": {
                "reason": reason,
                "message": request,
            },
            "time": unixTimestamp,
        };

        await this.sendEvent(this.getParam(JSON.stringify(blob)));
    }

    async PurchaseEventV2(order: OrderConfirmationModel){
        let email;
        await apiClient.account(Axios.CancelToken.source())
            .then(res => email = res.email)
            .catch(err => console.error("error getting account: " + err));

        if (email == undefined){
            console.error(`Attempted calling Ordered Product event without being logged in`);
            return;
        }

        const allItems = [];
        const experienceItem = [];

        order.orderItems.map((item, i) => {
            experienceItem.push(item);

            const itemBlob = {
                "ProductID": item.productId,
                "SKU": item.product.webSku,
                "ProductName": item.product.name,
                "Quantity": 1,
                "ItemPrice": (item.price / 100).toString(),
                "RowTotal": (item.price / 100).toString(),
                "ProductURL": `https://${this.state.pageName}/product/` + item.product.id,
                "ImageURL": item.product.thumbnails[0]?.url,
                "Categories": "genres",
                "Brand": "",
                "DistributionType": Number(item.distributionType) == 0 ? VariantType.Digital.toString().toUpperCase() : VariantType.Print.toString().toUpperCase(),
                "Series": item.product.series?.title,
                "BillingAddress": order.billingAddress?.address,
                "ShippingAddress": order.shippingAddress?.address,
                "Taxes": order.orderPrices.taxesPaid,
                "Format": item.product.subCategory,
                //"Format": item.variant.
            };
            allItems.push(itemBlob);
        });

        const date = Date.now();
        const unixTimestamp = Math.floor(date / 1000);

        const blob = {
            "token": this.state.publicKey,
            "event": "Placed Order",
            "customer_properties": {
                "$email": email,
                "$first_name": order.billingAddress.firstName,
                "$last_name": order.billingAddress.surName,
            },
            "properties": {
                "$event_id": order.id,
                "$value": (order.totalPrice),
                "Items":[allItems],
            },
            "time": unixTimestamp,
        };
        await this.sendEvent(this.getParam(JSON.stringify(blob)));
    }

    async PurchaseEvent(purchase: Purchase){
        let email;
        await apiClient.account(Axios.CancelToken.source())
            .then(res => email = res.email)
            .catch(err => console.error("error getting account: " + err));

        if (email == undefined){
            console.error(`Attempted calling Ordered Product event without being logged in`);
            return;
        }

        const allItems = [];
        const experienceItem = [];

        purchase.orders.map((order) =>  order.orderItems.map((item, i) => {
            experienceItem.push(item);



            const itemBlob = {
                "ProductID": item.productId,
                "SKU": item.product.webSku,
                "ProductName": item.product.name,
                "Quantity": 1,
                "ItemPrice": (item.price.getAmount() / 100).toString(),
                "RowTotal": (item.price.getAmount() / 100).toString(),
                "ProductURL": `https://${this.state.pageName}product/` + item.product.id,
                "ImageURL": item.product.thumbnails[0]?.url,
                "Categories": "genres",
                "Brand": "",
                "DistributionType": item.distributionType == 0 ? "DIGITAL" : "PRINT",
                "Series": item.product.series?.title,
                "BillingAddress": purchase.billingAddress?.address,
                "ShippingAddress": purchase.shippingAddress?.address,
                "Taxes": (item.tax.getAmount() / 100).toString(),
                "Coupon": purchase.couponCode,
                //"Format": item.variant.
            };

            allItems.push(itemBlob);
        }));

        const date = Date.now();
        const unixTimestamp = Math.floor(date / 1000);

        let tax = (purchase.totalPriceDetails.taxes.getAmount() / 100).toString();

        if (tax.trim() === "" || tax.trim() === "0"){
            tax = "0.00";
        }

        const blob = {
            "token": this.state.publicKey,
            "event": "Placed Order",
            "customer_properties": {
                "$email": email,
                "$first_name": purchase.billingAddress.firstName,
                "$last_name": purchase.billingAddress.surName,
            },
            "properties": {
                "$event_id": purchase.purchaseId,
                "$value": (purchase.totalPrice.getAmount() / 100),
                "$taxes": tax,
                "Items":[allItems],
            },
            "time": unixTimestamp,
        };
        await this.sendEvent(this.getParam(JSON.stringify(blob)));
    }

    async createOrderExperience(item: OrderItem, email: string, firstName: string, lastName: string, orderId: string){
        const blob = {
            "token": this.state.publicKey,
            "event": "Ordered Item",
            "customer_properties": {
                "$email": email,
                "$first_name": firstName,
                "$last_name": lastName
            },
            "properties": {
                "$event_id": item.productId + Math.random(),
                "$value": 9.99,
                "OrderId": orderId,
                "ExperienceID": item.productId,
                "SKU":  "none",
                "ExperienceName": item.product.name,
                "Description": item.product.description,
                "Quantity": 1,
            },
        };

        await this.sendEvent(this.getParam(JSON.stringify(blob)));
    }

   /* async placeOrder(purchase: Purchase){
        let email;
        await apiClient.account(Axios.CancelToken.source())
            .then(res => email = res.email)
            .catch(err => console.error("error getting account: " + err));

        if (email == undefined){
            console.error(`Attempted calling Ordered Product event without being logged in`);
            return;
        }

        const productNames = [];
        const allItems = [];
        const allCategories = [];
        const allProducts = [];

        purchase.orders.map((order) =>  order.orderItems.map((item, i) => {
            console.log("Creating Blob " + i);
            console.log(item.product);

            productNames.push(item.product.name);
            console.log("pusHing productName");
            allItems.push(item);
            console.log("pusHing allitemsName");

            const itemBlob = {
                "ProductID": item.productId,
                "SKU": item.product.webSku,
                "ProductName": item.product.name,
                "Quantity": 1,
                "ItemPrice": (item.price.getAmount() / 100).toString(),
                "RowTotal": (item.price.getAmount() / 100).toString(),
                "ProductURL": "https://www.idwpublishing.com/product/" + item.product.webSku,
                "ImageURL": "",
                "Categories": "Genre",
                "Brand": ""
            };
            console.log("Created Blob");
            allProducts.push(itemBlob);
        }
        ));

        const blob = {
            "token": this.state.publicKey,
            "event": "Placed Order",
            "customer_properties": {
                "$email": email,
                "$first_name": purchase.billingAddress.firstName,
                "$last_name": purchase.billingAddress.surName,
                "$phone_number": purchase.billingAddress.phoneNumber,
                "$address1": purchase.billingAddress.address,
                "$address2": "",
                "$city": purchase.billingAddress.locality,
                "$zip": purchase.billingAddress.postalCode,
                "$region": purchase.billingAddress.administrativeArea,
                "$country": purchase.billingAddress.country
            },
            "properties": {
                "$event_id": purchase.id,
                "$value": (purchase.totalPrice.getAmount() / 100).toString(),
                "Categories": allCategories,
                "ItemNames": productNames,
                "Brands": [""],
                "Discount Code": "none",
                "Discount Value": 0,
                "Items": [allProducts]
            },
            "time": 1387302423
        };

        console.log(util.inspect(blob));

        //await this.sendEvent(this.getParam(blob));
    }*/

  /*  sendEvent(encodedParams: URLSearchParams): void{
        const url = 'https://a.klaviyo.com/api/track';
        const options = {
            method: 'POST',
            headers: {Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded'},
            body: encodedParams
        };
        console.log("Sending Event");
        fetch(url, options)
            .then(res => res.json())
            .then(json => console.log("sending" + json))
            .catch(err => console.error('error:' + err));
    }*/

    async sendEvent(encodedParams: URLSearchParams): Promise<void>{
        const url = 'https://a.klaviyo.com/api/track';
        const options = {
            method: 'POST',
            headers: {Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded'},
            body: encodedParams
        };
        const response = await fetch(url, options);
    }

    identify(email: string): void{
        const blob =  {
            "token": this.state.publicKey,
            "properties": {
                "email": email
            }
        };

        const encodedParams = this.getParam(JSON.stringify(blob));

        const url = 'https://a.klaviyo.com/api/identify';
        const options = {
            method: 'POST',
            headers: {Accept: 'text/html', 'Content-Type': 'application/x-www-form-urlencoded'},
            body: encodedParams
        };

        fetch(url, options)
            .then(res => res.json())
            .then(json => console.log(json))
            .catch(err => console.error('error:' + err));
    }

    getParam(data: string): URLSearchParams{
        console.log("Creating Data");
        const encodedParams = new URLSearchParams();
        encodedParams.set('data', data);
        return encodedParams;
    }

    async GetActiveAccount(): Promise<string>{
        let email;
        await apiClient.account(Axios.CancelToken.source())
            .then(res => email = res.email)
            .catch(err => console.error("Error getting account: " + err));
        return email;
    }
}

const KlaviyoClient = new KlaviyoManager("");
export default KlaviyoClient;