import React, { useState } from 'react';
import './InkyFAQ.scss';
import { ReactComponent as Pink } from '../../Assets/inky-arts/Banner_Pink_Halftone_InkyPen.svg';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/Chevron_InkyPen.svg';
import { InkyPenNavbar } from '../../InkyPenNavbar/InkyPenNavbar';
import InkyPenLabeledInput from '../../InkyPenUIKit/InkyPenLabeledInput/InkyPenLabeledInput';
import InkyPenEmail from '../../InkyPenUIKit/InkyPenEmail/InkyPenEmail';
import InkyPenSubmit from '../../InkyPenUIKit/InkyPenSubmit/InkyPenSubmit';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import InkyAPI from '../../../API/InkyAPI';
import { isEmail } from '../../../utils/utils';

const InkyFAQ = () => {
    const history = useHistory();
    const [data, setData] = useState({ email: '', feedback: '' });
    const [error, setError] = useState(null);

    const goToLastPage = () => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    const postFeedbackData = () => {
        if (!isEmail(data.email)) {
            setError('The email is not correct');
            return;
        }
        InkyAPI.postFeedback(data.email, data.feedback).then(result => {
            console.log('postFeedbackData', result);
        }).catch(error => {
            console.log('postFeedbackData', error);
        });
    };

    return (
        <div className='inky-faq-container'>
            <InkyPenNavbar />
            <Pink className='inky-faq-pink' />

            <div className='inky-faq-max-wrapper'>

                <div className='inky-back-wrapper'>
                    <span className='inky-back' onClick={goToLastPage}>
                        <Chevron className='inky-back-icon' />
                        <span className='inky-back-text'>
                            Back
                        </span>
                    </span>
                </div>

                <h1 className='inky-title'>Frequently Asked Questions</h1>

                <div className='faq-wrapper'>
                    <p>After our launching of the service there are a lot of valid questions and concerns people have
                        been
                        asking which we've assembled here:</p>

                    <h5>Which Platforms are you on?</h5>
                    <p>
                        Nintendo Switch™ and STEAM®. We plan to launch on additional platforms going forward.
                    </p>


                    <h5> Is InkyPen cross-platform?</h5>
                    <p>
                        Yes! Your InkyPen account and subscription may be used on all platforms where InkyPen is
                        available.
                    </p>

                    <h5>Do you restrict content by territory?</h5>
                    <p>We generally don't in most cases and always ask publishers if they can give us global rights. In
                        cases where we only have rights for a particular region for desirable content we do
                        geo-restrict.</p>

                    <h5>Any free stuff?</h5>
                    <p>
                        Yes! We do have free content available that you can read on the Nintendo Switch™ or on STEAM®.
                        You can try out the app without even signing in. We do not right now have a free month trial of
                        all content however. The service is in its infancy and part of our philosophy is ensuring
                        artists and publishers get paid appropriately for their content. We do aim to offer more free
                        content and a trial period down the line.
                    </p>

                    <h5>Do you have Manga?</h5>
                    <p>
                        <span style={{ textDecoration: 'line-through' }}>
                            Very little at this moment but we are working on it!</span> YES!
                        We have quite the collection. Check our catalogue and select 'manga' as type to see full
                        catalogue.
                    </p>

                    <h5>Hey! You didn't answer the question I wanted, what now?</h5>
                    <p>No problem! Simply reach out to us at <a href='mailto:contact@inky-pen.com' className='mail-to-inky-support'>contact@inky-pen.com</a> and we will follow up soon!</p>
                    <br />


                    {/*<div className='text-center'>*/}
                    {/*    <h3>Technical problem or feedback?</h3>*/}
                    {/*</div>*/}

                    {/*<div className='send-feedback-wrapper'>*/}
                    {/*    <InkyPenLabeledInput label={'Email'}>*/}
                    {/*        <input className='input-email-faq' value={data.email}*/}
                    {/*               onFocus={() => setError(null)}*/}
                    {/*               onChange={(e) => setData({ ...data, email: e.target.value })}*/}
                    {/*               placeholder={'Enter your email address here'} />*/}
                    {/*    </InkyPenLabeledInput>*/}

                    {/*    <span className='error-faq'>*/}
                    {/*        {error}*/}
                    {/*    </span>*/}

                    {/*    <div className='send-feedback-input'>*/}
                    {/*        <textarea value={data.feedback}*/}
                    {/*                  onChange={(e) => setData(data => ({ ...data, feedback: e.target.value }))}*/}
                    {/*                  placeholder={'Describe your issue'} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    {/*<div className='send-wrapper'>*/}
                    {/*    <button onClick={postFeedbackData} className='send'>*/}
                    {/*        Submit*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>

            </div>
        </div>
    );
};

export default InkyFAQ;
