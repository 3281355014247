import { Priceable } from './Product/Priceable';
import { Thumbnail } from './Thumbnail';
import { Readable, ReadableDTO } from './Product/Readable';
import { IpTag } from './IpTag';
import { Variant, VariantDTO } from './Product/Variant';
import { Shippable } from './Product/Shippable';
import { Creator } from './Creator';
import { Category } from './Category';
import { DateTime } from 'schema-dts';
import dayjs, { Dayjs } from 'dayjs';
import Dinero from 'dinero.js';
import { AssetLinkGroup } from './AssetLinkGroup';
import Log from '../InkyPenV1/Services/Logger';
import { BaseSeries } from './Series';
import { VariantType } from './CartItem';
import { Downloadable, DownloadableDTO } from './Product/Downloadable';


export class Product {
    id: number;
    ipTags?: IpTag[];
    variants?: Variant[];
    chapters?: Product[];
    volumeId?: number;
    //DateTime TimeCreated
    //DateTime LastEdited
    //string CreatedBy
    //string EditedBy
    name: string;
    relativeName: string;
    description: string;
    ageRating: string;
    published: boolean;
    thumbnails: Thumbnail[] = [];
    creators: Creator[] = [];
    creatorsToString(): string {
        if(!this.creators ||this.creators.length < 0){
            return null;
        }
        return this.creators.map(x => x.name).join(",");
    }
    //ICollection<BundleItem> BundleItems
    publishDate: Dayjs;
    removed: boolean;
    series: BaseSeries;
    //DateTime? RemoveDate
    category?: Category;
    //SubCategory? SubCategory
    subCategory?: string;
    webSku: string;
    AndroidSku: string;
    appleSku: string;
    publishToApple: boolean;
    PublishToAndroid: boolean;
    PublishToWeb: boolean;
    diamondCode: string;
    tags?: string[];
    readableId?: number;
    readable: Readable;
    playableId?: number;
    //Playable Playable
    DownloadableId?: number;
    downloadable: Downloadable;
    shippableId?: number;
    shippable: Shippable;
    merchId?: number;
    //Merch Merch
    priceableId?: number;
    priceable?: Priceable;

    chapterNumber?: number;
    volumeNumber?: number;
    readableUrl?: string;
    PreviewableId?: number;
    //Previewable Previewable
    AgeRestrictableId?: number;
    //AgeRestrictable AgeRestrictable
    geoRestrictableId?: number;
    //GeoRestrictable GeoRestrictable
    bundlerId?: number;
    assetLinkGroups: AssetLinkGroup[];
    trim?: string;

    //Components.Bundler Bundler

    toApi(): ProductDTO {
        return Object.assign({}, this, {
            variants: this.variants?.map(v => v.toApi()),
            category: this.category.name,
            categoryId: this.category.Id,
            publishDate: this.publishDate.toISOString(),
            readable: this.readable?.toApi(),
            chapters: this.chapters.map(x => x.toApi())
        });
    }



    static createFromApi(dto: ProductDTO): Product {
        return Object.assign(new Product(), dto, {
            variants: dto.variants?.map(x => Variant.createFromApi(x)),
            category: Category.createFromApi(dto.categoryId, dto.category),
            publishDate: dayjs(dto.publishDate),
            readable: Readable.createFromApi(dto.readable),
            chapters: dto.chapters?.map(x => Product.createFromApi(x)) ?? [],
        });
    }

    lowestPrice(): Dinero.Dinero {
        const prices = this.variants.map(x => x.price);
        return Dinero.minimum(prices);
    }

    canReadDigitalByUser(userIsLoggedIn: boolean): boolean {
        if (this.readable.isOwned) {
            return true;
        }
        const digitalPriceType = this.getDigitalVariant()?.priceType;

        return digitalPriceType === 'Free'
            || digitalPriceType === 'FreeToReadAndPurchaseble'
            || (userIsLoggedIn && digitalPriceType === 'FreeForRegistered')
            || (userIsLoggedIn && digitalPriceType === 'FreeToReadForRegisteredAndPurchaseble');
    }

    canReadDigitalByAnyUser(): boolean {
        const digitalPriceType = this.getDigitalVariant()?.priceType;

        return digitalPriceType === 'Free'
            || digitalPriceType === 'FreeToReadAndPurchaseble'
            ||digitalPriceType === 'FreeForRegistered'
            ||  digitalPriceType === 'FreeToReadForRegisteredAndPurchaseble';
    }

    canDigitalBePurchased(userIsLoggedIn: boolean): boolean {
        if (this.readable.isOwned) {
            return false;
        }

        const digitalPriceType = this.getDigitalVariant()?.priceType;

        return digitalPriceType === 'Paid'
            || (userIsLoggedIn && digitalPriceType === 'FreeToReadAndPurchaseble')
            || (userIsLoggedIn && digitalPriceType === 'FreeToReadForRegisteredAndPurchaseble');
    }

    getDigitalVariant(): Variant {
        if (this.variants?.length > 0) {
            const digitalVariants = this.variants.filter(x => x.type === 'Digital');

            if (digitalVariants.length > 0) {
                return digitalVariants[0];
            }
        }

        return null;
    }

    getDefaultVariant(): Variant {
        if (this.variants?.length > 0) {
            return this.variants[0];
        }

        Log.warn(`Product ${this.id} ::: ${this.name} has no variants!`);
        console.log(this);
        return null;
    }

    getPrintVariant(): Variant {
        if (this.variants?.length > 0) {
            const variants = this.variants?.filter(x => x.type === VariantType.Print);

            if (variants.length > 0)
                return variants[0];

            else return null;
        }

        return null;
    }

    hasChapters(): boolean {
        return (this.chapters?.length > 0) ?? false;
    }

    /**
     * For Volumes, the series, otherwise, the Trilogy Name.
     */
    getName() {
        if(this.subCategory === "Volume") {
            return this.series.title
        }

        return this.name;
    }

    /**
     * For volumes, the Volume Number, otherwise null
     */
    getSubName() {
        if(this.subCategory === "Volume") {
            return `Volume ${this.volumeNumber}`;
        }

        return null;
    }
}

export interface ProductDTO {
    id: number;
    ipTags?: IpTag[];
    variants?: VariantDTO[];
    chapters?: ProductDTO[];
    //DateTime TimeCreated
    //DateTime LastEdited
    //string CreatedBy
    //string EditedBy
    name: string;
    relativeName: string;
    description: string;
    published: boolean;
    thumbnails: Thumbnail[];
    creators: Creator[];
    //ICollection<BundleItem> BundleItems
    publishDate: DateTime;
    removed: boolean;
    //DateTime? RemoveDate
    category: string;
    categoryId: number;
    //SubCategory? SubCategory
    webSku: string;
    AndroidSku: string;
    appleSku: string;
    publishToApple: boolean;
    PublishToAndroid: boolean;
    PublishToWeb: boolean;
    diamondCode: string;
    tags?: string[];
    readableId?: number;
    readable: ReadableDTO;
    playableId?: number;
    //Playable Playable
    DownloadableId?: number;
    downloadable: DownloadableDTO;
    shippableId?: number;
    shippable: Shippable;
    merchId?: number;
    //Merch Merch
    priceableId?: number;
    priceable?: Priceable;
    PreviewableId?: number;
    //Previewable Previewable
    AgeRestrictableId?: number;
    //AgeRestrictable AgeRestrictable
    geoRestrictableId?: number;
    //GeoRestrictable GeoRestrictable
    bundlerId?: number;

    readableUrl?: string;
    //Components.Bundler Bundler
}
