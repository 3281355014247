import { Creator } from '../Models/Creator';
import { InkypenColors } from './constants';
import { DiscoverItem, WheelContent } from '../Models/DiscoverItem';
import { Comic } from '../Models/Comic';
import dayjs, { Dayjs } from 'dayjs';
import { Product } from '../Models/Product';
import { MutableRefObject } from 'react';
import { Series } from '../Models/Series';
import { Article } from '../Models/Article';
import { Cart } from '../Models/Cart';
import { AssetLinkGroup } from '../Models/AssetLinkGroup';
import { VariantType } from '../Models/CartItem';

export const mapCreators = (creators: Creator[]): string[] => {

    const leadAuthors = creators.filter(value => value.title === "Lead Author");

    return leadAuthors?.map((creator, index) => {
        if (leadAuthors.length === 1) {
            return creator.name;
        } else if ((leadAuthors.length === 2 && index === 0) || (leadAuthors.length === index + 2)) {
            return creator.name + ' and ';
        }
        if (leadAuthors.length === index + 1) {
            return creator.name;
        } else {
            return creator.name + ', ';
        }
    });
};

export function getRandomColor(): string {
    // return 'hsl(' + Math.random() * 360 + ', 100%, 75%)';
    const values = Object.values(InkypenColors);
    const randomIndex = Math.floor(Math.random() * (values.length - 1));
    return values[randomIndex];
}

export function getRandomColorByIndex(index: number): string {
    const keys = Object.keys(InkypenColors).filter(key => key !== 'transparent');
    return keys[index % 8];
}

export function getSeriesColor(Key: string): string {
    // return InkypenColors[Key] ?? InkypenColors['green'];
    return InkypenColors[Key];
}

export const BlueColorForNotImageFound = (): 'rgb(20, 191, 161)' => {
    return 'rgb(20, 191, 161)';
};


export function validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function getScrollbarWidth(): number {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.width = '100px';
    // @ts-ignore
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    outer.style.overflow = 'scroll';

    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}


export const hideScroll = (): void => {
    document.querySelector('html').style.paddingRight = getScrollbarWidth().toString() + 'px';
    document.querySelector('html').style.overflow = 'hidden';
    document.querySelector('html').classList.add('no-scroll');
    document.body.style.overflow = 'hidden';
    document.body.classList.add('no-scroll');
    document.body.classList.remove('scroll');
};

export const showScroll = (): void => {
    document.querySelector('html').style.paddingRight = '0px';
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('html').classList.remove('no-scroll');
    document.body.style.overflow = 'unset';
    document.body.classList.remove('no-scroll');
    document.body.classList.add('scroll');
};

export function convertComicToDiscoverItem(comic: Comic): DiscoverItem {
    const content = new WheelContent();
    content.title = comic.title;
    content.id = comic.id;
    content.description = comic.description;
    content.tags = comic.tags;
    content.thumbnails = comic.thumbnails;
    content.url = '';
    content.ageRating = comic.ageRating;
    return {
        type: 'Product',
        displayType: comic.BookType,
        content: content,
    };
}


// Convert Wed Oct 26 2022 to Oct. 26, 2022
const shortenedMonths = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];
export const convertDate = (date: Date | string | Dayjs) => {
    const month = shortenedMonths[parseInt(dayjs(date).format('MM')) - 1];
    return month + (month !== 'May' ? '. ' : ' ') +
        dayjs(date).format('DD, YYYY');
};

export function getTransformMatrix(transform: string): number[] {
    return transform.match(/^matrix\((.+)\)$/)[1].split(',').map(Number);
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const LockBodyScroll = (elementIsOpen?: boolean): void => {
    const originalStyle: string = window.getComputedStyle(
        document.body,
    ).overflow;
    if (elementIsOpen) {
        document.body.style.overflow = 'hidden';
        document.ontouchmove = function(event) {
            event.preventDefault();
        };
    } else {
        document.body.style.removeProperty('overflow');
        document.ontouchmove = function() {
            return true;
        };
    }

    // useLayoutEffect((): (() => void) => {
    //
    //     return () => {
    //         document.body.style.overflow = originalStyle;
    //         document.body.style.overflow = "visible";
    //         document.ontouchmove = function () {
    //             return true;
    //         };
    //     };
    // }, [elementIsOpen]);
};


export function timeRemaining(ptime): string {
    const currentTime = new Date().getTime();
    const twoMinutes = 2 * 60 * 1000;
    const timePassed = currentTime - ptime;
    const timeRemaining = twoMinutes - timePassed;
    const minutes = Math.floor(timeRemaining / 60000);
    const seconds = Math.floor((timeRemaining % 60000) / 1000);
    if (minutes <= 0 && seconds <= 0) {
        return '00:00';
    }
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export function getCurrentTime(currentTime: number): string {
    const date = new Date(currentTime);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}


export const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(email);

export function validatePassword(password: string): boolean {
    const regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
}


const sortItemAlphabetically = (reverse: boolean, myBooks: Comic[]): Comic[] => {
    console.log('sorting item alphabetically');
    const array = myBooks.sort((a, b) => {
        if (a.seriesName < b.seriesName) {
            return -1;
        } else if (a.seriesName > b.seriesName) {
            return 1;
        } else {
            if (a.volumeNumber !== undefined && b.volumeNumber !== undefined || a.volumeNumber !== b.volumeNumber)
                return a.volumeNumber - b.volumeNumber;
            else
                return a.chapterNumber - b.chapterNumber;
        }
    });

    if (reverse) {
        array.reverse();
    }
    return array;
};

const sortItemsChronologically = (reverse: boolean, myBooks: Comic[]): Comic[] => {
    const array = myBooks as unknown as Product[];
    const sortedArray = array.sort((a, b) => {
        if (a.publishDate > b.publishDate)
            return -1;
        else
            return 1;
    });

    if (reverse) {
        sortedArray.reverse();
    }

    sortedArray.map(c => console.log(c.publishDate, c.series?.title));

    return sortedArray as unknown as Comic[];
};


export const sortVolumeItems = (ascend: boolean, volumes: Product[]): Product[] | undefined => {
    if (!volumes) {
        return undefined;
    }
    if (ascend) {
        return volumes.sort((a, b) => {
            const nameA = a.volumeNumber;
            const nameB = b.volumeNumber;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    } else {
        return volumes.sort((a, b) => {
            const nameA = a.volumeNumber;
            const nameB = b.volumeNumber;
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
        });
    }
};

export const sortChapterItems = (ascend: boolean, chapters: Product[]): Product[] | undefined => {
    if (!chapters) {
        return undefined;
    }
    if (ascend) {
        return chapters.sort((a, b) => {
            const nameA = a.chapterNumber;
            const nameB = b.chapterNumber;
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    } else {
        return chapters.sort((a, b) => {
            const nameA = a.chapterNumber;
            const nameB = b.chapterNumber;
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
        });
    }
};

export function queryThroughComics(query: string, myBooks: Comic[]): Comic[] {
    const array = myBooks.filter(comic => comic.title.toLowerCase().includes(query.toLowerCase()));
    return array;
}

export function queryThroughComicsInkypenBookshelf(query: string, myBooks: Comic[]): Comic[] {
    const array = myBooks.filter(comic => {
        return comic.title.toLowerCase().includes(query.toLowerCase()) || comic.seriesName.toLowerCase().includes(query.toLowerCase())
    });
    return array;
}

export function changeSortingOfComic(sorting: string, items: Comic[], query: string): Comic[] {
    let comicArray = [];
    console.log(sorting);

    switch (sorting) {
        case 'A - Z':
            comicArray = sortItemAlphabetically(false, items);
            break;
        case 'Z - A':
            comicArray = sortItemAlphabetically(true, items);
            break;
        case 'Youngest To Oldest':
            comicArray = sortItemsChronologically(false, items);
            break;
        case 'Oldest To Youngest':
            comicArray = sortItemsChronologically(true, items);
            break;
        case 'Newest To Oldest':
            comicArray = sortItemsChronologically(false, items);
            break;
        case 'Oldest To Newest':
            comicArray = sortItemsChronologically(true, items);
            break;
        /*        case 'Publish Date Ascending':
                    comicArray = sortItemsChronologically(false, items);
                    break;
                case 'Publish Date Descending':
                    comicArray = sortItemsChronologically(true, items);
                    break;*/
    }
    return queryThroughComics(query, comicArray);
}


export const removeFromArray = (array: unknown[], item: unknown): void => {
    const index = array.indexOf(item);
    if (index > -1) { // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
    }
};


export const scrollToElement = (id: string | MutableRefObject<any>) => {
    let itemRef;
    if (typeof id === 'string') {
        itemRef = document.getElementById(id);
    } else {
        // Pass ref here: scrollToElement(availableRef.current);
        itemRef = id;
    }
    console.log('availableRef.current 2', itemRef);
    itemRef?.scrollIntoView({ behavior: 'smooth' });
};

export const scrollToItem = (id: string | MutableRefObject<any>, offset: number, animationType?: 'instant' | 'smooth'): void => {
    let itemRef;
    if (typeof id === 'string') {
        itemRef = document.getElementById(id);
    } else {
        // Pass ref here: scrollToElement(availableRef.current);
        itemRef = id;
    }
    const headerOffset = offset;
    const elementPosition = itemRef.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: animationType ?? 'smooth',
    });
};

export const isTheChapterPurchased = (books: Comic[], chapter: Comic | Product, productID: number): boolean => {
    if (!chapter) {
        return;
    }
    return books.some(book => {
        return book.id === chapter.id || book.id === productID;
    });
};

export const isTheChapterPurchasedById = (books: Comic[], chapterId: number, productID: number): boolean => {
    return books.some(book => {
        return book.id === chapterId || book.id === productID;
    });
};

export const isTheChapterProgressFinished = (chapter: Product): boolean => {
    // console.log('finix 2', chapter.chapterNumber, chapter.readable.readingProgress, chapter.readable.pageCount);
    // console.log('finix 2', chapter.readable.readingProgress / (chapter.readable.pageCount - 1));
    return chapter.readable.readingProgress / (chapter.readable.pageCount - 1) < 1;
};

export const isTheVolumePurchased = (books: Comic[], productID: number): boolean => {
    if (books.length == 0) return false;
    return books?.some(book => book.id === productID);
};

export const isTheVolumeInCart = (cart: false | Cart, product: Product): boolean => {
    if (!(cart as Cart)?.cartItems) {
        return false;
    }
    return (cart as Cart)?.cartItems?.some(item => item.productId === product?.id);
};

export const isTheVolumeInCartDigital = (cart: false | Cart, product: Product): boolean => {
    if (!(cart as Cart)?.cartItems) {
        return false;
    }
    return (cart as Cart)?.cartItems?.some(item => (item.variant.type === VariantType.Digital && item.productId === product?.id));
};

export const isTheVolumeInCartPhysical = (cart: false | Cart, product: Product): boolean => {
    if (!(cart as Cart)?.cartItems) {
        return false;
    }
    return (cart as Cart)?.cartItems?.some(item => (item.variant.type === VariantType.Print && item.productId === product?.id));
};

export const isTheVolumeInCartById = (cart: false | Cart, productId: number): boolean => {
    if (!(cart as Cart)?.cartItems) {
        return false;
    }
    return (cart as Cart)?.cartItems?.some(item => item.productId === productId);
};

export const isTheChapterInCart = (cart: false | Cart, chapter: Product | Comic): boolean => {
    if (!(cart as Cart)?.cartItems) {
        return false;
    }
    return (cart as Cart)?.cartItems?.some(item => item.productId === chapter?.id);
};

export const isTheChapterInCartByVolume = (cart: false | Cart, chapter: Comic | Product, productID: number): boolean => {
    if (!(cart as Cart)?.cartItems || !chapter) {
        return false;
    }

    return (cart as Cart)?.cartItems.some(book => {
        return book.productId === chapter.id || book.productId === productID;
    });
};

export const isProductInProcessingBooks = (myProcessingBooks, productId: number) => {
    if (!myProcessingBooks) {
        return false;
    }
    return myProcessingBooks?.some(p => p.id === productId);
};

export function findPurchasedChapter(products, myBook): Comic | Product {
    let purchasedChapter = null;

    products?.reduce(function(result, p) {
        if (purchasedChapter) {
            return result;
        }

        const chapter = p.chapters?.find(c => {
            return isTheChapterPurchased(myBook, c, p.id) && isTheChapterProgressFinished(c);
        });


        if (chapter) {
            purchasedChapter = chapter;
        }

        return result;
    }, null);

    return purchasedChapter;
}

export function findFreeChapters(products: Product[], isLoggedIn): Product {
    return products?.find(p => {
        return p.chapters?.find(c => (c.variants[0]?.priceType === 'Free' || (isLoggedIn && c.variants[0]?.priceType === 'FreeForRegistered')) && isTheChapterProgressFinished(c));
    })?.chapters.find(c => (c.variants[0]?.priceType === 'Free' || (isLoggedIn && c.variants[0]?.priceType === 'FreeForRegistered')) && isTheChapterProgressFinished(c));
}

export function findFreeVolumes(products: Product[], isLoggedIn): Product {
    return products?.find(p => {
        return (p.variants[0]?.priceType === 'Free' || (isLoggedIn && p.variants[0]?.priceType === 'FreeForRegistered')) && isTheChapterProgressFinished(p);
    });
}

export function findPurchasedVolume(products: Product[], myBook): Product {
    // return products?.find(p => {
    //     return myBook?.some(b => b.id === p.id && p.chapters?.find((c) => isTheChapterProgressFinished(c)));
    // });
    return products?.find(p => {
        return myBook?.some(b => b.id === p.id && isTheChapterProgressFinished(p));
    });
}

export function formTheAssetGroup(items: AssetLinkGroup[]) {
    return items.reduce(
        (result, item) => {
            const [otherFilesArray, audioFilesArray] = result;
            if (item.name === 'Audio Files') {
                return [[...otherFilesArray], [...audioFilesArray, item]];
            } else {
                return [[...otherFilesArray, item], [...audioFilesArray]];
            }
        },
        [[], []] as [AssetLinkGroup[], AssetLinkGroup[]],
    )?.reverse();
}

export const isiOS = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        return true;
    }
};

export function clearBrowseData() {
    sessionStorage.removeItem('currentPage');
    sessionStorage.removeItem('active');
    sessionStorage.removeItem('activeButton');
    sessionStorage.removeItem('genreIds');
    sessionStorage.removeItem('sortOrder');
    sessionStorage.removeItem('ageRatings');
    sessionStorage.removeItem('includeSeries');
    sessionStorage.removeItem('selectedStatus');
}

// export function readableUrl(item: Product | Series): Product {
//     return item.
// }