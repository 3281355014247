import React, { useEffect, useState } from 'react';
import { Link } from '../../Devon/Components/InkyLink';
import { useAtom } from 'jotai/index';
import { AccountOverlayVisible, LoginOverlayVisible } from '../../store/OverlayStore';
import '../InkyPenAuth/InkyPenAuth.scss';
import './AccountSidePanel.scss';
import { ReactComponent as CancelIcon } from '../Assets/icons/PLUS.svg';
import { AccountInfoAtom, LoginStatusAtom, LogoutAtom } from '../../store/AccountStore';
import { useCounter } from '../Hooks/useCounter';
import { showScroll } from '../../utils/utils';
import { ResendVerification } from '../InkyPenAuth/InkyPenLogin/InkyPenLogin';
import { ReactComponent as AccountIcon } from '../Assets/inky-icons/Account_InkyPen.svg';


const AccountSidePanel = (props) => {
    const [, setAccountOverlayVisible] = useAtom(AccountOverlayVisible);
    const [step, setStep] = useState<number | string>(0);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [, logout] = useAtom(LogoutAtom);
    const [accountInfo] = useAtom(AccountInfoAtom);
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [sendActive, setSendActive] = useState<boolean>(false);
    const { startTimer, sentTime, status } = useCounter('verification_time');


    const onMenuItemClicked = () => {
        setAccountOverlayVisible(false);
        showScroll();
    };

    const onLogOutClicked = () => {
        logout();
        onMenuItemClicked();
        setAccountOverlayVisible(false);
    };


    const onEmailResendClicked = (): void => {
        setSendActive(false);
        if (typeof status === 'boolean') {
            ResendVerification().then(() => {
                startTimer();
            }).catch(e => {
                console.log(e);
                setSendActive(true);
            });
        }
    };

    useEffect(() => {
        status === false && setSendActive(true);
    }, [status]);


    return (
        <div className='auth-backdrop' onMouseDown={(e) => {
            e.stopPropagation();
            setAccountOverlayVisible(false);
        }}>


            <div className='auth-modal-container' onMouseDown={(e) => e.stopPropagation()}>
                <div className='auth-header-wrapper'>
                    <h3 className='auth-header-text'>MENU</h3>
                    <div className='auth-close-wrapper'
                         onMouseDown={(e) => {
                             e.stopPropagation();
                             setAccountOverlayVisible(false);
                         }}>
                        <CancelIcon fill={'white'} className='auth-close-icon' />
                    </div>
                </div>


                <div className='complete-menu-accounts'>

                    {loginStatus !== 'LoggedIn' ?

                        <React.Fragment>
                            <div onClick={() => setLoginOverlay(true)} className='complete-menu-accounts-nav'>
                                <span>Log In</span></div>
                            <Link onClick={onMenuItemClicked} to={'/library'}
                                  className='complete-menu-accounts-nav-link'><span>Library</span></Link>
                        </React.Fragment>

                        :

                        <React.Fragment>
                            <AccountIcon className={'account-icon'} />
                            {/*<div className='complete-new-menu-greeting'>*/}
                            {/*    Username*/}
                            {/*</div>*/}
                            <div className='complete-new-menu-greeting complete-new-menu-greeting-email'>
                                {accountInfo.email}
                            </div>
                            {/*{accountInfo.verified === false && <div className='account-verify-message-inner-wrapper'>*/}
                            {/*<span className='account-verify-message-text'>*/}
                            {/*    Your account has not been verified.*/}
                            {/*</span>*/}

                            {/*    <InkyPenArrow onClick={onEmailResendClicked}*/}
                            {/*                  active={sendActive}*/}
                            {/*                  text={typeof status !== 'boolean' ? 'EMAIL HAS BEEN SENT' : 'SEND VERIFICATION EMAIL'}*/}
                            {/*                  withArrow={false} />*/}
                            {/*</div>}*/}
                            <div className='complete-menu-accounts-nav'>
                                <Link onClick={onMenuItemClicked} to={'/account'}
                                      className='complete-menu-accounts-nav-link complete-menu-accounts-nav-link-top'>
                                    <span>Account</span>
                                </Link>
                            </div>
                            {/*{loginStatus !== 'LoggedIn' && <Link onClick={onMenuItemClicked} to={'/library'}*/}
                            {/*                                     className='complete-menu-accounts-nav-link'><span>Library</span></Link>}*/}
                            {/*{accountInfo?.roles?.includes('admin') && <Link onClick={onMenuItemClicked} to={'/dashboard'}*/}
                            {/*                                                className='complete-menu-accounts-nav-link complete-menu-accounts-nav-dashboard'><span>Dashboard</span></Link>}*/}
                            <div onClick={onLogOutClicked}
                                 className='complete-menu-accounts-nav-link complete-menu-accounts-nav-link-logout'>
                                <span>Log Out</span>
                            </div>
                        </React.Fragment>
                    }

                </div>


                {/*{typeof step !== 'string' &&*/}
                {/*    <div className={`auth-modal-cancel-wrapper ${step !== 0 ? 'auth-modal-cancel-wrapper-back' : ''}`}>*/}

                {/*        <div className='guide-title'>LEARN MORE</div>*/}
                {/*        <Link target={'_blank'} to='/' className='guide-text'>VISIT OUR FAQ</Link>*/}


                {/*        <div className='guide-title guide-title-margin'>NEED HELP</div>*/}
                {/*        <Link target={'_blank'} to='/' className='guide-text'>CONTACT US</Link>*/}
                {/*    </div>*/}
                {/*}*/}

            </div>
        </div>
    );
};


export default AccountSidePanel;