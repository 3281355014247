import { Thumbnail } from './Thumbnail';

/**
 * Model used for news and news-articles
 * @param {number} id - The ID. Should be used in the URL if readableUrl is not set.
 * @param {string?} title - The Title.
 * @param {string?} readableUrl - The readable URL if set in backend.
 * @param {Date} publishDate - The date the article was published.
 * @param {Thumbnail[]?} thumbnails - Thumbnail in various resolutions.
 * @param {ArticleStrip[]?} strips - List of Article strips...
 */
export class Article {
    id: number;
    title?: string;
    summary?: string;
    readableUrl?: string;
    publishDate: Date;
    thumbnails?: Thumbnail[];
    content?: string; //ToDO: Get rid of this!
    strips?: Strip[];


    static createFromApi(dto: ArticleDTO): Article {
        return Object.assign(new Article(), dto, {
            strips: dto.strips?.map(x=> Strip.createFromApi(x))
        });
    }
}

export interface ArticleDTO {
    id: number;
    readableUrl?: string;
    strips: StripDTO[]
}

export interface StripDTO {
    container: string;
    params: object[];
}

export class Strip {
    container: string;
    params: object;

    static createFromApi(dto: StripDTO): Strip {
        return Object.assign(new Strip(), dto, {

        })
    }
}