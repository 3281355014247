import React, { ReactElement, useEffect, useRef, useState } from 'react';
import './InkyPenLogin.scss';
import InkyPenEmail from '../../InkyPenUIKit/InkyPenEmail/InkyPenEmail';
import InkyPenArrow from '../../InkyPenUIKit/InkyPenArrow/InkyPenArrow';
import useLazyFetch from '../../Hooks/useLazyFetch';
import { InkyApiV2 } from '../../../API/InkyApiV2';
import { useAtom, useSetAtom } from 'jotai';
import { AccountInfoAtom, LoginStatus, LoginStatusAtom } from '../../../store/AccountStore';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from '../../../store/OverlayStore';
import apiClient from '../../../API/InkyAPI';
import { isEmail } from '../../../utils/utils';
import { FetchCartAtom, ShowCartAtom } from '../../../store/CartStore';
import { VariantType } from '../../../Models/CartItem';
import LoginImage from '../../Assets/inky-arts/Steam_Intense Reading.png';
import { AuthGuide } from '../InkyPenAuth';

// const colors = {
// 0: '#3cc8e1',
// 1: '#f5333f',
// 2: '#ffe600',
// 3: '#C964CF',
// };

export function setVerificationTime(): void {
    const currentTime = new Date().getTime();
    localStorage.setItem('verification_time', currentTime.toString());
}

export const ResendVerification = () => {
    return apiClient.resendVerification(null).then(resp => {
        console.log(resp);
        setVerificationTime();
        // localStorage.setItem('verification_time', )
    });
};

const InkyPenLogin = ({ email, setEmail, setStep, validEmailError = '', setValidEmailError }): ReactElement => {
    const { data, error, fetch } = useLazyFetch(() => InkyApiV2.shared().CheckIfEmailExists(email));
    const [isLoggedIn] = useAtom(LoginStatusAtom);
    // const [loginType] = useAtom(LoginTypeAtom);
    const [AccountInfo] = useAtom(AccountInfoAtom);
    const [hasSentVerification, setHasSentVerification] = useState(false);

    const [cart] = useAtom(FetchCartAtom); // The Cart-object
    // Checkout actions
    const [, setCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    const SetLoginOverlay = useSetAtom(LoginOverlayVisible);

    function hasDigital() {
        return cart && cart.containsProductVariantType(VariantType.Digital);
    }

    useEffect(() => {
        if (data === true) {
            setStep(1);
            setValidEmailError('');
        } else if (data === false) {
            setStep(2);
            setValidEmailError('');
        }
    }, [data, error]);

    const fetchAfterCheck = () => {
        if (isEmail(email)) {
            fetch();
        } else {
            email && setValidEmailError('Please enter a valid email.');
        }
    };

    const onLoginClicked = (): void => {
        fetchAfterCheck();
    };

    function onContinueAsGuestClicked(e: any) {
        e.preventDefault();
        setCheckoutOverlay(true);

        SetLoginOverlay(false);
    }

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            fetchAfterCheck();
        }
    };

    console.log('LoginTypeStore.getLoginType()', LoginTypeStore.getLoginType());

    if (isLoggedIn !== 'LoggedIn' && LoginTypeStore.getLoginType() === LoginType.Checkout) {
        // TODO: If user not signed in
        // LoginTypeStore.setLoginType(LoginType.Normal);
        return <div className='auth-login-container'>
            <span className='auth-login-title'>Log in or Sign up to continue</span>

            <div className='auth-login-features-wrapper-checkout'>
                <span
                    className='auth-login-feature-text'>{(hasDigital()) ? 'To purchase digital products you must be signed in to an account.' : 'Save time on your next checkout by creating an account.'}</span>
            </div>

            <span className='auth-login-email-label'>
                Enter your email to get started
            </span>


            <div className='auth-login-email-wrapper'>
                <InkyPenEmail placeHolder={'Email'}
                              name={'email'}
                              onChange={(value) => {
                                  setValidEmailError('');
                                  setEmail(value);
                              }}
                              onEnterPushed={onEnter}
                              withFocus={true}
                              errorMessage={error === undefined ? validEmailError === '' ? '' : 'Please enter a valid email.' : error.toString()} />
            </div>

            <div className={'auth-bottom-buttons-wrapper-checkout'}>
                {/*<div className='auth-continue-wrapper'>*/}
                {/*    {hasPhysical()&& */}
                {/*        <InkyPenArrow */}
                {/*            onClick={onContinueAsGuestClicked} */}
                {/*            active={!hasDigital()} */}
                {/*            title={hasDigital()?'You need to be signed in to an account to purchase digital products.':''} */}
                {/*            text={'Continue as Guest'} */}
                {/*            withArrow={false} />}*/}
                {/*</div>*/}
                <div className={'auth-bottom-buttons-wrapper-checkout-inner'}>
                    {/*<div className='auth-continue-wrapper'>*/}
                    {/*    {hasPhysical()&&*/}
                    {/*        <InkyPenArrow*/}
                    {/*            onClick={onContinueAsGuestClicked}*/}
                    {/*            active={!hasDigital()}*/}
                    {/*            title={hasDigital()?'You need to be signed in to an account to purchase digital products.':''}*/}
                    {/*            text={'Continue as Guest'}*/}
                    {/*            withArrow={false} />*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <div className='auth-login-submit-wrapper'>
                        <InkyPenArrow onClick={onLoginClicked} active={isEmail(email)} text={'Continue'} />
                    </div>

                </div>

            </div>

        </div>;
    }

    if (isLoggedIn === 'LoggedIn' && AccountInfo.verified === false) {
        // TODO: If user signed in but didn't verify account
        return <div className='auth-login-container'>
            <span className='auth-login-title'>Confirm your account</span>

            <div className='auth-login-features-wrapper'>
                {/*<span className='auth-login-feature-text'>Follow the instructions in the message we sent to your email to confirm your account. Then return here to continue.</span>*/}
                <span className='auth-login-feature-text'>
                    You’re all set! <br />
                    We’ve sent an email to <strong>{AccountInfo.email}</strong> with instructions to verify your new account. Please take a moment to complete this step.</span>
            </div>

            <div className='auth-login-verification-wrapper'>
                <span className='auth-login-feature-text'>Your account has not been verified.</span>
                <InkyPenArrow text={'Resend verification email'} withArrow={false} active={!hasSentVerification}
                              onClick={() => {
                                  ResendVerification();
                                  setHasSentVerification(true);
                              }} />
            </div>
        </div>;
    }

    return (
        <div className='auth-login-container'>

            <img src={LoginImage} alt={'Login Image'} className={'login-image'} />

            <span className='auth-login-email-label'>
                Email
            </span>


            <div className='auth-login-email-wrapper'>
                <InkyPenEmail placeHolder={'inky@inky-pen.com'}
                              onEnterPushed={onEnter}
                              name={'email'}
                              onChange={(value) => {
                                  setEmail(value);
                                  setValidEmailError('');
                              }}
                              withFocus={true}
                              errorMessage={error === undefined ? validEmailError === '' ? '' : 'Please enter a valid email.' : error.toString()} />
            </div>

            <div className={'auth-bottom-buttons-wrapper'}>
                {/*<div className='auth-continue-wrapper'>*/}
                {/*    {hasPhysical()&&*/}
                {/*        <InkyPenArrow*/}
                {/*            onClick={onContinueAsGuestClicked}*/}
                {/*            active={!hasDigital()}*/}
                {/*            title={hasDigital()?'You need to be signed in to an account to purchase digital products.':''}*/}
                {/*            text={'Continue as Guest'}*/}
                {/*            withArrow={false} />*/}
                {/*    }*/}
                {/*</div>*/}
                <div className='auth-login-submit-wrapper'>
                    <InkyPenArrow onClick={onLoginClicked} active={isEmail(email)} text={'Continue'} />
                </div>

            </div>

            <div className={'auth-guide-wrapper-top auth-modal-cancel-wrapper'}>
                <AuthGuide />
            </div>


        </div>
    );
};

export default InkyPenLogin;