import React, { ReactElement, PropsWithChildren } from 'react';
import {
    LinkProps as OriginalLinkProps,
    NavLinkProps as OriginalNavLinkProps,
    Link as OriginalLink,
    NavLink as OriginalNavLink,
} from 'react-router-dom';

export type LinkProps<S> = OriginalLinkProps<S>;
export type NavLinkProps<S> = OriginalNavLinkProps<S>;

export function ensureTrailingSlash(pathname: string): string {
    try {
        // do not change absolute URLs
        if (pathname.startsWith('https://') || pathname.startsWith('http://')) {
            return pathname;
        }
        const base = window.location.origin;
        const url = new URL(pathname, base);
        // do not change URLS with extensions (e.g. foo.pdf)
        if (/\.\w+$/.test(url.pathname)) {
            return pathname;
        }
        // nothing to add
        if (url.pathname.endsWith('/')) {
            return pathname;
        }
        url.pathname = url.pathname + '/';
        return `${url}`.substring(base.length);
    }  catch (e) {
        console.error(e);
    }
    return pathname;
}

let Link = OriginalLink;
/////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////////////////////
////////////////
/////////////////////////////////////////////////////////
////////////////////////////////////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////
/////////////////////////////////////////
///////////////////////////////////////////////
/////////////
//////////////////////
/////////////
//////
/////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////
/////
/////////////////////////////////
////////////////////////////////////////////////////////////////////////
/////
///////////////////////////////////////////////
 
//////////
export { Link };

let NavLink = OriginalNavLink;
/////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////
//////////////////////
////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////
//////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/////////////
/////////////////////////////////////////
///////////////////////////////////////////////
/////////////
//////////////////////
/////////////
//////
/////
//////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////
/////
/////////////////////////////////
///////////////////////////////////////////////////////////////////////////
/////
//////////////////////////////////////////////////
 
//////////
export { NavLink };

interface HashLinkProps {
    targetId: string;
}

export function HashLink({ targetId, children }: PropsWithChildren<HashLinkProps>): ReactElement {
    return <Link to={{ hash: '#' + targetId }} onClick={(e): void => {
        e.preventDefault();
        e.stopPropagation();
        const target = document.getElementById(targetId);
        if (target) {
            target.scrollIntoView({block: "center", inline: "nearest", behavior: 'smooth'});
        }
    }}>{children}</Link>;
}
