import React from 'react';
import './PreFooter.scss';
import { Link } from 'react-router-dom';

const PreFooter = () => {
    return (
        <div className='pre-footer-container'>
            <div className='pre-footer-links-wrapper'>
                <div className='pre-footer-link-wrapper'>
                    <span className='pre-footer-link-title'>Learn More</span>
                    <Link to={'/faq'} target={"_blank"} className='pre-footer-link'>VISIT OUR FAQ</Link>
                </div>

                <div className='pre-footer-link-wrapper pre-footer-link-wrapper-second'>
                    <span className='pre-footer-link-title'>Need Help?</span>
                    <Link to={'/faq'} target={"_blank"} className='pre-footer-link'>CONTACT US</Link>
                </div>
            </div>
        </div>
    );
};

export default PreFooter;