import React from 'react';
import './InkypenButton.scss';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/BackArrow_InkyPen.svg';


interface InkypenButtonProps {
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const InkypenButton: React.FC<InkypenButtonProps> = ({ text, onClick }) => {
    return (
        <button className='inkypen-button' onClick={onClick}>
            <span className='inkypen-button-text'>
                {text}
            </span>
            <Chevron className='inkypen-button-arrow' />
        </button>
    );
};

export default InkypenButton;
