import React from 'react';
import './MobileMenu.scss';
import { ReactComponent as CancelIcon } from '../Assets/icons/PLUS.svg';
import { Link } from '../../Devon/Components/InkyLink';
import { ReactComponent as DiscoverIcon } from '../Assets/inky-icons/Discover_InkyPen.svg';
import { ReactComponent as LogoutIcon } from '../Assets/inky-icons/LogOut_InkyPen.svg';
import { ReactComponent as AccountIcon } from '../Assets/inky-icons/Account_InkyPen.svg';
import clsx from 'clsx';
import { ReactComponent as BrowseIcon } from '../Assets/inky-icons/Browse_InkyPen.svg';
import { ReactComponent as MyComicsIcon } from '../Assets/inky-icons/MyComics_BlackBackdrop_InkyPen.svg';
import { useAtom } from 'jotai/index';
import { AccountOverlayVisible, LoginOverlayVisible } from '../../store/OverlayStore';
import { MobileMenuAtom } from './navbar.store';
import { AccountInfoAtom, LoginStatusAtom, LogoutAtom } from '../../store/AccountStore';

const MobileMenu = () => {
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [, setMobileMenuAtom] = useAtom(MobileMenuAtom);
    const [loginStatus] = useAtom(LoginStatusAtom);
    const [accountInfo] = useAtom(AccountInfoAtom);
    const [, setAccountOverlayVisible] = useAtom(AccountOverlayVisible);
    const [, logout] = useAtom(LogoutAtom);

    function closeOverlays() {
        setAccountOverlayVisible(false);
        setLoginOverlay(false);
        setMobileMenuAtom(false);
    }

    const onLinkClicked = (e) => {
        e.stopPropagation();
        setMobileMenuAtom(false);
    };

    const onLoginClicked = (e) => {
        e.stopPropagation();
        setMobileMenuAtom(false);
        setLoginOverlay(true);
    };

    function onLogoutClicked() {
        logout();
        closeOverlays();
    }


    return (
        <div className='mobile-menu-container' onClick={closeOverlays}>
            <div className='mobile-menu-modal'>
                <div className='mobile-menu-header-wrapper'>
                    <h3 className='mobile-menu-header-text'>MENU</h3>
                    <div className='mobile-menu-close-wrapper'
                         onMouseDown={(e) => {
                             e.stopPropagation();
                             setAccountOverlayVisible(false);
                         }}>
                        <CancelIcon fill={'white'} className='mobile-menu-close-icon' />
                    </div>
                </div>


                {/*<div className='mobile-menu-options-wrapper'>*/}
                {/*    {loginStatus === 'LoggedIn' ?*/}
                {/*        <Link onClick={onLinkClicked}*/}
                {/*              to={'/account'}*/}
                {/*              className={`mobile-menu-account-wrapper`}>*/}
                {/*            <AccountIcon className={`nav-icon`} />*/}
                {/*            <div aria-label={'link to news page'}*/}
                {/*                 className={'mobile-menu-account-right-wrapper'}>*/}
                {/*                <div className='account-text'>ACCOUNT</div>*/}
                {/*                <div className='email-text'>{accountInfo.email}</div>*/}
                {/*            </div>*/}
                {/*        </Link>*/}
                {/*        :*/}
                {/*        <div onClick={onLoginClicked} className='mobile-menu-option'>*/}
                {/*            Log In*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*</div>*/}

                <div className='mobile-menu-links-wrapper'>
                    <Link to={'/'}
                          onClick={onLinkClicked}
                          className={`${window.location.pathname === '/' ? 'mobile-menu-icon-wrapper-active' : 'mobile-menu-icon-wrapper'}`}>
                        <DiscoverIcon className={`nav-icon`} />
                        <div aria-label={'link to news page'}
                             className={`route ${clsx(window.location.pathname === '/' && 'route-text-active')}`}>
                            DISCOVER
                        </div>
                        {/*{window.location.pathname === '/' && <div className='active-border' />}*/}
                    </Link>

                    {/*<Link to={'/browse'}*/}
                    {/*      onClick={onLinkClicked}*/}
                    {/*      className={`${window.location.pathname === '/browse' ? 'mobile-menu-icon-wrapper-active' : 'mobile-menu-icon-wrapper'}`}>*/}
                    {/*    <BrowseIcon className={'nav-icon'} />*/}
                    {/*    <div aria-label={'link to browse page'}*/}
                    {/*         className={`route ${clsx(window.location.pathname === '/browse' && 'route-text-active')}`}>*/}
                    {/*        BROWSE*/}
                    {/*    </div>*/}
                    {/*</Link>*/}

                    {/*<Link to={'/library'}*/}
                    {/*      onClick={onLinkClicked}*/}
                    {/*      className={`${window.location.pathname === '/library' ? 'mobile-menu-icon-wrapper-active' : 'mobile-menu-icon-wrapper'}`}>*/}
                    {/*    <MyComicsIcon className={'nav-icon'} />*/}
                    {/*    <div aria-label={'link to library page'}*/}
                    {/*         className={`route comics-route ${clsx(window.location.pathname === '/library' && 'route-text-active')}`}>*/}
                    {/*        MY COMICS*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                </div>

                {/*<div className='mobile-menu-logout-wrapper'>*/}
                {/*    <Link to={'/'}*/}
                {/*          onClick={onLogoutClicked}*/}
                {/*          className={`${window.location.pathname === '/library' ? 'mobile-menu-icon-wrapper-active' : 'mobile-menu-icon-wrapper'}`}>*/}
                {/*        <LogoutIcon className={'nav-icon'} />*/}
                {/*        <div aria-label={'link to library page'}*/}
                {/*             className={`route`}>*/}
                {/*            LOG OUT*/}
                {/*        </div>*/}
                {/*    </Link>*/}
                {/*</div>*/}

            </div>

        </div>
    );
};

export default MobileMenu;
