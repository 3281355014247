import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import './InkyPenCheckBox.scss';
import { ReactComponent as Tick } from '../../Assets/inky-icons/Checkmark_InkyPen.svg';
import { ReactComponent as NotSelected } from '../../Assets/inky-icons/Checkbox_NotSelected_InkyPen.svg';

interface Props {
    label?: string | ReactElement;
    ariaLabel?: string;
    checked?: boolean;
    onChange?: (e) => void;
    useAnimation?: boolean;
    controlled?: boolean;
}

const InkyPenCheckBox = ({
                             label = 'label',
                             ariaLabel = 'label',
                             checked = true,
                             onChange,
                             controlled = false,
                         }: Props) => {
    const inputId = useRef(`${label}-input-${Math.random()}`);
    const [active, setActive] = useState(checked);
    const animatedRef = useRef(null);

    const onInputChanged = (e) => {
        e.stopPropagation();
        onChange(!active);
        setActive(active => !active);
    };

    useEffect(() => {
        setActive(checked);
    }, [checked]);

    // useEffect(() => {
    //     animatedRef.current.classList.add('checkmark-animation');
    // }, [animatedRef]);

    return (
        <div className='ui-checkbox-container' aria-label={typeof label === 'string' ? label : ariaLabel}>
            {active ? <div className='checkbox-selected-wrapper'>
                    <NotSelected onClick={onInputChanged} className='checkbox-icon checkbox-not-selected-icon' />
                    <Tick onClick={onInputChanged} className='checkbox-icon checkbox-tick-icon' />
                </div> :
                <div className='checkbox-selected-wrapper'>
                    <NotSelected onClick={onInputChanged}
                                 className='checkbox-icon checkbox-not-selected-icon' />
                </div>}

            <label onClick={onInputChanged} htmlFor={inputId.current} className='checkbox-label'>
                {label}
            </label>
            <input id={inputId.current} name={inputId.current}
                   aria-label={'checkbox for ' + (typeof label === 'string' ? label : ariaLabel)}
                   className='checkbox-input' type='checkbox'
                   checked={controlled ? checked : active} />

            {/*<span ref={animatedRef} onClick={onInputChanged} className='checkmark'>*/}
            {/*    <Tick className='checkbox-tick-icon'/>*/}
            {/*</span>*/}

        </div>
    );
};

export default InkyPenCheckBox;

interface OtherProps {
    checkboxProps: React.ComponentPropsWithoutRef<'input'>;
    labelProps: React.ComponentPropsWithoutRef<'label'>;
    commonLabel: ReactNode;
    commonOnChange?: () => void;
}

export const OtherInkypenCheckBox = ({ commonLabel = 'label', commonOnChange, ...rest }: OtherProps) => {
    const inputId = useRef(`${commonLabel}-input-${Math.random()}`);

    const animatedRef = useRef(null);

    useEffect(() => {
        animatedRef.current.classList.add('checkmark-animation');
    }, [animatedRef]);


    return (
        <div className='ui-checkbox-container' aria-label={commonLabel.toString()}>
            <label htmlFor={inputId.current} className='checkbox-label' {...rest.labelProps}>
                {commonLabel}
            </label>
            <input
                id={inputId.current} name={inputId.current}//this is what fills in the checkbox
                aria-label={'checkbox for ' + commonLabel}
                className='checkbox-input' type='checkbox'
                onChange={commonOnChange}
                {...rest.checkboxProps}
            />
            <span ref={animatedRef}
                  onClick={commonOnChange} //this is what actually gets clicked most of the time
                  className='checkmark'>
            </span>
        </div>
    );
};