import React from 'react';
import './InkyPenPublish.scss';
import { ReactComponent as Pink } from '../../Assets/inky-arts/Banner_Pink_Halftone_InkyPen.svg';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/Chevron_InkyPen.svg';
import SteamLogo from '../../Assets/inky-arts/SteamLogo.png';
import NintendoLogo from '../../Assets/inky-arts/Nintendo.png';
import { InkyPenNavbar } from '../../InkyPenNavbar/InkyPenNavbar';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

const InkyPenPublish = () => {
    const history = useHistory();

    const goToLastPage = () => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    return (
        <>
            <Helmet>
                <title>{'InkyPen | Publish'}</title>
            </Helmet>
            <div className='inky-publish-container'>
                <InkyPenNavbar />
                <Pink className='inky-publish-pink' />

                <div className='inky-publish-max-wrapper'>

                    <div className='inky-back-wrapper'>
                        <span className='inky-back' onClick={goToLastPage}>
                            <Chevron className='inky-back-icon' />
                            <span className='inky-back-text'>
                                Back
                            </span>
                        </span>
                    </div>

                    <h3 className='inky-second-title'>Reach out to new audiences</h3>
                    <h1 className='inky-title'>Publish with InkyPen</h1>

                    <p className='publish-text'>
                        {'We\'re'} always looking for new publishers to partner with, and with us, your content can find
                        a
                        new
                        audience in places where comics have never gone before. Inkypen was the first to deliver comics
                        to popular gaming platforms STEAM® and NINTENDO SWITCH&trade;
                    </p>

                    <div className='publish-logo-wrapper'>
                        <img src={SteamLogo} className='publish-steam-logo' />
                        <img src={NintendoLogo} className='publish-steam-logo' />
                    </div>

                    <div className='get-in-touch-wrapper'>
                        <span className='get-in-touch-title'>Get in touch</span>
                        <p className='get-in-touch-text'>If {'you\'re'} a publisher of comics, manga or anything in
                            between, you can contact us at <strong>publisher@inky-pen.com</strong>
                        </p>
                    </div>

                </div>
            </div>
        </>
    );
};

export default InkyPenPublish;
