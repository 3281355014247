import { atom } from 'jotai';
import { v4 as uuid } from 'uuid';
import { Cart } from '../Models/Cart';
import { InkyApiV2 } from '../API/InkyApiV2';
import apiClient from '../API/InkyAPI';
import { DiscoverArrayAtom } from './DiscoverStore';
import AddToCartOverlay from '../Models/AddToCartOverlay';
import { selectAtom } from 'jotai/utils';

interface CartProduct {
    productId: number;
    variantId: number;
}

//region UI-atoms
export const ShowCartAtom = atom<boolean>(false);

//endregion


//region Fetch Cart
export const CartAtom = atom<Cart | false>(false);

export const CartHasItemsAtom = selectAtom(CartAtom, cart => cart && (cart.cartItems?.length ?? 0) > 0);

export const FetchCartAtom = atom(
    (get) => get(CartAtom),
    async (get, set) => {
        // TODO: Create API-method that fetches cart. Use the state
        const cart = await InkyApiV2.shared().FetchCart();
        set(CartAtom, cart);
    }
);

//endregion
export const RegionLockedAtom = atom<boolean>(false);
export const AddToCartOverlayAtom =  atom<boolean>(false);
export const GetAddToCartOverlayBox = atom(
    (get) => {
        return get(AddToCartOverlayAtom);
    },
     (get, set, obj: AddToCartOverlay) => {
        set(AddToCartOverlayAtom, obj.show);
        set(RegionLockedAtom, obj.regionLocked);
    },
);

export const UpdatingCartAtom = atom<boolean>(false);

//region AddProductToCart
export const AddToCartAction = atom(
    (get) => get(CartAtom),
    async (get, set, product: CartProduct) => {
        try {
            set(UpdatingCartAtom, true);
            const updatedCart = await InkyApiV2.shared().AddProductToCart(product.productId, product.variantId);
            if(updatedCart !== null)
            {
                let overlay = new AddToCartOverlay();
                overlay.show = true;
                overlay.regionLocked = false;
                set(GetAddToCartOverlayBox, overlay);
                set(CartAtom, updatedCart);
            }
            else if (updatedCart === null)
            {
                let overlay = new AddToCartOverlay();
                overlay.show = true;
                overlay.regionLocked = true;
                set(GetAddToCartOverlayBox, overlay);
            }
        }
        finally {
            set(UpdatingCartAtom, false);
        }
    }
);


//endregion

//region DeductProductFromCart
export const DeductFromCartAction = atom(
    (get) => get(CartAtom),
    async (get, set, product: CartProduct) => {
        try {
            set(UpdatingCartAtom, true);
            const updatedCart = await InkyApiV2.shared().DeductProductFromCart(product.productId, product.variantId);
            console.log("Deducting Product from cart", product);
            set(CartAtom, updatedCart);
        }
        finally {
            set(UpdatingCartAtom, false);
        }
    }
);
//endregion

//region RemoveAllOfProductFromCart
export const RemoveAllOfProductFromCartAction = atom(
    (get) => get(CartAtom),
    async (get, set, product: CartProduct) => {
        const updatedCart = await InkyApiV2.shared().RemoveAllOfProductFromCart(product.productId, product.variantId);
        console.log("Removing all of Product from cart", product);
        set(CartAtom, updatedCart);
    }
);
//endregion


//region CartId
const PersistentCartIdKey = "CartId";
export const PersistentCartIdAtom = atom<string | false>(false);
export const CartIdAtom = atom(
    (get) => get(PersistentCartIdAtom),
    (get, set, cartId: string) => {
        console.log("[CartID] Setting CartId to " + cartId);
        localStorage.setItem(PersistentCartIdKey, cartId);
        // Set the Api before anything else, since other atoms may trigger API-calls
        InkyApiV2.shared().CartId = cartId;
        // Set other atoms.
        set(PersistentCartIdAtom, cartId);
        set(FetchCartAtom);
    }
);

CartIdAtom.onMount = (set): void => {
    console.log("[CartID] Initiated.");
    // Set the CartID to be the stored value, if that does not exist, create a new one.
    set(localStorage.getItem(PersistentCartIdKey) || uuid());
};

//endregion


//region ClearCart
export const ClearCartAction = atom(
    (get) => get(CartAtom),
    async (get, set) => {
        const updatedCart = await InkyApiV2.shared().ClearCart();
        console.log("Cleared cart");
        set(CartAtom, updatedCart);
    }
);

export const AbandonCart = atom(
    (get) => get(CartAtom),
    (get, set) => {
        console.log("Abandoning old cart, creating new cart.")
        set(CartIdAtom, uuid());
    }
);
//endregion

