import React, { useEffect } from 'react';
import './InkyPenSignUpFinish.scss';
import '../InkyPenLogin/InkyPenLogin.scss';
import { CheckoutOverlayVisible, LoginOverlayVisible, LoginType, LoginTypeStore } from '../../../store/OverlayStore';
import { useAtom } from 'jotai';
import { setVerificationTime } from '../InkyPenLogin/InkyPenLogin';
import { useHistory } from 'react-router-dom';
import InkyPenArrow from '../../InkyPenUIKit/InkyPenArrow/InkyPenArrow';
import InkypenButton from '../../InkyPenUIKit/InkypenButton/InkypenButton';
import { SubscriptionModalAtom } from '../../SubscriptionModal/SubscriptionModalAtom';

const InkyPenSignUpFinish = ({ setLoginOverlay, step, email }): JSX.Element => {
    const [, SetCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    const [, SetSubscriptionModal] = useAtom(SubscriptionModalAtom);
    const [, SetLoginOverlay] = useAtom(LoginOverlayVisible);
    const history = useHistory();

    useEffect(() => {
        setVerificationTime();
    }, []);

    function onPlanSelectButtonClicked() {
        SetSubscriptionModal(true);
        SetLoginOverlay(false);
    }

    return (
        <div className='auth-login-container'>
            <span className='auth-signup-finished-header'>
                Verify your account
            </span>
            <span className='auth-signup-finished-text-inkypen'>
                Complete your account by verifying your account. Follow the instructions we sent to:
            </span>

            <span className='auth-signup-finished-text-inkypen auth-signup-finished-text-email'><strong>{email}</strong></span>

            <div className='finish-signup-divider' />

            <div className='finish-signup-button-styler'>
                <InkypenButton text={'Select A Subscription Plan'} onClick={onPlanSelectButtonClicked} />
            </div>


            <div className='auth-signup-finished-front-wrapper'>
                <span onClick={(): void => {
                    setLoginOverlay(false);
                    history.push('/');
                    if (LoginTypeStore.getLoginType() === LoginType.SignupCheckout) {
                        setLoginOverlay(false);
                        SetCheckoutOverlay(true);
                    }
                }} className='auth-signup-finished-front-text'>
                    Continue without subscription
                </span>
            </div>


            {/*<InkyPenResumeBook />*/}
        </div>
    );
};

export default InkyPenSignUpFinish;