import { Publishable } from '../Publishable';
import dayjs, { Dayjs } from 'dayjs';
import dashboard from '../../../API/DashboardAPI';
import CannotPublishItem from '../../CannotPublishItem';

export class DashboardSalesCampaign extends Publishable {
    name: string;
    startDate: Dayjs|null;
    endDate: Dayjs|null;
    description:string
    salesProducts: DashboardSalesProduct[]
    includedSeries:DashboardSalesSeries[]

    canPublish(): boolean {
        return this.getCannotPublishReasons().length <1;
    }
    getEmptyFields(): CannotPublishItem[] {
        const missingItems = [];

        if(this.name.length < 1) {
            missingItems.push({ title: "Sales Campaign Name"});
        }

        if(this.startDate == null) {
            missingItems.push({ title: "Start Date"});
        }
        if(this.endDate == null) {
            missingItems.push({ title: "End Date"});
        }

        if(this.startDate&&this.endDate&& this.startDate.valueOf() > this.endDate.valueOf()){
            missingItems.push({ title: "End Date is before Start Date"});
        }

        return missingItems;
    }

    getCannotPublishReasons() {
        const missingItems: CannotPublishItem[] = [];
        const emptyFields = this.getEmptyFields();
        if(emptyFields && emptyFields.length > 0) {
            missingItems.push({
                title: "Missing Sales Information",
                description: "All the following fields must be filled in before you can publish this product",
                subItems: emptyFields
            });
        }
        return missingItems;
    }



    copyFromOriginal(): void {
        super.copyFromOriginal();
        const item = this.getOriginal();
        this.name = item.name;
        this.startDate = item.startDate;
        this.endDate = item.endDate;
        this.description = item.description;
        this.salesProducts = item.salesProducts;
        this.includedSeries = item.includedSeries;

    }
    initialize( param?:Partial<DashboardSalesCampaign> ){
        const orig = new DashboardSalesCampaign();
        orig.status=Publishable.STATUS_NEW;
        this.original = orig;
        this.copyFromOriginal();
        this.status = Publishable.STATUS_DRAFT;
        if(param) {
            for (const update in param) {
                const key = update as keyof DashboardSalesCampaign;
                (this[key] as any) = param[key];
            }
        }
    }
    

    async save() {
        if(this.id){
            return await dashboard.alterSalesCampaign(this);
        } else if(this.status === Publishable.STATUS_DRAFT){
            return await dashboard.createSalesCampaign(this);
        }
    }

    static async getFromApi(id: number) {
        let result = await dashboard.getSalesCampaign(id);
        return DashboardSalesCampaign.createFromApi(result);
    }
    
    async AddSeries(series:DashboardSalesSeries) {
        if(this.includedSeries.map(s =>s.seriesId).includes(series.seriesId)) {
             console.error("Tried to add series that is already included.")
            return this}
        const result = await dashboard.getSalesCampaignProductsForSeries(series.seriesId)
        this.includedSeries.push(series);
        
        this.salesProducts = this.salesProducts.concat(result.map(dspd =>DashboardSalesProduct.createFromApi(dspd)));
        
        return this;
    }
    static async getAllSalesCampaigns(fromIndex?:number, count?:number,search?:string) {
        
        let result = await  dashboard.getAllSalesCampaigns(fromIndex,count,search);
        return {items:result.items.map(x=>
                DashboardSalesCampaignShort.createFromApi(x)
            ),fullCount:result.fullCount}
    }

    getOriginal() {
        //really should avoid this, but due to the circular nature of publishable, making it better was difficult on several levels
        return this.original? this.original as unknown as DashboardSalesCampaign: null;
    }
    hasChanged(): boolean {

        if(!super.hasChanged()) {
            const orig = this.getOriginal();


            return (this.name !== orig.name ||
                !this.startDate?.isSame(orig.startDate) ||
                !this.endDate?.isSame(orig.endDate) ||
                this.description !== orig.description ||
                !this.areArraysEqual(this.salesProducts,orig.salesProducts)||
                !this.areArraysEqual(this.includedSeries,orig.includedSeries)
            );


        }

        return true;
    }
    private areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
        if( arr1 ==null){
            return arr2 ==null
        }
        if( arr2 ==null){
            return false
        }
        
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }
    

    unPublish(){
        return dashboard.unpublishSalesCampaign(this.id)
    }

    publish(){
        return dashboard.publishSalesCampaign(this.id)
    }

    toApi() {
        const dto = new DashboardSalesCampaignDTO();

        dto.name =this.name;
        dto.startDate = this.startDate ? (this.startDate).toISOString() : null;
        dto.endDate= this.endDate ? (this.endDate).toISOString() : null;
        dto.description =this.description;
        dto.salesProducts= this.salesProducts
            .filter(sp => sp.inCampaign && sp.discountPrice!= null)
            .map(sp => sp.toApi())
        dto.id = this.id;
        dto.status = this.status

        return dto;
    }

    static createFromApi(dto:DashboardSalesCampaignDTO):DashboardSalesCampaign {
        if (!dto) return null;
        const dsc=  new DashboardSalesCampaign();

        for (const prop in dto) {
            if (dto.hasOwnProperty(prop)) {
                dsc[prop] = dto[prop];
            }
        }
        dsc.startDate = dto.startDate?dayjs(dto.startDate +'Z'):null;
        dsc.endDate = dto.endDate?dayjs(dto.endDate +'Z'):null;
        dsc.salesProducts= dto.salesProducts.map(x =>DashboardSalesProduct.createFromApi(x));
        dsc.original = dsc;
        dsc.name = dsc.name?dsc.name:""
        dsc.description = dsc.description?dsc.description:""
        
        
        return dsc;
    }

    mutate(newProps: Partial<DashboardSalesCampaign>): DashboardSalesCampaign {

        return Object.assign(new DashboardSalesCampaign(), this, newProps);
    }
    
    static async ValidateFromCsv(files: File[]) {
        const formData = new FormData()
        files.map((file, index) => formData.append(`file${index + 1}`, file))

        const result = await dashboard.ValidateDashboardSalesImport(formData)
        return result;
    }
    static async ValidateAndImportFromCsv(files: File[]) {
        const formData = new FormData()
        files.map((file, index) => formData.append(`file${index + 1}`, file))

        const result = await dashboard.ValidateAndImportDashboardSalesImport(formData)
        return result;
    }

}
export class DashboardSalesSeries {
    seriesName:string;
    seriesId:number;
}

export class DashboardSalesCampaignShort {
    id:number;
    name: string;
    startDate: Dayjs;
    endDate: Dayjs;
    status: string
    description:string
    productCount:number

    // toApi() {
    //     const dto= new DashboardSalesCampaignShortDTO();
    //    
    //     for (const prop in dto) {
    //         if (dto.hasOwnProperty(prop)) {
    //             dto[prop] = this[prop];
    //         }
    //     }
    //
    //     dto.startDate= this.startDate ? (this.startDate).toISOString() : null;
    //     dto.endDate= this.endDate ? (this.endDate).toISOString() : null;
    //     return dto;
    // }
    static createFromApi(dto:DashboardSalesCampaignShortDTO) {
        if (!dto) return null;
        const salesCampaignShort = new DashboardSalesCampaignShort()
        for (const prop in dto) {
            if (dto.hasOwnProperty(prop)) {
                salesCampaignShort[prop] = dto[prop];
            }
        }
        salesCampaignShort.startDate =dto.startDate?dayjs(dto.startDate +'Z'):null;
        salesCampaignShort.endDate =dto.endDate?dayjs(dto.endDate +'Z'):null;
        return salesCampaignShort;
    }
}

export class DashboardSalesCampaignShortDTO {
    id:number;
    name: string;
    startDate: string;
    endDate: string;
    status: string
    productCount:number
    description:string
}

export class DashboardSalesCampaignDTO {
    id:number;
    name: string;
    startDate: string|undefined;
    endDate: string|undefined;
    salesProducts: DashboardSalesProductDTO[]
    description:string;
    status: string;
}

export class DashboardSalesProductDTO {
    id: number;
    productId: number;
    variantId: number;
    name:string;
    fullPrice: number;
    discountPrice: number;
    seriesId:number;
    EISBN:string;
    ISBN:string;
    priceType:string;
}
export class DashboardSalesProduct {
    id: number;
    productId: number;
    variantId:number;
    name:string;
    fullPrice: number;
    discountPrice: number;
    seriesId:number;
    inCampaign:boolean;
    eisbn:string;
    isbn:string;
    priceType:string;
    //OverridenBy
    //IsOverriding

    toApi() {
        const dto = new DashboardSalesProductDTO()
        // for (const prop in dto) {
        //     if (dto.hasOwnProperty(prop)) {
        //         dto[prop] = this[prop];
        //     }
        // }

        if(this.inCampaign && this.discountPrice == null || this.discountPrice === this.fullPrice){
            console.error("This should not be turned into a DTO ")
            return null;
        }

        dto.id = this.id;
        dto.productId= this.productId;
        dto.name = this.name;
        dto.fullPrice = this.fullPrice
        dto.discountPrice = this.discountPrice;
        dto.seriesId = this.seriesId;
        dto.variantId = this.variantId;
        return dto;
    }

    mutate(newProps: Partial<DashboardSalesProduct>): DashboardSalesProduct {

        return Object.assign(new DashboardSalesProduct(), this, newProps);
    }
    
    getVariantName() {
        return this.variantId === -1 ? "Digital" : "Print";
    }

    wontBeSaved() {
        return this.inCampaign && (this.fullPrice == this.discountPrice || this.discountPrice == null)
    }
    static createFromApi(dto:DashboardSalesProductDTO) {
        if (!dto) return null;

        const product = new DashboardSalesProduct();

        for (const prop in dto) {
            if (dto.hasOwnProperty(prop)) {
                product[prop] = dto[prop];
            }
        }

        product.inCampaign = dto.discountPrice !== undefined && dto.discountPrice !== null;

        return product;
    }
}