import React, { useEffect } from 'react';
import './SubscriptionModal.scss';
import { ReactComponent as Cross } from '../Assets/inky-icons/Cross_InkyPen.svg';
import TransparentRoundedSquares from '../Assets/inky-arts/Halftone_TransparentRoundedSquares_White_Short.png';
import { useAtom } from 'jotai';
import { SubscriptionModalAtom } from './SubscriptionModalAtom';
import { hideScroll, showScroll } from '../../utils/utils';


const SubscriptionModal = () => {
    const [subscriptionModal, setSubscriptionModal] = useAtom(SubscriptionModalAtom);

    useEffect(() => {
        if (subscriptionModal) {
            hideScroll();
        } else {
            showScroll();
        }
        // setTimeout(() => {
        // setSubscriptionModal(true);
        // }, 3000);
        return () => {
            showScroll();
        };
    }, [subscriptionModal]);

    if (!subscriptionModal) {
        return null;
    }

    return (
        <div className={'subscription-modal-backdrop'} onClick={(e) => {
            setSubscriptionModal(false);
            e.stopPropagation();
        }}>
            <div className={'subscription-modal-wrapper'} onClick={(e) => {
                e.stopPropagation();
            }}>
                <img src={TransparentRoundedSquares} className={'subscription-tone'} />
                <div className={'subscription-modal-header-wrapper'}>
                    <h2 className={'subscription-modal-header'}>Select a subscription plan</h2>
                    <Cross className={'subscription-modal-icon'} onClick={() => setSubscriptionModal(false)} />
                </div>

                <p className={'subscription-modal-describe'}>
                    Subscribe to get access to our full catalogue of content, or continue without subscribing and
                    explore our free content.
                </p>


                <div className='plans-grid'>
                    <div className='plan'>
                        <img src={''} className='plan-image' />
                        <span className={'plan-duration'}>
                            Monthly Subscription
                        </span>
                        <div className='plan-price-wrapper'>
                            <span className='plan-price'>$7.99</span>
                            <span className='plan-limit'>/month</span>
                        </div>
                    </div>
                    <div className='plan'>
                        <img src={''} className='plan-image' />
                        <span className={'plan-duration'}>
                            6 Month Subscription
                        </span>
                        <div className='plan-price-wrapper'>
                            <span className='plan-price'>$42.99</span>
                            <span className='plan-limit'>/6 months</span>
                        </div>

                        <span className='plan-discount'>
                            10% discount
                        </span>
                    </div>
                    <div className='plan'>
                        <img src={''} className='plan-image' />
                        <span className={'plan-duration'}>
                            Annual Subscription
                        </span>

                        <div className='plan-price-wrapper'>
                            <span className='plan-price'>$80.99</span>
                            <span className='plan-limit'>/year</span>
                        </div>

                        <span className='plan-discount'>
                            15% discount
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SubscriptionModal;
