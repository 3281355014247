import { atom } from 'jotai';
import apiClient from '../API/InkyAPI';
import { Comic } from '../Models/Comic';

export const MyBooksAtom = atom<Comic[]>([]);
export const IsMyBooksFetched = atom<boolean>(false);
export const MyProcessingBooksAtom = atom<Comic[]>([]);
export const MyProcessingBooksLoadingAtom = atom<boolean>(false);
export const MyPurchasedBooksAtom = atom<Comic[]>([]);

export const FetchMyBooksAtom = atom((get) => {
        return get(MyBooksAtom);
    },
    async (get, set) => {
        return apiClient.getMyBooks(null).then((response) => {
            set(MyBooksAtom, (response.data as Comic[] | string) === '' ? [] : response.data);
            set(IsMyBooksFetched, true);
        }).catch(err => {
            console.error('Something went wrong when fetching MyBooks', err);
        });
    },
);

//use this to get both owned books and books in orders that are still being processed
export const FetchMyProcessingBooksAtom = atom((get) => {
        return get(MyProcessingBooksAtom);
    },
    async (get, set) => {
        set(MyProcessingBooksLoadingAtom, true);
        apiClient.getMyProcessingBooks(null).then((response) => {
            set(MyProcessingBooksAtom, (response.data as Comic[] | string) === '' ? [] : response.data);
        }).catch(err => {
            console.error('Something went wrong fetching MyProcessingBooks', err);
        }).finally(() => {
            set(MyProcessingBooksLoadingAtom, false);
        });
    },
);

export const FetchMyPurchasedBooksAtom = atom((get) => {
        return get(MyPurchasedBooksAtom);
    },
    async (get, set) => {
        apiClient.getMyPurchasedBooks(null).then((response) => {
            set(MyPurchasedBooksAtom, (response.data as Comic[] | string) === '' ? [] : response.data);
        }).catch(err => {
            console.error('Something went wrong fetching MyPurchasedBook', err);
        });
    },
);
