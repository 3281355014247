import React, { useEffect, useRef, useState } from 'react';
import './InkyPenForgotPassword.scss';
import InkyPenLabeledInput from '../InkyPenUIKit/InkyPenLabeledInput/InkyPenLabeledInput';
import InkyPenEmail from '../InkyPenUIKit/InkyPenEmail/InkyPenEmail';
import InkyPenUnderlinedText from '../InkyPenUIKit/InkyPenUnderlinedText/InkyPenUnderlinedText';
import InkyPenArrow from '../InkyPenUIKit/InkyPenArrow/InkyPenArrow';
import InkyPenContactSupport from '../InkyPenUIKit/InkyPenContactSupport/InkyPenContactSupport';
import useLazyFetch from '../Hooks/useLazyFetch';
import apiClient from '../../API/InkyAPI';
import { useAtom } from 'jotai';
import { AccountInfoAtom, ForgotPasswordTimerAtom } from '../../store/AccountStore';
import { getCurrentTime, isEmail, timeRemaining } from '../../utils/utils';
import { LoginOverlayVisible } from '../../store/OverlayStore';
import { getTimeDiffs, useCounter } from '../Hooks/useCounter';
import HeaderImage from '../InkyPenAssets/LoginGFX_Mikasa.png';
import { ReactComponent as CancelIcon } from '../Assets/icons/PLUS.svg';
import { useHistory } from 'react-router-dom';

let nowItsTime = false;

interface InkypenForgotPasswordProps {
    setStepChangeEmail: (step: number) => void;
    setNotification?: (notification: string) => void;
    fromAuth?: boolean;
    userEmail?: string;
    ignoreTimer?: boolean;
}

const InkyPenForgotPassword = ({
                                    setStepChangeEmail,
                                    setNotification,
                                    fromAuth = false,
                                    userEmail,
                                    ignoreTimer = false,
                                }: InkypenForgotPasswordProps) => {
    const [AccountInfo] = useAtom(AccountInfoAtom);
    const [email, setEmail] = useState(AccountInfo.email ?? userEmail);
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [emailError, setEmailError] = useState('');
    const { data, loading, error, fetch } = useLazyFetch(() => apiClient.forgotPassword(email), true);
    const [timer, setTimer] = useAtom(ForgotPasswordTimerAtom);
    // const [ignoreTimerState, setIgnoreTimerState] = useState(ignoreTimer);
    const [showMessageSent, setShowMessageSent] = useState(false);
    const { startTimer, sentTime, status, emailSentTime } = useCounter('forgot_password');
    // console.log('sentTime', sentTime, status);
    // const [timeRemain, setTimeRemain] = useState(timeRemaining(timer) ?? '');
    // const timerRef = useRef(null);
    //
    useEffect(() => {
        if (getTimeDiffs(emailSentTime) < 3) {
            nowItsTime = true;
        }
    }, []);

    useEffect(() => {
        if (error) {
            console.log('error', error);
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            console.log('data', data);
            setNotification && setNotification('An email has been sent to you');
            // setStepChangeEmail(0);
            setTimer(new Date().getTime());
        }
    }, [data]);

    const onForgotPasswordClicked = (): void => {
        if (isEmail(email) || isEmail(userEmail)) {
            fetch();
            const currentTime = new Date().getTime();
            localStorage.setItem('forgot_password', currentTime.toString());
            startTimer();
            setShowMessageSent(true);
            // if (ignoreTimer) {
            //     setIgnoreTimerState(false);
            // }
            nowItsTime = true;
        } else {
            setEmailError('Please enter a valid email');
        }
    };

    // console.log('emailSentTime', emailSentTime, getTimeDiffs(emailSentTime));
    return (
        <main className={`${fromAuth ? 'cp-forgot-password-container-from-auth' : 'cp-forgot-password-container'}`}
              style={{ backgroundColor: fromAuth && 'transparent' }}
              onClick={() => {
                  setLoginOverlay(false);
                  setStepChangeEmail(0);
              }}>
            <section className='cp-forgot-password-modal' onClick={(e) => e.stopPropagation()}>
                {fromAuth && <div className='auth-close-wrapper' onClick={(e) => {
                    e.stopPropagation();
                    setLoginOverlay(false);
                    setStepChangeEmail(0);
                }}>
                    <CancelIcon className='auth-close-icon' />
                </div>}


                {fromAuth && <img src={HeaderImage} alt={'inkypen-login-header'}
                                  className={'auth-header-poster-2'} />}

                <div className='cp-forgot-password-inner-wrapper'>

                    {/*{(((getTimeDiffs(emailSentTime) < 3 && (userEmail || email)) || nowItsTime) && !ignoreTimerState) ?*/}
                    {showMessageSent ?
                        <>
                            <header className='cp-forgot-password-title' aria-label={'Forgot password header'}>
                                Instructions Sent
                            </header>


                            <span className='forgot-password-time-remains'>Sent {sentTime}</span>

                            <span
                                className='cp-forgot-password-description'>{`An email is on its way to `}
                                <strong>{userEmail ?? email}</strong></span>

                            <span
                                className='cp-forgot-password-description-2'>{'Check your inbox and follow the instructions to reset your password. It might take a few minutes to reach you. Be sure to take a look in your spam folder!'}</span>


                            <div className='cp-forgot-password-finish-buttons-wrapper'>
                                <InkyPenUnderlinedText text={fromAuth ? 'back' : 'cancel'} onClick={(): void => setStepChangeEmail(0)} />
                                <InkyPenArrow onClick={onForgotPasswordClicked}
                                              text={'SEND AGAIN'}
                                              active={status === false}
                                              withArrow={false} />
                            </div>

                            <InkyPenContactSupport text={'Visit our Support Center'}/>
                        </>
                        :
                        <>
                            <header className='cp-forgot-password-title' aria-label={'Forgot password header'}>
                                Forgot Password
                            </header>
                            <span
                                className='cp-forgot-password-description'>{'We\'ll send you an email with instructions on how to reset your password.'}</span>

                            <div className='cp-forgot-password-email-timer-wrapper'>

                                <InkyPenLabeledInput label={'Email'}>
                                    <InkyPenEmail
                                        withFocus={true}
                                        errorMessage={emailError}
                                        ariaLabeledBy={'label-' + 'Email'.replaceAll(' ', '')}
                                        value={userEmail ?? email}
                                        onEnterPushed={onForgotPasswordClicked}
                                        onChange={(val): void => {
                                            setEmail(val);
                                            setEmailError('');
                                        }}
                                        placeHolder={''} />
                                </InkyPenLabeledInput>

                                {/*        {sentTime !== undefined &&*/}
                                {/*            <span className='forgot-password-time-remains'>*/}
                                {/*Sent {sentTime}*/}
                                {/*</span>}*/}

                            </div>


                            <div className='cp-forgot-password-finish-buttons-wrapper'>
                                <InkyPenUnderlinedText text={fromAuth ? 'back' : 'cancel'}
                                                       onClick={(): void => fromAuth ? setStepChangeEmail(1) : setStepChangeEmail(0)} />
                                <InkyPenArrow onClick={onForgotPasswordClicked}
                                    // text={timer === 0 ? 'SEND EMAIL' : 'SEND AGAIN'}
                                              text='SEND EMAIL'
                                              active={(userEmail !== '' || email !== '') && loading === false && (getTimeDiffs(emailSentTime) > 3 || emailSentTime === null)}
                                              withArrow={false} />
                            </div>

                            <InkyPenContactSupport text={'Visit our Support Center'}/>
                        </>
                    }


                </div>
            </section>
        </main>
    );
};

export default InkyPenForgotPassword;