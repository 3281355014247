import React from 'react';
import './InkyPrivacy.scss';
import { ReactComponent as Pink } from '../../Assets/inky-arts/Banner_Pink_Halftone_InkyPen.svg';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/Chevron_InkyPen.svg';
import { InkyPenNavbar } from '../../InkyPenNavbar/InkyPenNavbar';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

const InkyPrivacy = () => {
    const history = useHistory();

    const goToLastPage = () => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    return (
        <>
            <Helmet>
                <title>{'InkyPen | Privacy'}</title>
            </Helmet>
            <div className='inky-tos-container'>
                <InkyPenNavbar />
                <Pink className='inky-tos-pink' />

                <div className='inky-tos-max-wrapper'>
                    <div className='inky-back-wrapper'>
          <span className='inky-back' onClick={goToLastPage}>
            <Chevron className='inky-back-icon' />
            <span className='inky-back-text'>Back</span>
          </span>
                    </div>

                    <h1 className='inky-title'> InkyPen Privacy Policy </h1>

                    <div className='tos-wrapper'>

                        <p>
            <span lang='EN-GB'>
              This privacy policy (the “<b>Privacy</b> <b>Policy</b>”) explains
              the collection, use, and sharing of information from or about you
              in connection with your use of our Service. The term “
              <b>Service</b>” refers to subscription-based service we offer,
              which allows users to access, download and view a wide selection
              of comic books, graphic novels, manga and comic strips
              (collectively, “<b>Content</b>”) through a software client (the “
              <b>Client</b>”) available to download on the Nintendo Switch™.
            </span>
                        </p>

                        <p>
            <span lang='EN-GB'>
              The company that provides the Service is InkyPen AS, a private
              limited company (<i>Aksjeselskap</i>) incorporated under the laws
              of Norway, having its registered office at Veiten 3, 5012 Bergen, Norway and registered at the Norwegian Register of
              Business Enterprises under number 919503661 (“<b>InkyPen</b>”).
            </span>
                        </p>

                        <p>
            <span lang='EN-GB'>
              <b>InkyPen</b> is a data controller of personal information
              collected and processed through the <b>Service</b> as described in
              this <b>Privacy Policy</b>.
            </span>
                        </p>

                        <h5 lang='EN-GB'>1 Personal Information We Collect:</h5>
                        <div className='divider' />
                        <p>
            <span lang='EN-GB'>
              <b>InkyPen</b> may collect the following information from or about
              you:
            </span>
                        </p>

                        <p>
                            <span lang='EN-GB'>1.1 Information You Provide To Us:</span>
                        </p>

                        <p style={{ marginTop: 0 }}>
                            We receive and store information you provide in relation to our{' '}
                            <b>Service</b>. For example:
                        </p>

                        <div className='plist'>
                            <p>
                                <strong> - </strong>When you purchase a subscription and create an
                                account with us, you enter information about yourself such as
                                username, email address, birthdate, and payment method
                                information.
                            </p>
                            <p>
                                <strong> - </strong> When you contact us for customer support or
                                other reasons, or configure your settings for the <b>Service</b>{' '}
                                on your Account.
                            </p>
                        </div>

                        <span lang='EN-GB'>1.2 Automatic Information:</span>

                        <p>
                            Your use of our <b>Service</b> generates information that{' '}
                            <b>InkyPen</b> receives and collects automatically. For example:
                        </p>

                        <div className='plist'>
                            <p>
                                <strong> - </strong> <i>Hardware, Software and Network Data</i>:
                                We collect information about the type and version of the Nintendo
                                Switch™ device you use to access our <b>Service</b>, your IP
                                address, unique device identifiers, the location of your Nintendo
                                Switch™ device, your network and connection information,{' '}
                                <b>Client</b> usage, connectivity data, and errors or event
                                failures.
                            </p>

                            <p>
                                <strong> - </strong> <i>Content Selection and Interaction</i>: We
                                collect information about the <b>Content</b> you view, browse and
                                download for offline access.
                            </p>
                        </div>

                        <h5 lang='EN-GB'>
                            2 Purposes For Processing Your Personal Information:
                        </h5>
                        <div className='divider' />

                        <span lang='EN-GB'>
              2.1 We process your personal information to operate, provide, and
              improve the <b>Service</b>. These purposes include:
            </span>

                        <div className='plist'>
                            <p>
                                <strong> - </strong>
                                Providing the <b>Service</b> to you.
                            </p>
                            <p>
                                <strong> - </strong>
                                Processing your subscription payments.
                            </p>
                            <p>
                                <strong> - </strong>
                                Communicating with you, including for promotional purposes;
                            </p>
                            <p>
                                <strong> - </strong>
                                Recommending content that we think might interest you and to
                                personalise your experience with the <b>Service</b>.
                            </p>
                            <p>
                                <strong> - </strong>
                                Detecting and mitigating fraud or abuse in order to protect the
                                security of our customers, the <b>Service</b>, InkyPen, and
                                others.
                            </p>
                            <p>
                                <strong> - </strong>
                                Providing functionality, analysing performance, fixing errors, and
                                improving usability and effectiveness of the <b>Service</b>.
                            </p>
                            <p>
                                <strong> - </strong>
                                Compiling aggregate data for internal and external business
                                purposes.
                            </p>
                            <p>
                                <strong> - </strong>
                                Complying with our legal obligations, such as complying with
                                geographic restrictions on the distribution of Content, paying
                                taxes, and displaying appropriate Content, policies and notices.
                            </p>
                            <p>
                                <strong> - </strong>
                                Protecting your and our rights.
                            </p>
                            <p>
                                <strong> - </strong>
                                Other uses in accordance with our Terms of Use.
                            </p>
                            <p>
                                <strong> - </strong>
                                Any other purpose for which we seek your consent. When you consent
                                to our processing your personal information for a specified
                                purpose, you may withdraw your consent at any time and we will
                                stop processing your information for that purpose.
                            </p>
                        </div>

                        <span>
                        2.2 <i>Use of Payment Information: </i>
                        <br />
                        We use payment information, such as bank account and credit card
                        numbers, to provide the <b>Service</b>. For example, we use payment
                        information to process payments from a subscriber (we use a
                        third-party payment processor to do this), to retrieve account
                        information for a subscriber, to determine whether a subscriber is
                        eligible for promotions, and to detect fraud or abuse.
                    </span>

                        <p>
                            2.3 <i>Use of Location Data: </i>
                            <br />
                            In order to access the <b>Service</b>, you may be required to share
                            your device’s location data with us in order for us to comply with
                            geographic restrictions on the distribution of <b>Content</b>.
                        </p>

                        <span>
                        2.4 <i>Use of Aggregate and Anonymised Information: </i>
                        <br />
                        In addition to the individual data use described in this{' '}
                            <b>Privacy Policy</b>, we may aggregate information about you and
                        other individuals together and anonymise the information about you
                        (which means that the information does not identify you personally).
                        We will use information in these forms for our legitimate business
                        purposes, including for research and analysis, calculating payment
                        of our Content licensors, optimisation of the <b>Service</b>, and
                        promotional purposes.
                    </span>

                        <h5 lang='EN-GB'>3 Your Rights and Choices</h5>
                        <div className='divider' />

                        <p>
            <span lang='EN-GB'>
              Subject to applicable law, you have the right to access the
              personal information we maintain about you, to request that your
              personal information be corrected, updated, or deleted, and to ask
              for data portability. Examples of information you can access
              through <b>InkyPen</b> include browsing history and personally
              identifiable information (including account name and email). You
              can object to processing of your personal information, ask us to
              restrict processing of your personal information or request
              portability of your personal information; if we have collected and
              process your personal information with your consent, then you can
              withdraw your consent at any time; withdrawing your consent will
              not affect the lawfulness of any processing we conducted prior to
              your withdrawal, nor will it affect processing of your personal
              information conducted in reliance on lawful processing grounds
              other than consent; you have the right to complain to a data
              protection authority about our collection and use of your personal
              information. When you consent to our processing of your personal
              information for a specified purpose, you may withdraw your consent
              at any time and we will stop any further processing of your
              information for that purpose. If you decide you no longer want us
              to process your personal information, you should uninstall the{' '}
                <b>Client</b>, and, if you have an account, at your request we
              will delete your account and make your user profile anonymous in
              the database. You will no longer be able to use our Service if you
              do this. You may exercise the rights mentioned in this section by
              contacting us at the following address:
              <i>Veiten 3, 5012 Bergen, Norway</i>
              <br />
              or by emailing us at: <i>support@inky-pen.com</i>.
            </span>
                        </p>

                        <h1>
                            <h5 lang='EN-GB'>
                                4 Sharing Personal Information with Third Parties
                            </h5>
                            <div className='divider' />

                        </h1>

                        <p>
                            4.1 We may share information collected from or about you with third
                            parties as described below:
                        </p>

                        <div className='plist'>
                            <p>
                                <strong> - </strong>
                                We may transfer personal information to our corporate affiliates.
                            </p>
                            <p>
                                <strong> - </strong>
                                We may transfer personal information to third parties we have
                                retained to perform services on our behalf, such as Content
                                hosting and delivery, data analytics, marketing and advertising,
                                and payment services. Such service providers may not use or
                                disclose personal information except as necessary to perform
                                services on our behalf or to comply with legal requirements.
                            </p>
                            <p>
                                <strong> - </strong>
                                We may transfer personal information in aggregated or anonymised
                                forms with our business partners, including publishers who provide
                                Content for our <b>Service</b> in order to measure Content
                                popularity, business metrics and calculating payments.
                            </p>
                            <p>
                                <strong> - </strong>
                                In connection with a business transaction involving transfer of
                                our Company or a business of ours, information relating to
                                customers typically would be included in the assets transferred.
                            </p>
                            <p>
                                <strong> - </strong>
                                We may transfer personal information with your consent.
                            </p>
                            <p>
                                <strong> - </strong>
                                We may share personal information:
                                <b>(a)</b> as required by any law to which we are subject;
                                <b>(b)</b> in response to a valid legal request;
                                <b>(c)</b> to protect our rights under our contracts, terms of
                                service, or end user license agreements, or to otherwise protect
                                our rights or the rights of others, including the owners of
                                intellectual property in Content;
                                <b>(d)</b> to assess or mitigate credit risk; or <b>(e)</b> to
                                conduct any investigation relating to the foregoing.
                            </p>
                        </div>

                        <p>
                            4.2 Whenever in the course of sharing information we transfer
                            personal information to countries outside of the European Economic
                            Area and other regions with comprehensive data protection laws, we
                            will ensure that the information is transferred in accordance with
                            this Privacy Policy and as permitted by the applicable laws on data
                            protection.
                        </p>

                        <h5 lang='EN-GB'>5 Data Retention</h5>
                        <div className='divider' />


                        <p>
                            We retain personal information for as long as necessary to provide
                            our <b>Service</b>, to fulfill any other purposes for which the
                            information was initially collected, as may be required by law (such
                            as for tax or accounting purposes), or as otherwise communicated to
                            you. We take reasonable measures to destroy or anonymise personal
                            information in a secure manner when it is no longer required.
                        </p>

                        <h5 lang='EN-GB'>6 Security</h5>
                        <div className='divider' />


                        <p>
                            We use reasonable administrative, logical, physical and managerial
                            measures to safeguard your personal information against loss, theft
                            and unauthorised access, use and modification. These measures are
                            designed to provide a level of security appropriate to the risks of
                            processing your personal information. We will never ask you for your
                            password in any unsolicited communication (including by telephone or
                            e-mail) but we may occasionally request proof of identity. If you
                            believe your username or password has been compromised, please
                            contact us at <i>support@inky-pen.com</i>. You should understand
                            that no system of data security is invulnerable. You should protect
                            against unauthorised access to your password and to your computers,
                            devices, and applications.
                        </p>

                        <h5 lang='EN-GB'>7 Links to Other Websites and Services</h5>
                        <div className='divider' />

                        <p>
                            We may provide links to various third party websites and services.
                            Those sites and services are not under our control. Those sites and
                            services may have their own privacy policies or notices, which we
                            strongly suggest you review if you visit them or use them. We are
                            not responsible for those third party privacy policies or notices,
                            the related data processing practices, or any other aspects of those
                            sites and services.
                        </p>

                        <h5 lang='EN-GB'>8 Children</h5>
                        <div className='divider' />

                        <p>
                            You must be 18 years of age or older to subscribe to the{' '}
                            <b>Service</b>, and while individuals under the age of 18 may use
                            the <b>Service</b>, they may do so only with the involvement,
                            supervision, and approval of a parent or legal guardian.
                        </p>

                        <h5 lang='EN-GB'>9 Changes to This Privacy Policy</h5>
                        <div className='divider' />

                        <p>
                            We will update this <b>Privacy Policy</b> from time to time in
                            response to changing legal, regulatory or operational requirements.
                            We will provide notice of any such changes{' '}
                            <i>(including when they will take effect)</i> in accordance with
                            applicable law. Your continued use of the <b>Service</b> after any
                            such updates take effect will constitute acknowledgement and (as
                            applicable) acceptance of those changes. If you do not wish to
                            acknowledge or accept any updates to this <b>Privacy Policy</b>, you
                            may cancel your use of the <b>Service</b>. To see when this{' '}
                            <b>Privacy Policy</b> was last updated, please see below.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InkyPrivacy;
