import React, { ReactElement, useEffect, useState } from 'react';
import './InkyPenAuth.scss';
import InkyPenLogin from './InkyPenLogin/InkyPenLogin';
import InkyPenSignUp from './InkyPenSignUp/InkyPenSignUp';
import { useAtom } from 'jotai';
import HeaderImage from '../InkyPenAssets/LoginGFX_Mikasa.png';
import {
    CheckoutOverlayVisible,
    LoginOverlayVisible,
    LoginType,
    LoginTypeStore,
} from '../../store/OverlayStore';
import InkyPenSignUpFinish from './InkyPenSignUp/InkyPenSignUpFinish';
import InkyPenPassword from './InkyPenPassword/InkyPenPassword';
import InkyPenForgotPassword from '../InkyPenChangePassword/InkyPenForgotPassword';
import { AccountInfoAtom, LoginStatus, LoginStatusAtom } from '../../store/AccountStore';
import { hideScroll, showScroll } from '../../utils/utils';
import { Link } from '../../Devon/Components/InkyLink';
import { ReactComponent as CancelIcon } from '../Assets/icons/PLUS.svg';
import InkyPenUnderlinedText from '../InkyPenUIKit/InkyPenUnderlinedText/InkyPenUnderlinedText';
import { useSetAtom } from 'jotai/index';
import { VariantType } from '../../Models/CartItem';
import { FetchCartAtom } from '../../store/CartStore';

export function AuthGuide(): JSX.Element {
    return <>
        <div className='guide-title'>LEARN MORE</div>
        <Link target={'_blank'} to='/' className='guide-text'>VISIT OUR FAQ</Link>


        <div className='guide-title guide-title-margin'>NEED HELP</div>
        <Link target={'_blank'} to='/' className='guide-text'>CONTACT US</Link>
    </>;
}

const InkyPenAuth = (): ReactElement => {
    const [, setLoginOverlay] = useAtom(LoginOverlayVisible);
    const [, SetCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    const [step, setStep] = useState<number | string>(0);
    const [email, setEmail] = useState('');
    const [validEmailError, setValidEmailError] = useState('');
    const [notification, setNotification] = useState('');
    const [AccountInfo] = useAtom(AccountInfoAtom);
    const [isLoggedIn] = useAtom(LoginStatusAtom);
    const [, setCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    const SetLoginOverlay = useSetAtom(LoginOverlayVisible);
    // const [loginType] = useAtom(LoginTypeAtom);
    // const [checkoutOverlay, setCheckoutOverlay] = useAtom(CheckoutOverlayVisible);
    const [cart] = useAtom(FetchCartAtom); // The Cart-object

    const isNotVerified = AccountInfo.verified === false && isLoggedIn === 'LoggedIn';

    useEffect(() => {
        hideScroll();
        return () => {
            showScroll();
        };
    });

    useEffect(() => {
        const onEscape = (e) => {
            if (e.key === 'Escape') {
                setLoginOverlay(false);
            }
        };

        window.addEventListener('keydown', onEscape);
        return (): void => {
            window.removeEventListener('keypress', onEscape);
            LoginTypeStore.setLoginType(LoginType.Normal);
        };
    }, []);


    function hasDigital() {
        return cart && cart.containsProductVariantType(VariantType.Digital);
    }

    const onBackOrLearnMoreClicked = (e) => {
        if (e.target.textContent === 'ACCOUNT CREATION BENEFITS') {
            // action for learn more
            // history.push()
        } else {
            // setStep(0);
        }
    };

    return (
        <div className='auth-backdrop' onMouseDown={(e) => {
            e.stopPropagation();
            setLoginOverlay(false);
        }}>


            <div className='auth-modal-container' onMouseDown={(e) => e.stopPropagation()}>
                <div className='auth-header-wrapper'>
                    <h3 className='auth-header-text'>LOG IN OR SIGN UP</h3>
                    <div className='auth-close-wrapper'
                         onMouseDown={(e) => {
                             e.stopPropagation();
                             setLoginOverlay(false);
                         }}>
                        <CancelIcon fill={'white'} className='auth-close-icon' />
                    </div>
                </div>

                {step === 0 && <InkyPenLogin setEmail={setEmail} email={email} setStep={setStep}
                                             setValidEmailError={setValidEmailError}
                                             validEmailError={validEmailError} />}

                {step === 1 && <InkyPenPassword email={email} setStep={setStep} />}

                {step === 2 &&
                    <InkyPenSignUp setValidEmailError={setValidEmailError} email={email} setStep={setStep} />}

                {step === 3 &&
                    <InkyPenForgotPassword fromAuth={true} setStepChangeEmail={setStep}
                                           userEmail={email}
                                           setNotification={setNotification}
                                           ignoreTimer={true}
                    />}

                {typeof step === 'string' &&
                    <InkyPenSignUpFinish setLoginOverlay={setLoginOverlay} step={step} email={email} />}

                {/*<div*/}
                {/*    className={`auth-guide-wrapper auth-modal-cancel-wrapper`}>*/}
                {/*    <AuthGuide />*/}
                {/*</div>*/}

            </div>
        </div>
    );
};

export default InkyPenAuth;