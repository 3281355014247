import apiClient, { ApiModel, InkyApiError, InkyApiStatus } from './InkyAPI';
import { AxiosResponse, CancelTokenSource } from 'axios';
import {
    DashboardCarousel,
    DashboardHomeListEntry,
    DashboardProduct,
    DashboardSeries,
    DashBundle,
    ItemOrder,
    PricingTier,
    RestrictedTerritory,
} from '../Models/Dashboard';
import { IpTagSummary } from '../Models/Dashboard/IpTagSummary';
import { FullImsItem, ImsItem } from '../Components/Dashboard/ImsLink';
import { DashCustomOrderNoteRequest, OrderNoteLogLevel } from '../Models/Dashboard/DashOrder';
import DashUser, {
    DashCustomUserNoteRequest,
    UserNoteLogLevel,
} from '../Models/Dashboard/DashUser';
import DashUserDetails, { DashUserDetailsDTO } from '../Models/Dashboard/DashUserDetails';
import { OrderSummaryAdmin } from '../Models/Order';
import { DashShipmentProviderAccountInfo } from '../Models/Dashboard/DashShipmentProvider';
import { DiscoverItem } from '../Models/DiscoverItem';
import AverageOrderValue from '../Models/Dashboard/Reporting/AverageOrderValue';
import AnalyticsAverageOrderProductQuantity from '../Models/Dashboard/Reporting/AnalyticsAverageOrderProductQuantity';
import AnalyticsUniqueCustomers from '../Models/Dashboard/Reporting/AnalyticsUniqueCustomers';
import TopSellingProduct from '../Models/Dashboard/Reporting/TopSellingProduct';
import TopSellingProducts from '../Models/Dashboard/Reporting/TopSellingProducts';
import { DashMessageItem } from '../Models/Dashboard/DashMessageItem';
import { InkyPortProductInfo, InkyPortProductInfoDTO } from '../Models/Dashboard/InkyPortProductInfo';
import DashboardReport from '../Models/Dashboard/Reporting/DashboardReport';
import { DashUserDetailsWithPurchaseDataDTO } from '../Models/Dashboard/DashUserDetailsWithPurchaseData';
import {
    DashboardSalesCampaign,
    DashboardSalesCampaignDTO,
    DashboardSalesCampaignShortDTO, 
    DashboardSalesProductDTO
} from '../Models/Dashboard/Sales/DashboardSalesCampaign';
import { PaginationRequestParams, PaginationResponseParams } from '../Devon/Pagination/PaginationTypes';
// import { SalePost, SalesResponseData } from '../Components/Sales/SalesModel';


class DashboardAPI {

    /**
     * Get All products that match parameter criteria
     * @param cancelTokenSource
     * @param query
     * @param start
     * @param count
     * @param includeBundles
     * @param publishStatus
     * @param includeRemoved Only relevant if the publish status is null (all products).
     */
    getAllProducts(cancelTokenSource: CancelTokenSource, query?: string,
                   start?: number, count?: number, includeBundles?: boolean, publishStatus?: string,
                   includeRemoved?: boolean, showOnlyOnSale?: boolean, includeChapters?:boolean,distributionType?:DistributionType): Promise<ApiModel<DashboardProduct[]>>  {

      const params = [];

      if(query) params.push(["query", query]);
      if(start) params.push(["start", start]);
      if(count) params.push(["count", count]);
      if(includeBundles) params.push(["includeBundles", includeBundles]);
      if(publishStatus) params.push(["publishStatus", publishStatus]);
      if(includeRemoved) params.push(["includeRemoved", includeRemoved]);
      if(showOnlyOnSale) params.push(["showOnlyOnSale", showOnlyOnSale]);
      if(includeChapters) params.push(["includeChapters", showOnlyOnSale]);
      if (distributionType) {
          switch (distributionType) {
              case "Digital": params.push(["distributionType", 0]); break;
              case "Print": params.push(["distributionType", 1]); break;
              default: break;
          }
      }

      return apiClient.getResponse<DashboardProduct[]>("/dashboard/product", cancelTokenSource, params);
    }
    getAllProductsV2(abortSignal: AbortSignal, query?: string,
                   start?: number, count?: number, includeBundles?: boolean, publishStatus?: string,
                   includeRemoved?: boolean, showOnlyOnSale?: boolean, includeChapters?:boolean,distributionType?:DistributionType): Promise<ApiModel<DashboardProduct[]>>  {

        const params = [];

        if(query) params.push(["query", query]);
        if(start) params.push(["start", start]);
        if(count) params.push(["count", count]);
        if(includeBundles) params.push(["includeBundles", includeBundles]);
        if(publishStatus) params.push(["publishStatus", publishStatus]);
        if(includeRemoved) params.push(["includeRemoved", includeRemoved]);
        if(showOnlyOnSale) params.push(["showOnlyOnSale", showOnlyOnSale]);
        if(includeChapters) params.push(["includeChapters", showOnlyOnSale]);
        if (distributionType) {
            switch (distributionType) {
                case "Digital": params.push(["distributionType", 0]); break;
                case "Print": params.push(["distributionType", 1]); break;
                default: break;
            }
        }

        return apiClient.getResponseV2<DashboardProduct[]>("/dashboard/product", abortSignal, params);
    }

    getProductsForSeries(abortSignal: AbortSignal, seriesId: number): Promise<DashboardProduct[]> {
        return apiClient.getV4(`/dashboard/product/for/series/${seriesId}`, abortSignal);
    }

    getProductById(abortSignal: AbortSignal, id: number): Promise<DashboardProduct> {
        return apiClient.getV4(`/dashboard/product/${id}`, abortSignal);
    }
    



    getAllCarouselItems(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<DashboardCarousel[]>> {
      return apiClient.get<DashboardCarousel[]>("/dashboard/carousel", cancelTokenSource);
    }

    getCarouselItem(cancelTokenSource: CancelTokenSource, itemId: number): Promise<AxiosResponse<DashboardCarousel>> {
      return apiClient.get<DashboardCarousel>("/dashboard/carousel/"+itemId, cancelTokenSource);
    }

    addNewCarouselItem(cancelTokenSource: CancelTokenSource, carousel: DashboardCarousel) {
      return apiClient.post<DashboardCarousel>("/dashboard/carousel/new",carousel, cancelTokenSource);
    }

    alterCarouselItems(cancelTokenSource: CancelTokenSource, carousels: DashboardCarousel[]) {
      return apiClient.post<DashboardCarousel[]>("/dashboard/carousel/alter", carousels, cancelTokenSource);
    }

    reOrderCarouselItems(cancelTokenSource: CancelTokenSource, carouselOrders: ItemOrder[]) {
      return apiClient.post<ItemOrder[]>("/dashboard/carousel/reOrder", carouselOrders, cancelTokenSource);
    }

    removeCarouselItem(cancelTokenSource: CancelTokenSource, id: number) {
      return apiClient.post<Object>("/dashboard/carousel/remove?id="+id, null, cancelTokenSource);
    }

    publishCarouselItem(cancelTokenSource: CancelTokenSource, id: number) {
      return apiClient.post<Object>("/dashboard/carousel/publish?id="+id, null, cancelTokenSource);
    }

    unPublishCarouselItem(cancelTokenSource: CancelTokenSource, id: number) {
      return apiClient.post<Object>("/dashboard/carousel/unpublish?id="+id, null, cancelTokenSource);
    }

    addCustomOrderNote(cancelTokenSource: CancelTokenSource, orderId: number, message: string) {

        // Create paypload
        const payload = new DashCustomOrderNoteRequest();
        payload.orderId = orderId;
        payload.logLevel = OrderNoteLogLevel.Info;
        payload.message = message;

        return apiClient.post<Object>("/dashboard/orders/addCustomNote", payload, cancelTokenSource);
    }

    addCustomUserNote(cancelTokenSource: CancelTokenSource, userId: number, message: string) {

        // Create paypload
        const payload = new DashCustomUserNoteRequest();
        payload.userId = userId;
        payload.logLevel = UserNoteLogLevel.Info;
        payload.message = message;

        return apiClient.post<Object>("/dashboard/users/addCustomNote", payload, cancelTokenSource);
    }


    getSeries(cancelTokenSource: CancelTokenSource, query?: string, count?: number, publishStatus?: string): Promise<AxiosResponse<DashboardSeries[]>> {
      if(query || count || publishStatus) {
        let params = [];
        if(query) {
            params=[...params, ["query", query]];
        }
        if(count) {
          params=[...params, ["count", ""+count]];
        }
        if(publishStatus) {
          params=[...params, ["publishStatus", publishStatus]];
        }

        return apiClient.get<DashboardSeries[]>("/dashboard/series",cancelTokenSource, params);
      }
      else {
        return apiClient.get<DashboardSeries[]>("/dashboard/series", cancelTokenSource);
      }
    }

    getSeriesById(abortSignal: AbortSignal, id: number): Promise<DashboardSeries> {
        return apiClient.getV4<DashboardSeries>(`/dashboard/series/${id}`, abortSignal);
    }

    getBundles(cancelTokenSource: CancelTokenSource, query?: string, count?: number): Promise<AxiosResponse<DashBundle[]>> {
      if(query) {
        let params = [["query", query]];
        if(count) {
          params=[...params, ["count", ""+count]]
        }
        return apiClient.get<DashBundle[]>("/dashboard/bundles",cancelTokenSource, params);
      }
      else {
        return apiClient.get<DashBundle[]>("/dashboard/bundles", cancelTokenSource);
      }
    }

    async getAllSalesCampaigns(fromIndex?:number, count?:number,search?:string,abortSignal?: AbortSignal) {
        let path = "/dashboard/sales/";
        const temp = [];
        if(fromIndex){
            temp.push("fromIndex="+fromIndex)
        }if(count){
            temp.push("count="+count)
        }if(search){
            temp.push("search="+search)
        }
        if (temp.length > 0) {
            path = path + "?" + temp.join('&')
        }

        const result = await apiClient.getV4<ApiModel<{items:DashboardSalesCampaignShortDTO[],fullCount:number}>>(path, abortSignal);

        if (result.status.type === "Success") {
            return result.response;
        }
    }

    async getSalesCampaign(id: number,abortSignal?: AbortSignal) {
        const result= await apiClient.getV4<ApiModel<DashboardSalesCampaignDTO>>("/dashboard/sales/getCampaignById/" + id,abortSignal);

        if (result.status.type === "Success") {
            return result.response;
        }
    }
    async downloadSalesCampaign(id: number){
        return apiClient.getFile('/dashboard/sales/exportAsExcel/' +id, {}, null);
    }
    
    async getSalesCampaignProductsForSeries(id: number,abortSignal?: AbortSignal) {
        const result= await apiClient.getV4<ApiModel<DashboardSalesProductDTO[]>>("/dashboard/sales/getSeriesInfo/" + id,abortSignal);

        if (result.status.type === "Success") {
            return result.response;
        }
    }
    
    
    async alterSalesCampaign(salesCampaign:DashboardSalesCampaign, abortSignal?:AbortSignal){

        const result= await apiClient.postV4<DashboardSalesCampaignDTO,ApiModel<DashboardSalesCampaignDTO>>("/dashboard/sales/alter",salesCampaign.toApi(),abortSignal);

        if (result.status.type === "Success") {
            return DashboardSalesCampaign.createFromApi(result.response);
        }else {
            console.error("Something went wrong saving")
        }
    } 
    async createSalesCampaign(salesCampaign:DashboardSalesCampaign, abortSignal?:AbortSignal){

        const result= await apiClient.postV4<DashboardSalesCampaignDTO,ApiModel<DashboardSalesCampaignDTO>>("/dashboard/sales/create",salesCampaign.toApi(),abortSignal);

        if (result.status.type === "Success") {
            return DashboardSalesCampaign.createFromApi(result.response);
        }else {
            console.error("Something went wrong saving")
        }
    }

    async publishSalesCampaign(salesCampaignId:number, abortSignal?:AbortSignal){

        const result= await apiClient.postV4<null,ApiModel<DashboardSalesCampaignDTO>>("/dashboard/sales/publish/"+salesCampaignId,null,abortSignal);

        if (result.status.type === "Success") {
            return DashboardSalesCampaign.createFromApi(result.response);
        }else {
            console.error("Something went wrong publishing")
        }
    }

    async unpublishSalesCampaign(salesCampaignId:number, abortSignal?:AbortSignal){

        const result= await apiClient.postV4<null,ApiModel<DashboardSalesCampaignDTO>>("/dashboard/sales/unpublish/"+salesCampaignId,null,abortSignal);

        if (result.status.type === "Success") {
            return DashboardSalesCampaign.createFromApi(result.response);
        }else {
            console.error("Something went wrong publishing")
        }
    }

    // createSalesCampaigns(data: SalePost): Promise<AxiosResponse<SalePost>> {
    //     return apiClient.post<SalePost>("/dashboard/sales/create", data);
    // }

    getAllWheelItems(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<DashboardHomeListEntry[]>> {
      return apiClient.get<DashboardHomeListEntry[]>("/dashboard/home/entry", cancelTokenSource);
    }

    alterWheelItems(cancelTokenSource: CancelTokenSource, homeListEntries: DashboardHomeListEntry[]) {
      return apiClient.post<DashboardHomeListEntry[]>("/dashboard/home/entry/alter", homeListEntries, cancelTokenSource);
    }

    removeWheelItem(cancelTokenSource: CancelTokenSource, id: number) {
      return apiClient.post<Object>("/dashboard/home/entry/remove?id="+id, null, cancelTokenSource);
    }

    publishWheelItem(cancelTokenSource: CancelTokenSource, id: number) {
      return apiClient.post<Object>("/dashboard/home/entry/publish?id="+id, null, cancelTokenSource);
    }

    unPublishWheelItem(cancelTokenSource: CancelTokenSource, id: number) {
      return apiClient.post<Object>("/dashboard/home/entry/unpublish?id="+id, null, cancelTokenSource);
    }

    reOrderWheelItems(cancelTokenSource: CancelTokenSource, wheelOrders: ItemOrder[]) {
      return apiClient.post<ItemOrder[]>("/dashboard/home/entry/reOrder", wheelOrders, cancelTokenSource);
    }
    invalidateCache(cancelTokenSource: CancelTokenSource){
        return apiClient.post("/dashboard/cache/invalidateAll",{},cancelTokenSource);
    }

    addNewWheelItem(cancelTokenSource: CancelTokenSource, homeListEntry: DashboardHomeListEntry) {
      console.log("Adding new wheel item");
      console.log(homeListEntry);
      return apiClient.post<DashboardHomeListEntry>("/dashboard/home/entry/new",homeListEntry, cancelTokenSource);
    }

    addHomePageConfiguration(cancelTokenSource: CancelTokenSource, id: number){
        console.log("test " + id);
        return apiClient.post("/dashboard/homepage/top?typeId=" + id, cancelTokenSource);
    }

    addHomePageMessage(cancelTokenSource: CancelTokenSource, message: DashMessageItem){
        return apiClient.post("/dashboard/homepage/messaging/set", message, cancelTokenSource);
    }

    getAllRestrictedTerritories(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<RestrictedTerritory[]>> {
        return apiClient.get<RestrictedTerritory[]>("/dashboard/home/restrictedTerritories", cancelTokenSource);
    }

    getAllPriceTiers(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<PricingTier[]>> {
        return apiClient.get<PricingTier[]>("/dashboard/home/pricing/tiers", cancelTokenSource);
    }

    getIpTagList(cancelTokenSource: CancelTokenSource, query?: string): Promise<AxiosResponse<IpTagSummary[]>> {
        const params = [];
        if (query) {
            params.push(["query", query]);
        }
        return apiClient.get<IpTagSummary[]>("/dashboard/ipTags", cancelTokenSource, params);
    }

    getImsItems(cancelTokenSource: CancelTokenSource, query?: string): Promise<AxiosResponse<ImsItem[]>> {
        const params = [];
        if (query) {
            params.push(["query", query]);
        }
        return apiClient.get<ImsItem[]>("/dashboard/ims", cancelTokenSource, params);
    }

    async getInkyPortProducts(cancelTokenSource: CancelTokenSource, query?: string): Promise<InkyPortProductInfo[]> {
        const params = [];
        if (query) {
            params.push(["query", query]);
        }
        const resp = await apiClient.getResponse<InkyPortProductInfoDTO[]>("/dashboard/ims", cancelTokenSource, params);

        if(resp.status.type === 'Success') {
            return resp.response.map(x => InkyPortProductInfo.createFromApi(x));
        }
    }

    async getInkyPortProduct(cancelTokenSource: CancelTokenSource, sku: string): Promise<InkyPortProductInfo> {
        const resp = await apiClient.getResponse<InkyPortProductInfoDTO>(`/dashboard/ims/${sku}`, cancelTokenSource);

        if(resp.status.type === 'Success') {
            return InkyPortProductInfo.createFromApi(resp.response);
        }
    }

    getImsItemDetails(cancelTokenSource: CancelTokenSource, inventoryId: string): Promise<AxiosResponse<FullImsItem>> {
        return apiClient.get<FullImsItem>("/dashboard/ims/" + inventoryId, cancelTokenSource);
    }

    getOrderSummaries(cancelTokenSource: CancelTokenSource, orderState: string=null, filterFromDate: Date=null, filterOrderSelectTagId: number=null,
                       start: number = null, count: number=null, searchText: string=null): Promise<ApiModel<OrderSummaryAdmin[]>> {
        const params = [];
        if (orderState !== null) {
            params.push(["filterStatus", orderState]);
        }
        if (filterFromDate !== null) {
            params.push(["filterFromDate", filterFromDate.toISOString()]);
        }
        if (filterOrderSelectTagId !== null) {
            params.push(["filterTagIds", filterOrderSelectTagId]);
        }
        if (start !== null) {
            params.push(["start", start]);
        }
        if (count !== null) {
            params.push(["count", count]);
        }
        if (searchText !== null) {
            params.push(["searchText", searchText]);
        }
        //console.log(`params for orderState: ${orderState}, filterFromDate: ${filterFromDate}`);
        //console.log(params);
        return apiClient.getResponse<OrderSummaryAdmin[]>("/dashboard/orders", cancelTokenSource, params);
    }


    getUsers(cancelTokenSource: CancelTokenSource, query: string): Promise<ApiModel<DashUser[]>> {
        return apiClient.getResponse<DashUser[]>("/dashboard/users", cancelTokenSource, [["query", query]]);
    }

    getUsersV2(abortSignal: AbortSignal, query: string): Promise<ApiModel<DashUser[]>> {
        return apiClient.getResponseV2<DashUser[]>("/dashboard/users", abortSignal, [["query", query]]);
    }

    getUserDetails(cancelTokenSource: CancelTokenSource, userId: number): Promise<ApiModel<DashUserDetailsDTO>> {
        return apiClient.getResponse<DashUserDetailsDTO>("dashboard/users/products", cancelTokenSource,[["userId", "" + userId]]);
    }

    getUserDetailsWithPurchaseData(cancelTokenSource: CancelTokenSource, userId: number): Promise<ApiModel<DashUserDetailsWithPurchaseDataDTO>> {
        return apiClient.getResponse<DashUserDetailsWithPurchaseDataDTO>("dashboard/users/"+userId, cancelTokenSource,[]);
    }
    /*
    Only updates user.Roles for now
     */
    setUserDetails(cancelTokenSource:CancelTokenSource, dashBoardUser:DashUserDetailsDTO): Promise<AxiosResponse<ApiModel<DashUserDetailsDTO>>>{
        return apiClient.postV2<ApiModel<DashUserDetailsDTO>>("/dashboard/users/alter/"+dashBoardUser.userId,dashBoardUser, cancelTokenSource);
    }


    /**
     * Filters out new Product Ids and adds them as comped to the user
     * @param cancelTokenSource
     * @param dashUserDetails
     */
    addUserCompensatedProducts(cancelTokenSource: CancelTokenSource, dashUserDetails :DashUserDetailsDTO): Promise<AxiosResponse<any>> {
        return apiClient.postV2<ApiModel<any>>("/dashboard/users/products/compAdd",dashUserDetails, cancelTokenSource);
    }

    /**
     * removes a userPurchaseData entry by id, does not affect any other tables
     * @param cancelTokenSource
     * @param userPurchaseDataId
     */
    removeUserProduct(cancelTokenSource:CancelTokenSource, userPurchaseDataId:number){
        return apiClient.post<ApiModel<any>>("/dashboard/users/products/delete/"+userPurchaseDataId,null, cancelTokenSource);
    }
    
    /**
     * @deprecated SKU's are not used
     */
    addUserProducts(cancelTokenSource: CancelTokenSource, userId: number, skus: string[]): Promise<AxiosResponse<any>> {
        const payload={
            userId: userId,
            productSkus: skus
        };

        return apiClient.post<any>("/dashboard/users/products/add",payload, cancelTokenSource);
    }

    async deleteUser(userId: number, abortSignal?: AbortSignal): Promise<boolean> {
        const res = await apiClient.postV4<null, ApiModel<null>>("/dashboard/users/delete/" + userId, null, abortSignal);
        if( res.status.type !== "Success") {
            throw res.status.message;
        }
        return true;
    }

    ingestProduct(cancelTokenSource: CancelTokenSource, productId: number, remotePath: string) {
        return apiClient.get(`/dashboard/ingest/encode/${productId}`, cancelTokenSource, [
            ["forceReEncode", "true"],
            ["masterRecord", remotePath]
        ]);
    }

    getAnalyticsOrderData(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date){
        console.log(startDate);
        console.log(endDate);

        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);

        return apiClient.get("/analytics/getOrderData", cancelTokenSource, params);
    }
    getAnalyticsOrderDataLegacy(cancelTokenSource: CancelTokenSource){
        return apiClient.get("/analytics/getOrderData", cancelTokenSource);
    }
    getAnalyticsUserData(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date){

        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);

        return apiClient.get("/analytics/getUserData", cancelTokenSource, params);
    }
    getAnalyticsCartData(cancelTokenSource: CancelTokenSource){
        return apiClient.get("/analytics/getCartData", cancelTokenSource);
    }
    getAnalyticsPageMetrics(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date){
        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);

        return apiClient.get("/analytics/getPageMetrics", cancelTokenSource,params);
    }

    getSearchItemsToRemove(cancelTokenSource: CancelTokenSource): Promise<ApiModel<DiscoverItem[]>> {
        return apiClient.getResponse('/search/getOrphans', cancelTokenSource);
    }

    getSearchItemsToAdd(cancelTokenSource: CancelTokenSource): Promise<ApiModel<DiscoverItem[]>> {
        return apiClient.getResponse('/search/getActiveNotUploaded', cancelTokenSource);
    }

    removeInactiveSearchItems(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<DiscoverItem[]>> {
        return apiClient.post<DiscoverItem[]>('/search/removeInactiveFromSearch', null, cancelTokenSource);
    }

    addMissingActiveSearchItems(cancelTokenSource: CancelTokenSource): Promise<AxiosResponse<void>> {
        return apiClient.post<void> ('/search/uploadAllToSearch', null, cancelTokenSource);
    }

    async getShipmentProviderAccountInfo(cancelTokenSource: CancelTokenSource): Promise<ApiModel<DashShipmentProviderAccountInfo>> {
        let response = null;
        response = await apiClient.getResponse('/dashboard/orders/shipping/getAccountInfo', cancelTokenSource);
        return response;
    }

    async GetSalesReport(reportRequest: DashboardReport){

        const response = await apiClient.getFile('/reports/asExcelFile', reportRequest, null);

        return response;
    }

    /* REPORTING */
    async getAverageOrderValue(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date): Promise<ApiModel<AverageOrderValue>> {
        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);
        return apiClient.getResponse('/analytics/averageOrderValue', cancelTokenSource, params
        );
    }
    async getAverageOrderProductQuantity(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date): Promise<ApiModel<AnalyticsAverageOrderProductQuantity>>{
        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);
        return apiClient.getResponse('/analytics/averageOrderProductQuantity', cancelTokenSource, params);
    }
    async getUniqueLoggedInCustomers(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date): Promise<ApiModel<AnalyticsUniqueCustomers>>{
        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);
        return apiClient.getResponse('analytics/uniqueLoggedInCustomers', cancelTokenSource, params);
    }
    async getTopSellingProducts(cancelTokenSource: CancelTokenSource, startDate: Date, endDate: Date,  maxItems?: string): Promise<ApiModel<TopSellingProducts>>{
        const params = [];
        if(startDate) params.push(["start", startDate.toISOString()]);
        if(endDate) params.push(["end", endDate.toISOString()]);
        if(maxItems) params.push("maxItems", maxItems);
        return apiClient.getResponse('analytics/topSellingProducts', cancelTokenSource, params);
    }

    async setRoles(cancelTokenSource: CancelTokenSource, userId: string, role: string[]): Promise<AxiosResponse<any>> {
        const payload={
            Auth0Id: userId,
            NewRoles: role
        };

        return apiClient.post<any>("/dashboard/users/SetRoles",payload, cancelTokenSource);
    }

    async ValidateDashboardSalesImport(form:FormData, abortSignal?:AbortSignal){
        const response = await apiClient.postV4<FormData,ApiModel<{
            item1: DashboardSalesCampaignDTO,
            item2: string[]
        }>>("/dashboard/sales/ValidateFromCsv",form, abortSignal)

        if (response.status.type ==="Success"){
            return response.response;
        } else {
            throw new InkyApiError(response.status.message,InkyApiStatus.Default)
        }
    }
    async ValidateAndImportDashboardSalesImport(form:FormData, abortSignal?:AbortSignal){
        const response = await apiClient.postV4<FormData,ApiModel<{
            item1: DashboardSalesCampaignDTO,
            item2: string[]
        }>>("/dashboard/sales/CreateFromCsv",form, abortSignal)

        if (response.status.type ==="Success"){
            return response.response;
        } else {
            throw new InkyApiError("Something Failed with ValidateTrilogyExport endpoint",InkyApiStatus.Default)
        }
    }
    
    async getPaginatedProducts(query: PaginationRequestParams, abortSignal?: AbortSignal) {
        const result = await apiClient.postV4<PaginationRequestParams,ApiModel<PaginationResponseParams<DashboardProduct>>>("/dashboard/product", query, abortSignal);
        if (result.status.type === "Success") {
            return result.response;
        } else {
            throw new InkyApiError(result.status.message, InkyApiStatus.Default);
        }
    }
}

type DistributionType = "Digital" |"Print";
const dashboard = new DashboardAPI();

export default dashboard;
