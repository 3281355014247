import React from 'react';
import './InkyTOS.scss';
import { ReactComponent as Pink } from '../../Assets/inky-arts/Banner_Pink_Halftone_InkyPen.svg';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/Chevron_InkyPen.svg';
import { InkyPenNavbar } from '../../InkyPenNavbar/InkyPenNavbar';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

const InkyTOS = () => {
    const history = useHistory();

    const goToLastPage = () => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    return (
        <>
            <Helmet>
                <title>{'InkyPen | Terms'}</title>
            </Helmet>
            <div className='inky-tos-container'>
                <InkyPenNavbar />
                <Pink className='inky-tos-pink' />

                <div className='inky-tos-max-wrapper'>

                    <div className='inky-back-wrapper'>
                        <span className='inky-back' onClick={goToLastPage}>
                            <Chevron className='inky-back-icon' />
                            <span className='inky-back-text'>
                                Back
                            </span>
                        </span>
                    </div>

                    <h1 className='inky-title'> InkyPen Terms of Use </h1>

                    <div className='tos-wrapper'>
                        <p>
                            Hello, we’re InkyPen! We provide a subscription-based service which allows users to access,
                            download and view a wide selection of comic books, graphic novels, manga and comic strips
                            (collectively, “Content”) through a software client (the “Client”) available to download on
                            the
                            Nintendo Switch™.

                            These Terms of Use govern your use of the service provided by InkyPen for discovering and
                            enjoying Content available on the Client, including all features and functionalities (the
                            “Service”). By accessing or using the Service you accept and agree to these Terms of Use.
                        </p>

                        <h5>1 Subscription</h5>
                        <div className='divider' />
                        <div className='list'>
                            <p>
                                1.1 In order use our Service you need to set up a ”Subscription”, which will continue on
                                a
                                monthly basis until terminated. To use our Service you need a Nintendo Switch™, access
                                to
                                the
                                Internet, and to provide us with a current, valid, accepted method of payment, as may be
                                updated
                                from time to time, and which may include payment through your account with a third party
                                (a
                                “Payment Method ”).
                            </p>

                            <p>
                                1.2 Your Subscription may start with a free or discounted trial for a limited period as
                                specified during sign-up (a “ Trial Period”). For combinations with other offers,
                                restrictions
                                may apply, as stated in such offer(s). Trial Period eligibility is determined by InkyPen
                                in
                                its
                                sole discretion. InkyPen may limit Trial Period eligibility or duration to avoid abuse
                                of
                                its
                                benevolent generosity.
                            </p>

                            <p>
                                1.3 Your Subscription is linked to an account with InkyPen accessible on the Client
                                (your
                                “Account”), which will store your downloaded Content, as well as your information and
                                preferences.
                            </p>

                            <p>
                                1.4 After your initial monthly subscription term expires, your Subscription will
                                automatically
                                renew for the following month. You can cancel any time after you have signed up, but you
                                will
                                continue to have access to your Account and be charged through the end of your
                                subscription
                                term, at which time you will not be billed again.
                            </p>
                        </div>

                        <h5>2 Billing</h5>
                        <div className='divider' />
                        <div className='list'>
                            <p>
                                2.1 Your Subscription fee (including charges such as taxes and possible transaction
                                fees)
                                will
                                be charged from your Payment Method on a monthly basis, on the calendar day
                                corresponding to
                                the
                                commencement of the paying portion of your Subscription. This payment day may change in
                                certain
                                circumstances, for instance if the payment day falls on a day on which banks are not
                                open
                                for
                                business, or if your Payment Method has not successfully settled, or if you become
                                trapped
                                in a
                                time loop, endlessly replaying the same moment in time over and over again.
                            </p>


                            <p>
                                2.2 For Trial Period Subscriptions, InkyPen may take a Payment Method from you when
                                signing
                                up
                                to make sure you’re a real person eligible to use our Service (and not just a bunch of
                                owls
                                in a
                                trench-coat or something). However, nothing will be charged at that time (with the
                                exception
                                of
                                a de minimis temporary hold that may be placed to make sure your Payment Method is
                                valid).
                                InkyPen will only begin billing your Payment Method for your Subscription fee following
                                the
                                end
                                of the Trial Period, unless you cancel your Subscription prior to the end of your Trial
                                Period.
                                You can access details of your Subscription, including the Subscription fee, the end
                                date of
                                your Trial Period and how to cancel, on your Account.
                            </p>

                            <p>
                                2.3 Adventure Time: All this legalese is getting a bit tiresome, isn’t it? Why not have
                                an
                                adventure! You find yourself in a windowless room. To your left there is a heavy steel
                                door
                                set
                                in the bare stone wall, and at your feet is a rickety-looking trapdoor. What do you do?
                                (ii)
                                Go
                                through the steel door? Go to section 4.8; (ii) Go through the trapdoor? Go to section
                                4.2.
                            </p>
                            <p>
                                2.4 You can update your Payment Methods on your Account. We may also update your Payment
                                Methods
                                using information provided by payment service providers. Following any update, you
                                authorise
                                us
                                to continue to charge the applicable Payment Method(s) to pay for your Subscription fee.
                                You
                                authorise us to charge any Payment Method associated to your Account in case your
                                primary
                                Payment Method is declined or no longer available to us to pay for your Subscription
                                fee.
                                You
                                remain responsible for any uncollected amounts. If a payment is not successfully
                                settled,
                                due to
                                expiration, insufficient funds, supervillain attack or otherwise, and you do not cancel
                                your
                                Subscription, we may suspend your access to the Service until we have successfully
                                charged a
                                valid Payment Method. For some Payment Methods, the issuer may charge you certain fees,
                                such
                                as
                                foreign transaction fees or other fees relating to the processing of your Payment
                                Method.
                                Local
                                tax charges may vary depending on the Payment Method used. Please check with your
                                Payment
                                Method
                                service provider for details.
                            </p>
                            <p>
                                2.5 Adventure Time : You crawl along the rough, dirty floor, feeling for the mysterious
                                metal
                                object you heard skipping away earlier. Your hand touches something! It’s small, and
                                cold,
                                and
                                round… it’s a ring! Just an ordinary, metal ring, dull with tarnish and grime
                                (henceforth,
                                the
                                “OR”). It doesn’t look very fancy – doesn’t have any precious stones set in it, or a
                                magical
                                glow or anything. Disappointed, you stand back up and pocket it just in case. The
                                corridor
                                continues in front of you. Ahead, you can faintly hear something, a low and repetitive,
                                rhythmic
                                noise. It almost sounds like… chanting? What do you do? (i) Keep going? Go to section
                                5.5;
                                (ii)
                                Head back? Go back to section 3.12.
                            </p>
                            <p>
                                2.6 Payments are non-refundable and there are no refunds or credits for partially used
                                billing
                                periods. At any time, and for any reason, InkyPen may provide a refund, discount, or
                                other
                                consideration to any one or more subscribers, at InkyPen’s sole and absolute discretion
                                and
                                without admission of fault or liability. The provision of refunds or credits on one
                                occasion
                                does not entitle you to refunds or credits in the future even for similar reasons, nor
                                does
                                it
                                obligate InkyPen to provide refunds or credits in the future. Nothing in this section
                                affects
                                your statutory rights.
                            </p>
                            <p>
                                2.7 If you are under the age of eighteen years old, you may only use a Payment Method
                                with
                                the
                                approval of a parent or guardian.
                            </p>
                            <p>
                                2.8 Adventure Time : You approach the rabbit, relieved to have finally encountered
                                another
                                living creature, and a cute little bunny at that! But as you reach out your hand to pet
                                it
                                on
                                its soft little head, the rabbit lets out a terrible scream and leaps for your throat,
                                biting
                                your head clean off your shoulders. Oops. Go back to section 2.3.
                            </p>
                        </div>

                        <h5>3 End User License Agreement: Access Conditions and Restrictions</h5>
                        <div className='divider' />
                        <div className='list'>
                            <p>
                                3.1 You must be signed into your Account on the Client in order to access the Content.
                            </p>

                            <p>
                                3.3 Adventure Time : You answer “the wind”. The speaker howls angrily, and shrieks “how
                                does
                                it know my riddles!?” Oh boy, now you’ve made them angry. You can hear them clambering
                                over
                                the ground towards you, muttering and cursing. What do you do? (i) use the OR (if you
                                have
                                it)? Go to section 11.2; (ii) Run away! Go to section 8.2.
                            </p>

                            <p>
                                3.4 The Client and Service are licensed (not sold), and you are granted a limited
                                non-exclusive, non-sublicensable, non-transferable license for the duration of your
                                Subscription to use the Client and Service for your personal non-commercial use only. No
                                copies of the Client, the Service or the Content are allowed.
                            </p>

                            <p>
                                3.5 InkyPen grants you a limited right, for the duration of your Subscription, to
                                download,
                                install and use the Client, and to stream, download, re-download, store, display and
                                view
                                the Content which you are entitled to access via the Service within the limits and
                                restrictions imposed by the functionality of the Client, provided that the Service is
                                available and the Content has not been withdrawn from the Service.
                            </p>

                            <p>
                                3.6 Adventure Time : You go through the ordinary door. My word, it’s dark in here. You
                                trip
                                on the uneven floor and topple over, landing hard. Ouch! As you fall, you put your hands
                                out
                                and knock something down the corridor. It bounces off into the darkness with a metallic
                                ‘ping’. What do you do? (i) Investigate? Go to section 2.5; (ii) Head back? Go back to
                                section 3.12.
                            </p>

                            <p>
                                3.7 You do not have any ownership, commercial use or promotional use rights in the
                                Service
                                or any of the Content.
                            </p>

                            <p>
                                3.8 Children under the age of thirteen are not permitted to use the Service without the
                                approval of a parent or guardian. If you are under the age of thirteen, you are not
                                permitted to register an Account or provide InkyPen with any personal information. If
                                you
                                are between thirteen and eighteen years old, you may only set up an Account with the
                                approval of a parent or guardian, including consent to these Terms of Use on your
                                behalf.
                                You will also require the approval of a parent or guardian to modify your Account. The
                                Client features age restriction filters which can be applied to Account profiles. If you
                                are
                                under eighteen years of age and we discover you have not obtained the approval of a
                                parent
                                or guardian, InkyPen will terminate your access to the Service. Adults are free to do
                                whatever they like, because life just isn’t fair sometimes.
                            </p>

                            <p>
                                3.9 In connection with your use of the Service, you acknowledge and agree that you will
                                not:
                            </p>

                            <p>
                                (i) copy, duplicate, reverse engineer, reverse assemble, otherwise attempt to discover
                                the
                                source code, distribute, transmit, display, perform, record, create a database in
                                electronic
                                or structured form by systematically and/or regularly downloading, caching or storing
                                all or
                                any of the Content and/or the Client, reproduce, recreate, publish, broadcast, license,
                                create derivative works from, transfer, sell, publicly display or perform, modify,
                                prepare
                                any derivative work based on or in any other way commercially exploit any information,
                                software, products, Content, features or services obtained through the Service;
                            </p>

                            <p>
                                (ii) modify, defeat, interfere with, or circumvent any feature of the Client or the
                                Service,
                                including any security or access control mechanism or age restriction features;
                            </p>

                            <p>
                                (iii) access or attempt to access the Service by any means other than through the
                                Client;
                            </p>

                            <p>
                                (iv) register, subscribe, attempt to register or subscribe, unsubscribe, or attempt to
                                unsubscribe, any person for the Service if you are not expressly authorised by such
                                person
                                to do so; or
                            </p>

                            <p>
                                (v) impersonate any person or entity or falsely state or otherwise misrepresent your
                                affiliation with such a person or entity (in particular InkyPen or an InkyPen employee,
                                or
                                any owner or licensor of Content or any of their employees);
                            </p>

                            <p> (vi) use the knowledge gleaned from any of the Content to: </p>
                            <p> a) call forth or attempt to call forth a demon lord or other eldritch creature from the
                                Nether Realms; </p>
                            <p> b) cast or attempt to cast any spells, curses or glamours on any other person for any
                                reason; </p>
                            <p> c) engineer or attempt to engineer a radiation chamber, genetically modified insect or
                                other
                                creature or device for the purpose of gaining superpowers; </p>
                            <p> d) build or attempt to build any kind of superweapon or doomsday device; </p>
                            <p> (vii) post or transmit any message, information, data, text, software or image, or other
                                content that is unlawful, harmful, threatening, harassing, abusive, defamatory,
                                tortuous,
                                vulgar, obscene or otherwise objectionable and/or which may infringe upon another
                                person’s
                                right of privacy or publicity; </p>
                            <p> (viii) seek to deny the existence of the cake – the cake is real, the cake exists; </p>
                            <p>
                                (ix) post or transmit any material that contains a virus or corrupted data;
                            </p>
                            <p>
                                (x) post or transmit any material that infringes any trademark, copyright or other
                                proprietary rights of any person;
                            </p>
                            <p>
                                (xi) kidnap or attempt to kidnap a princess (or any other member of a royal or
                                aristocratic
                                family) and keep them in a castle or similarly fortified building;
                            </p>

                            <p> (xii) use any of the Service or Client features in a manner that adversely affects the
                                availability of its resources to, or enjoyment by, other users (e.g., spamming,
                                trolling); </p>
                            <p> (xiii) delete any legal notices, author attributions or proprietary designations or
                                labels
                                that you upload to any communication feature; </p>
                            <p> (xiv) violate any applicable local, state, provincial, national or international
                                law; </p>
                            <p> (xv) use the Client or the Service for any purpose that is unlawful or prohibited by
                                these
                                Terms of Use; </p>
                            <p> (xvi) get up to any other funny business. </p>
                            <p> 3.10 You may not use the Service in any manner that could damage, disable, overburden or
                                impair InkyPen’s servers or networks, or interfere with any other Subscriber’s use and
                                enjoyment of the Service. You may not gain or attempt to gain unauthorised access to the
                                Client, the Service, other InkyPen services, accounts, computer systems or networks
                                connected to InkyPen through hacking, password mining, black magic or any other means.
                                You
                                may not obtain or attempt to obtain any Content, materials or information through any
                                means
                                not intentionally made available through the Client or the Service. </p>
                            <p> 3.11 InkyPen reserves the right to cancel your Account and Subscription and revoke your
                                license to the Client, the Service and the Content if InkyPen has reason to believe you
                                are
                                using or have used the Service, the Client, and/or the Content in violation of these
                                Terms
                                of Use or in any manner other than for its intended purpose. InkyPen may also restrict
                                or
                                suspend your access to your Account following a notification to you if: (a) InkyPen is
                                required to by law or regulation, or (b) if InkyPen reasonably believes that your
                                Account
                                has been created fraudulently or has been accessed fraudulently, or if anyone uses your
                                Account for unlawful purposes or for other than its intended purpose. </p>
                            <p> 3.12 Adventure Time : You walk down the gloomy corridor and soon find yourself before
                                two
                                doors. One appears to be an unremarkable, ordinary door. The other looks like it has
                                been
                                made out of bones, and is streaked with what you hope is red paint and mud, but you
                                suspect
                                it may be something else. What do you do? (i) Go through the nice, plain, boring door?
                                Go to
                                section 3.6; (ii) Go through the scary-as-heck door? Go to section 3.16; (iii) Try the
                                other
                                corridor? Go back to section 4.8. </p>
                            <p> 3.13 InkyPen reserves the right, at its sole discretion and to the extent permitted by
                                applicable law, to change, modify, add to, or delete any elements comprising the Service
                                including the Content. Your use of any updates, modifications to, or replacement
                                versions
                                of, the Service shall be governed by these Terms of Use and any additional terms you
                                agree
                                to when you install such update, modification, or replacement version. </p>
                            <p>3.14 Adventure Time : Hesitantly, you answer “a fart”. The speaker emits a low growl, and
                                whispers “nooooo, no no no that isn’t it, my precious”. You hear a scrabbling of claws
                                on
                                stone as the speaker skitters toward you, its huge saucer-like eyes glowing. You freeze
                                in
                                terror. Oh darn. Go back to section 2.3.</p>

                            <p>3.15 If InkyPen decides to discontinue the Service, InkyPen will give you at least thirty
                                (30) calendar days' prior notice. In the event that InkyPen changes or discontinues any
                                part
                                of the Service, you acknowledge that you may no longer be able to access Content to the
                                same
                                extent, or at all, as you may have done prior to the change or discontinuation. In no
                                event
                                will InkyPen be liable in any way for the discontinuation of the Service, the removal of
                                or
                                disabling of access to the Service or other materials or for the withdrawal of any
                                Content.
                                Your sole remedy in the event of discontinuation of the Service is to cancel your
                                Subscription.</p>

                            <p>3.16 Adventure Time : You go through the creepy door, which slams shut behind you.
                                Another
                                corridor. Great. The corridor widens into a chamber of some sort, long and
                                high-ceilinged.
                                You see a door at the far end of the chamber. In front of you, blocking your path, sits
                                a
                                little white rabbit, with one floppy ear and an adorable snuffly nose. He looks up at
                                you,
                                blinking. What do you do? (i) Approach it and say hello? Go to section 2.8; (ii) Use the
                                OR
                                (if you have it)? Go to section 4.4; (iii) Use the HHG (if you have it)? Go to section
                                7.4.</p>


                            <h5>4 Availability of Content </h5>
                            <div className='divider' />


                            <p> 4.1 The availability of Content may change from time to time and Subscribers agree that
                                InkyPen may at its discretion add or withdraw Content from the Service at any time, with
                                or
                                without notice. You acknowledge and agree that Content may not be available to view, use
                                or
                                display under certain conditions, such as due to restrictions imposed by owners or
                                licensors
                                of Content or if InkyPen or the owner or licensor of the Content no longer has the legal
                                rights necessary to authorise distribution of that Content. InkyPen may modify or
                                discontinue the availability of any Content at any time. </p>
                            <p> 4.2 Adventure Time : You open the trapdoor. A pitch-black abyss is revealed, with a
                                ladder
                                leading down into the inky depths. You nervously step on the ladder, but before you even
                                start to descend your foot slips, and you fall! As you plummet through the darkness, you
                                reflect on the poor choice you made. Go back to section 2.3. </p>
                            <p> 4.3 The Service may be unavailable during any maintenance or update periods or any power
                                or
                                server outages or for other reasons outside of InkyPen’s control (e.g. alien invasion,
                                robot
                                uprising, intergalactic war or zombie attack). InkyPen will endeavour to ensure that the
                                Client and the Service are consistently available, although InkyPen cannot guarantee
                                this.
                                If InkyPen needs to suspend or restrict access to, or update, the Client, the Service or
                                the
                                Content, InkyPen will endeavour to minimise any disruption to you. </p>
                            <p> 4.4 Adventure Time : You slowly reach into your pocket to slip the ring you found onto
                                your
                                finger. Before you have a chance, however, the rabbit fixes you with its beady eyes,
                                lets
                                out a roar, and charges. You raise your hands to defend yourself but it’s too late, the
                                killer bunny is upon you, and within seconds, it is over. At least your death was quick.
                                Go
                                back to section 2.3. </p>
                            <p> 4.5 Subscribers have the right to download titles of Content for offline access. </p>
                            <p> 4.6 We personalise Content and features as part of the Service, including by showing you
                                recommendations on features, Content and services which might be of interest to
                                you. </p>
                            <p> 4.7 The Service (including any Content offered in connection with it) is available only
                                to
                                residents of countries where we offer our service, which may change from time to time at
                                InkyPen’s sole discretion (such countries, the “Territory”) or as otherwise required by
                                law.
                                Subscribers who have purchased a Subscription within the Territory may access the
                                Service
                                through their Account in any other country within the Territory. </p>
                            <p> 4.8 Adventure Time : You find yourself in a corridor which branches off in two
                                directions.
                                It’s dark and the air smells old and musty, and you can hear the sound of water
                                dripping,
                                like a broken tap. What do you do? (ii) Head left? Go to section 5.2; (ii) Head right?
                                Go to
                                section 3.12. </p>


                            <h5> 5 Intellectual Property </h5>
                            <div className='divider' />

                            <p> 5.1 The Service is owned and operated by InkyPen. The Content, software, visual
                                interfaces,
                                interactive elements, features, information, graphics, design, compilation, computer
                                code,
                                products, look and feel, and all other elements of the Client and the Service (the
                                “Property”), are protected by laws relating to confidentiality and the protection of
                                intellectual property and proprietary rights, including copyright, patent, and trademark
                                laws. All Property, including intellectual property rights therein and thereto, are the
                                property of InkyPen, its subsidiaries or affiliated companies and/or third-party
                                licensors.
                                You may not reproduce, distribute, rent, lease, sell, license, copy, modify, publicly
                                perform or display, transfer, transmit, publish, edit, adapt, prepare derivative works
                                based
                                on, or otherwise use the Property except as expressly authorised by these Terms of Use
                                and
                                applicable law. InkyPen reserves all rights not expressly granted in these Terms of Use.
                                You
                                shall not acquire any right, title or interest in or to the Property, whether by
                                implication
                                or otherwise, except for the limited rights set forth in these Terms of Use. </p>
                            <p> 5.2 Adventure Time : You head left down the corridor, carefully feeling your way along
                                the
                                wall in the darkness. Where are you? How did you get here? Who is going to feed your
                                cat? So
                                many unanswerable questions. You see a pure white light, shining at the end of the
                                corridor.
                                What do you do? (i) Investigate? Go to section 8.5; (ii) Run away! Go back to section
                                4.8. </p>
                            <p> 5.3 Any copying, access, transfer, public performance or communication to the public or
                                other use of the Service, the Client or any Content other than as expressly authorised
                                by
                                applicable law or these Terms of Use shall constitute an act of copyright infringement
                                or
                                infringement of any other applicable intellectual property or proprietary right and a
                                breach
                                of these Terms of Use. In such case, InkyPen may, at its sole discretion and without
                                prior
                                intervention of a court or arbitral body, terminate your Account and Subscription
                                without
                                notice and pursue any rights or remedies available to it. </p>
                            <p> 5.4 If you believe that any content on the Service infringes your intellectual property
                                or
                                proprietary rights, you should contact us and provide sufficient information for us to
                                investigate the claim further. </p>
                            <p> 5.5 Adventure Time : You continue down the corridor. Ahead, you see a light, growing
                                brighter and brighter. Suddenly the corridor ends and you find yourself a huge cavern.
                                There’s bright green grass underfoot, and riotously colourful, bizarre plants bursting
                                from
                                the ground, some rising high above your head. That sound you heard earlier was
                                definitely
                                chanting, and it’s coming from behind some bushes. You carefully pick your way through
                                the
                                undergrowth until you reach a clearing. There, you discover a group of strange little
                                men,
                                standing in a circle. Dressed in stripy shirts and jodhpurs, they have shiny orange skin
                                and
                                bright green hair, and are slowly hopping from foot to foot, chanting something like
                                “Boompa”, or “Poompa” – it’s hard to make out. You take a step forward and a twig snaps
                                loudly under your foot. The little men spin around and glare at you, their faces twisted
                                with rage. Their chanting grows louder, and they start marching towards you menacingly.
                                Panicking, you begin to back up but trip on a fallen branch. Before you can react they
                                leap
                                at you, still chanting, their eyes wide and unblinking. Oh, for it to end like this! Go
                                back
                                to section 2.3. </p>


                            <h5> 6 Customer Support </h5>
                            <div className='divider' />


                            <p>
                                6.1 Please contact the InkyPen Customer Service Gremlins at <a
                                href='mailto:support@inky-pen.com?Subject=InkyHelp'
                                target='_top'>support@inky-pen.com</a> for assistance if the Client and/or Service are
                                not
                                working properly. InkyPen will check the Client and/or Service and try to fix problems
                                with
                                its/their operation as soon as reasonably possible. If InkyPen determines that there is
                                a
                                fault with the operation of the Client and/or Service and isn't able to fix the problem
                                in a
                                reasonable period of time, then you may be entitled to a full or partial refund for any
                                Subscription fees you have paid.
                            </p>

                            <p> 6.2 Adventure Time : You find yourself in a dark cave, filled with a pool of water. On
                                the
                                other side is a barely lit exit. You hear a splashing in the murk, heading towards you.
                                Out
                                of the darkness you hear a voice: “What has we gots here? A lost little goblin, is it?”
                                Terrified, you ask if the speaker knows the way out. “Ah yeessssss, but first it must
                                answer
                                my riddles, it does!” The unseen speaker clears their throat with a phlegmy choking
                                cough.
                                “Voiceless it cries, Wingless flutters, Toothless bites, Mouthless mutters. What is it?”
                                What do you do? (i) Answer “a fart”? Go to section 3.14; (ii) answer “the wind”? Go to
                                section 3.3; (iii) Run away! Go to section 8.2. </p>
                            <p> 6.3 Please note that InkyPen is not responsible for any lack of functionality or failure
                                to
                                provide any part of the Client, the Service or any Content, or any loss of content or
                                data
                                that is due to: (1) your Nintendo Switch™ device(s) or Internet connection; (2) your
                                failure
                                to download the most recent available version of the Client; or (3) your failure to meet
                                the
                                compatibility requirements for the Client. </p>

                            <h5> 7 Warranties </h5>
                            <div className='divider' />


                            <p>7.1 Adventure Time : You hurry down a twisty passageway, with the angry shrieks of the
                                bug-eyed
                                creature echoing behind you. Finally, after running for what seems like hours, you turn
                                a
                                corner
                                to find huge set of double doors, encrusted with gold and covered in strange symbols.
                                Standing
                                guard in front of it is an armoured knight, holding a frankly ridiculous two-handed
                                sword,
                                longer than you are tall. Towering over you, the knight speaks. “Mortal! Welcome. You
                                have
                                journeyed far and overcome many obstacles. But before you can go any further you must
                                answer
                                this riddle. Answer incorrectly, and you shall meet your doom. Do you accept?” Well,
                                you’ve
                                come
                                this far, so you agree. “Good,” says the knight. “Now listen: There is a house. One
                                enters
                                it
                                blind and comes out seeing. What is it?” What do you do? (i) Answer “a library”? Go to
                                section
                                13.4; (ii) Answer “an optometrist”? Go to section 10.2.</p>

                            <p>7.2 InkyPen warrants that the Service will be: (a) of satisfactory quality; (b) fit for
                                purpose;
                                and (c) as described in these Terms of Use. To the extent permitted by law, InkyPen
                                disclaims
                                all other warranties, express, implied, statutory or otherwise.</p>

                            <p>7.3 InkyPen makes no representations or warranties that the Service or your access to and
                                use
                                of
                                the Service will be uninterrupted or error-free, free of viruses, malicious code, or
                                other
                                harmful components, or otherwise secure. InkyPen is not responsible for any damage to
                                your
                                Nintendo Switch™, other hardware, or software, or for any loss of or damage to data that
                                may
                                result from your use of the Service. Some jurisdictions do not allow the exclusion of
                                certain
                                warranties. Accordingly, some of the above exclusions may not apply to you.</p>

                            <p>7.4 Adventure Time : This rabbit looks suspicious: Quickly, you pull the orb out of your
                                pocket.
                                It starts to glow with that pure white light from earlier. The rabbit, startled, bares
                                its
                                teeth
                                at you and growls. You hurriedly throw the orb at the rabbit, and it explodes in a
                                blinding
                                flash of light and a blast of heavenly trumpets. When your eyes readjust to the gloom,
                                the
                                rabbit has disappeared. Phew! You walk across the chamber and go through the door. Go to
                                section
                                6.2.</p>


                            <h5> 8 Limitation of Liability </h5>
                            <div className='divider' />


                            <p>8.1 To the extent permitted by law, you agree that neither InkyPen nor its respective
                                affiliates, subsidiaries, officers, directors, employees, agents, consultants, content
                                providers, licensors, partners or suppliers (" Released Parties"), shall have any
                                liability
                                to
                                you under any theory of liability or indemnity in connection with your use of the
                                Content,
                                Client and/or Service. You agree to hereby and forever release and waive any and all
                                claims
                                you
                                may have against any Released Parties for any and all claims, causes, damages or losses
                                under
                                any theory of liability (including attorneys' fees and associated costs and expenses)
                                arising
                                from your use of the Content, Client and/or Service. Notwithstanding the foregoing, if
                                InkyPen
                                is held liable under these Terms of Use, our total liability to you by any Released
                                Party
                                shall
                                not exceed the fees paid by you to us during your Subscription.</p>
                            <p>8.2 Adventure Time : You turn to run back the way you came, but you slip on the wet stony
                                floor.
                                A clawed hand latches onto your ankle, and you are dragged screaming into the icy depths
                                of
                                the
                                pool. Go back to section 2.3.</p>
                            <p>8.3 You expressly agree as a condition of using the Service that, to the extent permitted
                                under
                                applicable law, neither InkyPen nor the Released Parties nor their respective directors,
                                officers, employees, contractors or agents are responsible or liable to you or anyone
                                else
                                for
                                any loss or injury or any indirect, incidental, consequential, special, exemplary,
                                punitive
                                or
                                other damages under any contract, negligence, strict liability or other theory arising
                                out
                                of or
                                relating in any way to: (i) the use of or inability to use the Content, Client and/or
                                Service;
                                (ii) any content made available via the Client and/or Service; (iii) statements or
                                conduct
                                posted or made publicly available via the Client and/or Service; (iv) negative effects
                                (including, without limitation, changes to physical appearance or the development of
                                supernatural abilities) resulting from the reading of magic spells, runes of power, or
                                other
                                instruments of sorcery made available via the Client and/or Service; (v) any product or
                                other
                                services purchased or obtained through the Client and/or Service; (vi) being transported
                                through
                                time and space to a different era (whether to the past or future) and/or jurisdiction
                                (whether
                                in this or any other timeline or dimension), in the course of using the Client and/or
                                Service;
                                (vii) any action taken in response to or as a result of any information available on the
                                Client
                                and/or Service; (viii) becoming the subject of an investigation by one or more
                                intelligence
                                agencies as a result of any information you may discover regarding top-secret government
                                experiments in the course of using the Client and/or Service; (ix) any damage caused by
                                loss
                                of
                                access to, deletion of, failure to store, failure to back up, or alteration of any
                                content
                                available via the Client and/or Service; or (x) any other matter relating to the
                                Content,
                                Client
                                and/or Service. In no event shall Released Parties' total liability to you for any and
                                all
                                damages, losses, and causes of action (whether in contract, tort, negligence, statutory,
                                or
                                otherwise) exceed the fees paid by you to us during your Subscription. In addition,
                                should
                                your
                                claim arise from a purchase made on or through the service, website and/or app, our
                                total
                                damages shall not exceed the cost of the product or service purchased. No advice or
                                information,
                                whether oral or written, obtained by you through the Content, Client and/or Service
                                shall
                                create
                                any warranty not expressly stated in these Terms of Use.</p>
                            <p>8.4 Some jurisdictions do not allow the exclusion of certain warranties or the limitation
                                or
                                exclusion of liability for certain categories of damages such as incidental or
                                consequential
                                damages. Accordingly, some of the above limitations of this section may not apply to
                                you.</p>
                            <p>8.5 Adventure Time : You continue towards the source of the light, and at the end of the
                                corridor you find a beautiful glowing white orb, with a golden cross sticking out of the
                                top
                                of
                                it. You pick it up and the glowing light vanishes. Darn. You stick the orb (henceforth,
                                the
                                “HHG”) in your pocket. Go back to section 4.8.</p>


                            <h5> 9 Inky’s Tasty Brownie Recipe </h5>
                            <div className='divider' />

                            <p> 9.1 Ingredients: You will need:

                                <div>
                                    i) 150g all-purpose flour;
                                    <br />
                                    ii) 150g unsalted butter;
                                    <br />

                                    iii) 150g brown sugar;
                                    <br />

                                    iv) 100g white sugar;
                                    <br />

                                    v) 100g dark chocolate;
                                    <br />

                                    vi) 50g cocoa powder (unsweetened);
                                    <br />

                                    vii) 4 eggs;
                                    <br />

                                    viii) 1 tsp vanilla extract;
                                    <br />

                                    ix) 1 tsp baking powder;
                                    <br />

                                    x) ½ tsp cinnamon powder;
                                    <br />

                                    xi) 100g pecans, walnuts, and/or hazelnuts, roughly chopped (optional).
                                    <br />
                                </div>

                            </p>

                            <p> 9.2 Instructions

                                <div>
                                    i) Preheat the oven to 180°C / fan 160°C.
                                    <br />
                                    ii) Line a deep baking tray with baking paper, leaving an overhang on two sides.
                                    <br />
                                    iii) Take a medium saucepan, fill with water to about 3cm deep, and heat to a gentle
                                    simmer.
                                    <br />
                                    iv) Combine the flour, cocoa powder, cinnamon and salt in a bowl, then set aside.
                                    <br />
                                    v) Combine the butter and chocolate in a heatproof bowl and set the bowl over the
                                    pan of
                                    water. Stir occasionally until melted, then remove and set aside to cool.
                                    <br />
                                    vi) While waiting for the chocolate mixture to cool, whisk the eggs and sugar
                                    together
                                    in a bowl until fluffy.
                                    <br />
                                    vii) Take the chocolate mixture and fold into the egg mixture, while adding the
                                    vanilla
                                    extract.
                                    <br />
                                    viii) Take the flour mixture and gradually fold into the chocolate egg mixture,
                                    stirring
                                    until you have a smooth batter.
                                    <br />
                                    ix) Optional: Add chopped nuts and stir until evenly distributed.
                                    <br />
                                    x) Pour the batter evenly into the baking tray and pop it in the oven for about 25
                                    minutes (pro-tip: to check if it’s done, get a toothpick or a wooden BBQ skewer and
                                    stick it into the middle. You want it to come back out not quite clean, with just a
                                    little bit of sticky batter clinging to it. Covered in chocolatey batter = not ready
                                    yet. Completely clean = overdone).
                                    <br />
                                    xi) Once done, remove the baking tray from the oven and leave to cool completely.
                                    Once
                                    cool, use the baking paper overhangs to lift out of the tin and cut into squares.
                                    <br />
                                </div>

                            </p>

                            <h5> 10 Privacy </h5>
                            <div className='divider' />

                            <p>10.1 InkyPen respects your privacy and has established certain policies and procedures
                                relating
                                to the collection and use of your personal information in connection with your use of
                                the
                                Service. Our Privacy Policy is available to review on the Client.</p>
                            <p>10.2 Adventure Time : You answer “an optometrist”. The knight sighs and mutters, “damnit,
                                not
                                again.” He reaches over to the wall and presses a hidden button. There is a sound like a
                                set
                                of
                                wind-chimes being thrown down an escalator, and everything vanishes. Go back to section
                                2.3.</p>
                            <p>10.3 To the extent permitted by law, InkyPen accepts no responsibility for any personal
                                information you may accidentally or unintentionally share with any telepathic entities
                                you
                                may
                                encounter in the course of using our Service.</p>


                            <h5> 11 Changes to These Terms of Use </h5>
                            <div className='divider' />

                            <p> 11.1 InkyPen may change any of these Terms of Use at any time. Any significant amendment
                                will be effective thirty (30) calendar days following the earlier of InkyPen either
                                sending
                                an email notice and/or in-Client message to the Subscriber or posting the amendment on
                                the
                                Service. Other amendments will be effective immediately on InkyPen either sending an
                                email
                                notice and/or in-Client message to the Subscriber or posting the amendment on the
                                Service. </p>
                            <p> 11.2 Adventure Time : You jam your hand in your pocket and quickly put the ring on your
                                finger. You can hear the creature getting closer, and you can see its two huge eyes
                                glowing
                                in the dark. It gets closer, closer, close enough to touch. But just when it gets to
                                mere
                                inches from you it stops. “Where has it gone?” The creature cries, confused. You stand
                                absolutely still, holding your breath. Screaming in frustration and looking about
                                wildly, it
                                stalks off behind you. The ring must have made you invisible! Wow, that’s a stroke of
                                luck.
                                You tiptoe around the edge of the water until you reach the exit, and slip away. Go to
                                section 7.1. </p>
                            <p> 11.3 The most current version of these Terms of Use will be available to access via the
                                Client and will automatically apply to all new Subscriptions. Your continued use of the
                                Service constitutes your acceptance of any changes to the Terms of Use. If you do not
                                agree
                                to any change to these Terms of Use, you must cancel your Subscription, delete the
                                Client
                                and discontinue use of the Service. </p>


                            <h5> 12 Severability </h5>
                            <div className='divider' />

                            <p> 12.1 If any provision of these Terms of Use is held to be unlawful, invalid or
                                unenforceable, the remaining provisions of these Terms of Use shall be unimpaired and
                                remain
                                in full force and effect, and to the extent permitted by law, the illegal, invalid or
                                unenforceable provision shall be amended to achieve as closely as possible the intention
                                as
                                expressed in the original term. </p>
                            <p> 12.2 None of the sections commencing “Adventure Time:” form part of these Terms of Use;
                                they
                                are a just a bit of fun. For the avoidance of doubt, neither is the brownie recipe. </p>

                            <h5> 13 Governing Law and Jurisdiction </h5>
                            <div className='divider' />

                            <p> 13.1 To the extent permitted by law, all matters arising out of or relating to these
                                Terms
                                of Use shall be governed by the laws of the Kingdom of Norway, and any actions between
                                the
                                parties arising out of or relating to these Terms of Use shall be brought only in the
                                district courts of Bergen, Norway. </p>

                            <p> 13.2 Upon the occurrence of an event which causes a timeline split, the laws of the
                                Kingdom
                                of Norway in the present timeline shall, to the extent possible, continue to prevail
                                between
                                the parties with respect to these Terms of Use unless otherwise determined by the
                                district
                                courts of Bergen, Norway. </p>
                            <p> 13.3 If, as a result of a cataclysmic cosmic or supernatural event, reality and/or the
                                space-time continuum becomes warped, twisted or otherwise altered such that the Kingdom
                                of
                                Norway no longer exists or ceases to have a functioning system of law, the parties to
                                these
                                Terms of Service agree that, to the extent possible, these Terms of Service shall be
                                governed by the closest extant jurisdiction to that currently governing these Terms of
                                Service. </p>
                            <p> 13.4 Adventure Time : You answer “a library”. “Yes!” the knight shouts, jumping up and
                                down
                                excitedly, armour clattering and clanking. “Yes! Yes! Finally!” The doors swing open and
                                he
                                ushers you through. You step across the threshold and are stunned by what you see.
                                You’re
                                standing in the atrium of a great library, filled with all manner of books and scrolls
                                and
                                tomes, floor after floor of bookshelves stretching so far up you can’t see the top. You
                                grab
                                the nearest book – it’s your favourite graphic novel! “Come! Sit here,” says the knight,
                                ushering you to a big comfy sofa in front of a roaring fire. “Settle down and enjoy
                                yourself. Now, I’m off for a toilet break, I’ve been waiting out there for bloody ages…”
                                As
                                the knight lumbers off to use the facilities, you lean back and crack open the spine of
                                your
                                new book. Lovely. Congratulations! Now go back to section 2.4 and read the rest of these
                                Terms of Use. </p>
                            <p> Nintendo Switch is a trademark of Nintendo. </p>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default InkyTOS;
