import { Genre } from '../Series';
import dayjs, { Dayjs } from 'dayjs';

export class Readable {
    id: number;
    pageCount: number;
    readingProgress: number = 0; //ToDO: Make this nullable?
    isOwned: boolean = false;
    copyright: string;
    isbn: string;
    eisbn: string;
    coverType: string;
    seriesId: number;
    releaseDate: Dayjs;
    printReleaseDate: Dayjs;
    digitalReleaseDate: Dayjs;
    colorType: string;
    imprint: string;
    genres: Genre[];

    toApi(): ReadableDTO {
        return Object.assign({}, this, {
            seriesId: this.seriesId.toString(),
            releaseDate: this.releaseDate.toISOString(),
            printReleaseDate: this.printReleaseDate?.toISOString(),
            digitalReleaseDate: this.digitalReleaseDate?.toISOString()
        });
    }

    static createFromApi(dto: ReadableDTO): Readable{
        if (!dto) return null;
        return Object.assign(new Readable(), dto, {
            seriesId: parseInt(dto.seriesId),
            releaseDate: dayjs(dto.releaseDate),
            printReleaseDate: dto.printReleaseDate ? dayjs(dto.printReleaseDate): null,
            digitalReleaseDate: dto.digitalReleaseDate ? dayjs(dto.digitalReleaseDate): null,
        });
    }
}

export class ReadableDTO {
    id: number;
    seriesId: string;
    pageCount: number;
    readingProgress?: number;
    isOwned?: boolean;
    copyright: string;
    isbn: string;
    eisbn: string;
    coverType: string;
    releaseDate: string;
    printReleaseDate: string;
    digitalReleaseDate: string;
    colorType: string;
    imprint: string;
    genres: Genre[];
}
