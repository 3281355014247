import React, { useState } from 'react';
import './InkypenNintendoCode.scss';
import { ReactComponent as Pink } from '../Assets/inky-arts/Banner_Pink_Halftone_InkyPen.svg';
import InkyLogo from '../Assets/inky-arts/InkyPenLogo.png';
import PreFooter from '../InkyPenFooter/PreFooter';
import InkyAPI from '../../API/InkyAPI';

const InkypenNintendoCode: React.FC = () => {
    const [error, setError] = useState(null);
    const [code, setCode] = useState('');


    function onSubmitCode() {
        InkyAPI.getSubscriptionWithCode(code).then((result) => {
            console.log('url code Success', result.data);
            if (result.data.status.type === 'Success') {
                window.location.replace(result.data.response);
            }
        }).catch((error) => {
            console.log('url code', error.response.data);
            setError(error.response?.data?.status?.message ?? 'Try again later.');
        });
    }

    function onCodeInputChange(e) {
        setCode(e.target.value);
    }

    return (
        <div className={'inkypen-nintendo-code-container'}>
            <Pink className='inc__pink' />
            <img alt={'Logo'} className='inc__logo' src={InkyLogo} />

            <span className='inc__title'>
                Complete Your Purchase
            </span>

            <span className='inc__instructions'>
                Enter the 8-digit code from your device below to finish setting up your subscription.
            </span>


            <input type='text' value={code} onInput={onCodeInputChange} className='inc__code-input'
                   placeholder={'Enter Code'} />

            {error ? <span className='inc__error'>
                {error}
                </span> : null}

            <button className='inc__submit-button' onClick={onSubmitCode}>
                Continue
            </button>

            <div className='inc__pre-footer-wrapper'>
                <PreFooter />
            </div>
        </div>
    );
};

export default InkypenNintendoCode;