import 'react';
import React, { ReactElement } from 'react';
import Log from '../../../InkyPenV1/Services/Logger';

interface GTagManagerProps {
    Id: string;
    scriptId: string;
}

export function GTagManagerTriggerLoad(){
    if(navigator.userAgent === 'RenderBot'){return}

    if (!window['dataLayer']) {
        const gtmScriptNode = document.getElementById('gtm-script-container');

        eval(gtmScriptNode?.textContent);
    }
}

export function GTagManager(props: GTagManagerProps){

    if(navigator.userAgent === 'RenderBot'){return (<></>)}
    Log.info("Loading GTM")

    const noscript: string = `
                            <iframe 
                                role="presentation"
                                src="https://www.googletagmanager.com/ns.html?id=${props.Id}"
                                height="0"
                                width="0"
                                style="display:none;visibility:hidden">
                            </iframe>`;

    const script: string = `
                    (function(w,d,s,l,i){w[l]=w[l]||[];
                        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                        j.async=true;
                        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${props.Id}');`;

    return (<>
        <script id={props.scriptId} defer dangerouslySetInnerHTML={{ __html: script }}></script>
        <noscript dangerouslySetInnerHTML={{ __html: noscript }}></noscript>
    </>);


}
