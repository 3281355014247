import axios, { AxiosError } from 'axios';
import { InkyApiV2 } from './InkyApiV2';

export enum ApiErrorStatus {
    // Returned whenever a network call reports an unauthorized request.
    // Reaction to this error should be to use the refresh-token to
    // get a new access-token, and retry whichever request that failed.
    InvalidAccessToken = "InvalidAccessToken"
}

export class DashApiError extends Error {

    status: ApiErrorStatus;

    constructor(msg: string, status: ApiErrorStatus) {
        super(msg);

        this.status = status;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, DashApiError.prototype);
    }

}

interface TokenResponse {
    access_token: string;
    refresh_token: string;
    expires_in: string;
    token_type: string;
}


export class InkyDashApiV2{
    private static instance: InkyDashApiV2;
    private baseUrl = process.env.REACT_APP_BASE_URL;

    public static shared(): InkyDashApiV2{
        if(!this.instance){
            this.instance = new InkyDashApiV2();
        }
        return this.instance;
    }


    public RefreshToken: string;
    public AccessToken: string;


    async FetchOrderCount(filter?: string): Promise<number> {
        let filters = "";
        if (filter){
            filters = filter;
        }
        const result = await this.get<number>(`/dashboard/orders/count${filters}`);
        return result;
    }

    private get<T>(url: string, params?: string[][]): Promise<T> {
        const fullUrl = new URL(url, this.baseUrl);

        if (params) {
            let param;
            for (param of params) {
                fullUrl.searchParams.append(param[0], param[1]);
            }
        }

        return axios.get(fullUrl.href, { headers: { Authorization: `Bearer ${this.AccessToken}`} })
            .then(function (response) {
                return response.data;
            })
            .catch((error: AxiosError) => {
                if(error.isAxiosError){
                    console.debug("Axios error response", error.response)

                    if(error.response.status == 401){
                        // Unauthorized error.
                        throw new DashApiError("Unauthorized request.", ApiErrorStatus.InvalidAccessToken);
                    }

                    console.log("Acioserror", error.response.status);
                }
                throw error;
            });

    }

    private async post<T>(url: string, payload: object): Promise<T> {
        const fullUrl = new URL(url, this.baseUrl);
        const response = await axios.post<T>(fullUrl.href, payload, { headers: { Authorization: `Bearer ${this.AccessToken}`} });

        return response.data;

    }
}
