import React from 'react';
import './PressKit.scss';
import { InkyPenNavbar } from '../../InkyPenNavbar/InkyPenNavbar';
import { ReactComponent as Chevron } from '../../Assets/inky-icons/Chevron_InkyPen.svg';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ReactComponent as FacebookIcon } from '../../Assets/inky-icons/SoMe-Facebook.svg';
import { ReactComponent as TwitterIcon } from '../../Assets/inky-icons/SoMe-Twitter.svg';
import { ReactComponent as InstagramIcon } from '../../Assets/inky-icons/SoMe-Instagram.svg';
import { ReactComponent as YouTubeIcon } from '../../Assets/inky-icons/SoMe-YouTube.svg';


const Summary = ({ title, text }: { title: string, text: string | JSX.Element }) => {
    return <div className='summary-wrapper'>
        <h4 className='summary-title'>{title}</h4>
        <span className='summary-text'>{text}</span>
    </div>;
};

const PressKit = () => {
    const history = useHistory();

    const goToLastPage = () => {
        if (history.length > 1) {
            history.goBack();
        } else {
            history.replace('/');
        }
    };

    return (
        <>
            <Helmet>
                <title>{'InkyPen | Press'}</title>

                <script
                    async type='text/javascript'
                    src='//static.klaviyo.com/onsite/js/klaviyo.js?company_id=WPikYP'
                ></script>

            </Helmet>
            <div className={'press-kit-container'}>
                <InkyPenNavbar />

                <div className='press-kit-wrapper'>
                    <div className='inky-press-back-wrapper'>
                    <span className='inky-back' onClick={goToLastPage}>
                        <Chevron className='inky-back-icon' />
                        <span className='inky-back-text'>
                            Back
                        </span>
                    </span>
                    </div>
                </div>

                <div className='summary-description-wrapper'>
                    <div className='summary-kit-container'>
                        <div className='summary-kit-wrapper'>
                            <Summary title={'In a sentence:'}
                                     text={' InkyPen™ is an all-you-can-read comic subscription service for Nintendo Switch™ and soon launching on STEAM®.'} />
                            <Summary title={'Developer:'} text={<p> InkyPen AS (With help from our buddies at <Link
                                to={{ pathname: 'https://rain-games.com' }} target={'_blank'}>Rain Games AS</Link>).
                                Both based
                                in Bergen, Norway. </p>} />
                            <Summary title={'Release Date:'}
                                     text={' InkyPen™ was first released on Nintendo Switch™ on December 17th, 2018. STEAM® launch date to be announced.'} />
                            <Summary title={'Platforms:'} text={' Nintendo Switch­™ and soon STEAM®.'} />
                            <Summary title={'Supported Operating Systems:'} text={'Nintendo Switch, Windows.'} />
                            <Summary title={'Subscription Price:'} text={'$7.99 / €7.99 or local equivalent'} />
                            <Summary title={'Assets:'} text={''} />

                            <Link className='download-assets'
                                  to={{ pathname: ' https://drive.google.com/drive/folders/18dUWQdDV6hCIszePxDuoh-TahOaz-d1d?usp=sharing' }}
                                  target={'_blank'}>Download assets</Link>
                        </div>
                    </div>


                    {/*STEAM ANNOUNCEMENT*/}


                    <div className='description-kit-container'>

                        <p className='description-kit-text-strong'>
                            Bergen, Norway - 24/07/2024
                        </p>

                        <h1 className='description-kit-header'>
                            InkyPen Launching on Steam soon!
                        </h1>

                        <div className='divider' />

                        <p className='description-kit-text'>
                            Soon you will be able to read a wide variety of manga and comics from top publishers
                            around the world on your favourite PC gaming platform.
                        </p>

                        <div className='divider' />

                        <img src={'https://cdn.inky-pen.com/statics/InkyPen_Steam.jpg'}
                             className='image-kit' />

                        <p className='description-kit-text'><strong>InkyPen</strong> will be the first comic book
                            service available
                            on the popular PC gaming platform <strong>Steam</strong>. The All-You-Can-Read comic
                            subscription service was also
                            the first of its kind on Nintendo Switch when it was released in December 2018 as one of the
                            first non-game
                            apps on the platform alongside the likes of YouTube and Hulu. InkyPen will now be making
                            their growing catalogue
                            available to the tens of millions of daily users on Steam.</p>

                        <p className='description-kit-text'>InkyPen Subscribers will get FULL access to a huge catalogue
                            of comics available in their region.</p>
                        <p className='description-kit-text'>InkyPen on Steam will have a range of free comics and
                            samples available for everyone, with more added regularly.</p>
                        <p className='description-kit-text'>Users can expect to find many of the best titles from major
                            international publishers, including Manga, Western Comics, French/Belgian comics, Chinese
                            Manhua, webtoons, comic strips and much, much more.</p>
                        <p className='description-kit-text'>InkyPen will be releasing more information on its growing
                            catalogue in the coming weeks.</p>

                        <div className='divider' />

                        <p className='description-kit-text'>
                            “Ease-of-access to the world's best comics is our grand plan for InkyPen. Games, Manga &
                            Comics share a devoted audience,
                            and we’re excited to announce that InkyPen is coming to Steam. We hope gamers and Comic
                            readers will enjoy InkyPen on Steam
                            for years to come.” says <strong>Ronan Huggard, InkyPen Founder & CEO</strong>.
                        </p>

                        <div className='divider' />

                        <p className='description-kit-text'>In addition to an exciting new launch, InkyPen is looking
                            forward to sharing more news in the near future as the service will continue to expand its
                            catalogue of available content.</p>

                        <p className='description-kit-text'>Follow the release of InkyPen on Steam by visiting our store
                            page, follow us on social media, or stay tuned by signing up to our email list for future
                            updates.</p>

                        <a href='https://store.steampowered.com/app/681220/InkyPen/' target='_blank'>
                            <button className='steam-cta-button'>Go to Steam Store Page</button>
                        </a>

                        <div className='divider' />

                        <div className='klaviyo-wrapper'>
                            <div className='klaviyo-form-RHZt9u'></div>
                        </div>

                        <div className='divider' />

                        <div className='socials-wrapper'>

                            <a href='https://www.facebook.com/InkyPenComics' target='_blank'>
                                <div className='socials-background'>
                                    <FacebookIcon className='socials-icon' />
                                </div>
                            </a>

                            <a href='https://x.com/InkyPenComics' target='_blank'>
                                <div className='socials-background'>
                                    <TwitterIcon className='socials-icon' />
                                </div>
                            </a>

                            <a href='https://www.youtube.com/@InkyPen' target='_blank'>
                                <div className='socials-background'>
                                    <YouTubeIcon className='socials-icon' />
                                </div>
                            </a>

                            <a href='https://www.instagram.com/inkypencomics/' target='_blank'>
                                <div className='socials-background'>
                                    <InstagramIcon className='socials-icon' />
                                </div>
                            </a>

                        </div>


                        <div className='article-spacer-small'></div>

                        {/*ABOUT SECTION*/}


                        <h3 className='second-title'>
                            About InkyPen
                        </h3>
                        <p className='description-kit-text'>
                            Hello fellow Manga & Comic enthusiasts!
                            InkyPen was founded in 2017, as a result of wanting something that didn't yet
                            exist.
                            InkyPen CEO Ronan Huggard and games company Rain CEO Peter Meldahl, started drawing the
                            outlines for an All-you-can-read subscription service that allowed comic book and graphic
                            novel fans to read what they want, when they want. The company is developed by a team of
                            comic book enthusiasts from Norway and Luxembourg, who’s continuing focus is building an
                            extensive catalogue of graphic novels and comics available from all over the world,
                            including Manga, U.S comic books and European comics. Our inventory contains top publishers,
                            among them IDW, Dark horse Comics, Valiant and Kodansha.

                            <br />
                            <br />

                            In December 2018 InkyPen launched as one of the three first non-game applications on
                            Nintendo Switch™, directly following in the footsteps of Hulu and Youtube. InkyPen was
                            declared a Gazelle company in 2023.
                        </p>

                        <p className='description-kit-text-italic'>
                            We'd love to hear from you if you have any press related questions whatsoever, feel free to
                            get in
                            touch! - contact Scott Barratt - scott@inky-pen.com
                        </p>

                        <p className='description-kit-text-italic'>
                            Check out <Link to={{ pathname: 'https://www.inky-pen.com' }}
                                            className='inkypen-link'
                                            target={'_blank'}>https://www.inky-pen.com</Link>
                            &nbsp; to see our full catalog!</p>

                        <div className='divider' />

                        <div className='article-spacer' />

                        <h1 className='description-kit-header'>Previous Press Releases</h1>

                        <div className='article-spacer' />

                        {/*KODANSHA ANNOUNCEMENT ARTICLE*/}

                        <p className='description-kit-text-strong'>
                            Bergen, Norway / New York, NY - 24/04/2020
                        </p>

                        <h1 className='description-kit-header'>
                            Kodansha USA Publishing & InkyPen Bring Manga on Demand to Nintendo Switch
                        </h1>

                        <div className='divider' />

                        <p className='description-kit-text'>
                            Starting today you can read Kodansha Comics & VERTICAL manga on Nintendo Switch with InkyPen
                            all-you-can-read subscription service.
                        </p>

                        <div className='divider' />

                        <img src={'https://newdevcdn2.inkypen.co/statics/DEBUT_InkyPen-KCcom_1500x500.jpg'}
                             className='image-kit' />


                        <p className='description-kit-text'>You can now read many of the world's top manga on
                            your <strong>Nintendo
                                Switch</strong> using <strong>InkyPen</strong>,
                            the All-You-Can-Read comic subscription service. InkyPen has joined forces with <strong>Kodansha
                                USA
                                Publishing</strong>, purveyors of some of the world’s most recognized manga properties,
                            to bring
                            All-You-Can-Read manga to its subscription catalog, retailing at 7.99USD per month.</p>

                        <p className='description-kit-text'><strong>Kodansha USA Publishing</strong> houses world famous
                            manga
                            titles such as <strong>Attack on
                                Titan</strong>, <strong>Parasyte</strong>, <strong>Battle Angel Alita</strong>, <strong>FAIRY
                                TAIL</strong> and much, much more. All of
                            which are available immediately on <strong>InkyPen</strong>, with new releases to be added
                            regularly.</p>

                        <p className='description-kit-text'>View the announcement video here: </p>

                        <div className={'youtube-wrapper'}>
                            <iframe src='https://www.youtube.com/embed/sd5Y3uSb984'
                                    className='youtube-embed'
                                    width='1280' height='720'
                                    title='Read Manga on Nintendo Switch with Kodansha Comics and InkyPen [PEGI]'
                                    frameBorder='0'
                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                                    allowFullScreen></iframe>
                        </div>

                        <p className='description-kit-text'>InkyPen launched in December 2018 as one of the few apps on
                            the
                            Nintendo eShop launching soon after YouTube and Hulu. With a diverse range of comics from
                            top
                            publishers such as <strong>IDW, Dark Horse, Archie
                                Comics</strong> and <strong>Valiant</strong>.
                            However while InkyPen had a strong catalog with hundreds of series and over 10,000 comics,
                            manga was
                            not among the list and quickly became the top requested addition from the community.</p>

                        <div className='divider' />

                        <p className='description-kit-text'>“Our readers wanted manga and we listened and there’s simply
                            no
                            bigger and better manga publisher
                            than Kodansha.” says <strong>Ronan Huggard, InkyPen CEO</strong></p>
                        <div className='divider' />
                        <p className='description-kit-text'>“We’re thrilled to further bring the Kodansha
                            Comics &amp; VERTICAL
                            library into the digital space
                            through InkyPen’s digital subscriptions service,” said <strong>Alvin Lu, Kodansha USA
                                Publishing
                                President and CEO</strong>. “We’re excited for not only InkyPen readers, but also
                            Nintendo
                            Switch
                            users to have easy access to a growing number of Kodansha USA Publishing titles through this
                            all-you-can-read service.”,</p>
                        <div className='divider' />

                        <p className='description-kit-text'>Now for the first time, popular comics such as <strong>Archie,
                            Locke &amp; Key,
                            Hellboy</strong> and <strong>Bloodshot</strong>, can be found amongst the likes of
                            fan-favorite
                            manga titles such
                            as <strong>Attack on Titan</strong> and <strong>Fire Force</strong> on a purely
                            all-you-can-read
                            service.</p>
                        <div className='divider' />
                        <p className='description-kit-text'>Whether you’re jumping back in for manga, or just now
                            hearing of
                            InkyPen, it’s an exciting time to
                            check it out. The application is free to download from the Nintendo eShop and has a variety
                            of free
                            comics to read, with all-you-can-read comics and manga for only 7.99USD a month.</p>

                        <div className='divider' />

                        <div className='article-spacer'></div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default PressKit;
