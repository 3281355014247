export default class DashUser
{
    id: number;
    email: string;
    pictureUrl: string;
    verified: boolean;
    roles: string[];
}



export class DashCustomUserNoteRequest {
    userId: number;
    message: string;
    logLevel: UserNoteLogLevel;
}

export enum UserNoteLogLevel {
    Info = "Info",
    Success = "Success",
    Error = "Error",
}