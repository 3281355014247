import React, { ReactElement } from 'react';
import './InkyPenLabeledInput.scss';

interface Props {
    label: string;
    children: ReactElement;
}

const InkyPenLabeledInput = ({ label, children }: Props): JSX.Element => {
    return (
        <>
            <span aria-label={'label for ' + label} role={'label'} id={'label-' + label.replaceAll(' ', '')} className='ui-labeled-input-label'>{label}</span>
            {children}
        </>
    );
};

export default InkyPenLabeledInput;