import React, { ReactElement, useEffect, useState } from 'react';
import './InkyPenPassword.scss';
import InkyPenEmail from '../../InkyPenUIKit/InkyPenEmail/InkyPenEmail';
import InkyPenArrow from '../../InkyPenUIKit/InkyPenArrow/InkyPenArrow';
import { LoginAtom, LoginErrorMessageAtom } from '../../../store/AccountStore';
import { useAtom } from 'jotai';
import { LoginOverlayVisible, LoginType, LoginTypeStore } from '../../../store/OverlayStore';
import InkyPenCheckBox from '../../InkyPenUIKit/InkyPenCheckBox/InkyPenCheckBox';
import { AuthGuide } from '../InkyPenAuth';
import { Platform, PlatformAtom } from '../../../store/GenericAtoms';

const InkyPenPassword = ({ email, setStep }): ReactElement => {
    const [password, setPassword] = useState('');
    const [info, login] = useAtom(LoginAtom);
    const [errormessage, setErrorMessage] = useAtom(LoginErrorMessageAtom);
    const [isChecked, setIsChecked] = useState(false);
    const [, setOverlayVisible] = useAtom(LoginOverlayVisible);
    const [platform] = useAtom(PlatformAtom);
    // const [loginType] = useAtom(LoginTypeAtom);

    const onLoginClicked = (): void => {
        login({ email: email, password: password });
    };

    const onEnter = (e) => {
        if (e.key === 'Enter') {
            onLoginClicked();
        }
    };

    useEffect(() => {
        if (info === "LoggedIn" && LoginTypeStore.getLoginType() !== LoginType.Checkout) {
            setOverlayVisible(false);
        }
    }, [info]);

    return (
        <div className='auth-login-container'>
            <span className='auth-login-title'>Welcome back!</span>
            <span className='auth-login-email-label'>
                Email
            </span>


            <div className='auth-login-email-wrapper'>
                <InkyPenEmail value={email} active={false}
                              onChange={(value): void => setPassword(value)}
                              placeHolder={'Email'}
                />
            </div>

            <span className='auth-login-email-label auth-login-password-label' style={{ marginTop: '10px' }}>
                Password
            </span>

            <div className='auth-login-email-wrapper auth-password-type'>
                <InkyPenEmail value={''} active={true} type={'password'}
                              onChange={(value): void => {
                                   setPassword(value);
                                   setErrorMessage(false);
                               }}
                              placeHolder={'Password'}
                              onEnterPushed={onEnter}
                              withFocus
                              errorMessage={errormessage === false ? '' : errormessage} />
            </div>

            {platform == Platform.Steam &&
            <div className='link-account-steam'>
                <InkyPenCheckBox
                    ariaLabel={'checkbox for terms of use'}
                    label={
                        <>
                            Link my Steam and Inkypen accounts.
                        </>
                    }
                    checked={isChecked}
                    onChange={() => setIsChecked(isChecked => !isChecked)} />
            </div>
            }


            <div className='auth-modal-forgot-password-wrapper'>
                <span className='auth-modal-cancel-learn auth-modal-forgot-password' onClick={() => setStep(3)}
                      style={{ cursor: 'pointer' }}>FORGOT PASSWORD?</span>
                {/*<InkyPenArrow text={'Reset Password'} onClick={setStep(3)}/>*/}
                <div className='auth-login-forgot-wrapper'>
                    <InkyPenArrow active={password !== ''} onClick={onLoginClicked} text={'Continue'} />
                </div>
            </div>

            <div className={'auth-guide-wrapper-top auth-modal-cancel-wrapper'}>
                <AuthGuide />
            </div>

        </div>
    );
};

export default InkyPenPassword;
