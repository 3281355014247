import React from 'react';
import './SeriesPoster.scss';
import { getSeriesColor } from '../../../utils/utils';
import useElementSize from '../../Hooks/useElementSize';
import { InkypenColors } from '../../../utils/constants';
import InkyMageImg, { InkyMageImgDimension } from '../../../Devon/Components/InkyMageImg/InkyMageImg';
import { ImagePlaceholder } from '../../Assets/ImagePlaceholder';

interface Props {
    src: string;
    alt?: string;
    ratio?: number;
    color?: string;
    hover?: boolean;
    isGray?: boolean;
    preloadImage?: boolean;
    isOnSale?: boolean;
    /// Image width when column is small (Will fallback to extra-small if not set.)
    s?:  InkyMageImgDimension;
    /// Image width when column is medium (Will fallback to small if not set.)
    m?: InkyMageImgDimension;
    /// Image width when column is large (Will fallback to medium if not set.)
    l?: InkyMageImgDimension;
    /// Image width when column is extra-large (Will fallback to large if not set.)
    xl?:    InkyMageImgDimension;
}

const SeriesPoster = ({ src, alt, ratio = 140, hover, color, isGray, preloadImage, s = {width: 700}, m, l = {width: 400}, xl = {width: 600} ,isOnSale}: Props) => {
    // Pass the color which you got from the server
    const [seriesRef, { width, height }] = useElementSize();

    return (
        <div className={`ui-series-poster-container ${hover !== false && 'ui-series-poster-container-hover'}`}
             style={{ paddingTop: ratio + '%' }}>
            <div className='ui-series-poster-container-2'>
                {isOnSale && <span className={`is-free-series`}>Free</span>}
                <div className='ui-series-poster-wrapper' ref={seriesRef}>
                        {src ?
                                <InkyMageImg className='ui-series-poster-image'
                                             src={src}
                                             alt={alt}
                                             preloadImage={preloadImage}
                                             s={s}
                                             m={m}
                                             l={l}
                                             xl={xl}
                                             width={1200}
                                             height={960}
                                             style={{
                                                 width: src === '' && 'auto',
                                                 filter: (isGray === false || color === 'none') && 'grayscale(0%)',
                                             }}
                                />
                            :
                            <ImagePlaceholder alt={alt} />
                        }
                    <div
                        style={{
                            backgroundColor: src ? getSeriesColor(color) : InkypenColors.none,
                        }}
                        className='colorize' />

                </div>
            </div>
        </div>
    );
};

export default React.memo(SeriesPoster);
