import React from 'react';
import './InkyPenContactSupport.scss';
// import { Link, useHistory } from 'react-router-dom';

interface Props {
    text?: string;
}

const InkyPenContactSupport = ({ text }: Props): JSX.Element => {

    return (
        <div className='support-wrapper'>
            <span className='support-need-help-text'>Need help?</span>
            <span onClick={() => {
                window.open("/support-center", "_blank");
            }}
                  className='support-contact-support-text'>{text ?? 'Contact Support'}</span>
        </div>
    );
};

export default InkyPenContactSupport;