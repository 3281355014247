import React, { lazy, Suspense, useEffect } from 'react';
import './InkyPenApp.scss';
import './Reboot.scss';

import { Route, Switch, useLocation } from 'react-router-dom';
import { useAtom, useAtomValue } from 'jotai';
import { AccessTokenAtom, AccountInfoAtom, LoginStatusAtom } from '../store/AccountStore';
import { CheckoutOverlayVisible, LoginOverlayVisible } from '../store/OverlayStore';
import { PaymentIntentAtom, PaymentMethodAtom, ShowPaymentProcessingOverlayAtom } from '../store/PaymentStore';
import { CartIdAtom, GetAddToCartOverlayBox } from '../store/CartStore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { useLocalStorage } from './Services/localstorage/useLocalStorage';
import { LocalStorageKeys } from './Services/localstorage/LocalStorageKeys';
import { AnalyticsIdAtom, AnalyticsUserProxyAtom } from '../store/AnalyticsStore';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { Md5 } from 'ts-md5';
import apiClient from '../API/InkyAPI';
import AnalyticsEvent from '../Models/Analytics/AnalyticsEvent';
import { Helmet } from 'react-helmet';
import Log from './Services/Logger';
import { UseStripeWrapperAtom } from '../store/LoadStripeStore';
import { GTagManagerTriggerLoad } from '../Devon/Components/GTagManager/GTagManager';
import SubscriptionModal from './SubscriptionModal/SubscriptionModal';
import InkyFAQ from './StaticPages/InkyFAQ/InkyFAQ';
import InkyTOS from './StaticPages/InkyTOS/InkyTOS';
import InkyPrivacy from './StaticPages/InkyPrivacy/InkyPrivacy';
import InkyPenPublish from './StaticPages/InkypenPublish/InkypenPublish';
import PressKit from './StaticPages/PublishKit/PressKit';
import InkypenNintendoCode from './InkypenNintendoCode/InkypenNintendoCode';

const InkypenArticle = lazy(
    () => import('./InkyPenArticle').then(module => ({ default: module.InkyPenArticle })),
);
const InkypenAccountVerified = lazy(
    () => import('./InkypenAccountVerified/InkypenAccountVerified').then(module => ({ default: module.InkypenAccountVerified })),
);
// const InkypenCheckout = lazy(
//     () => import('./InkyPenCheckout').then(module => ({ default: module.InkyPenCheckout })),
// );
const InkypenHome = lazy(
    () => import('./InkyPenHome').then(module => ({ default: module.InkyPenHome })),
);
const InkypenSeries = lazy(
    () => import('./Routes/Series/InkyPenSeries'),
);
const InkypenAuth = lazy(
    () => import('./InkyPenAuth/InkyPenAuth'),
);
const InkypenProduct = lazy(
    () => import('./Routes/Product/InkyPenProduct'),
);
const InkypenSearch = lazy(
    () => import('./InkyPenSearch'),
);
const CookiesConsent = lazy(
    () => import('./CookiesConsent/CookiesConsent'),
);
const AddedToCartOverlay = lazy(
    () => import('./InkyPenAddedToCartOverlay/AddedToCartOverlay').then(module => ({ default: module.AddedToCartOverlay })),
);

export function InkyPenApp(props): JSX.Element {

    const renderLoading = (): JSX.Element => <> </>;

    /**
     * All overlays except Login + Force mount necessary atoms.
     * @constructor
     */
    function Overlays() {
        const [checkoutOverlay] = useAtom(CheckoutOverlayVisible);
        const [addToCartOverlay] = useAtom(GetAddToCartOverlayBox);

        //The following need to be referenced so that we can trigger an onMount.
        const [AccessToken] = useAtom(AccessTokenAtom);
        const [CartId] = useAtom(CartIdAtom);

        //For the payment overlays (TODO: Check if these are truly necessary)
        const [paymentIntent] = useAtom(PaymentIntentAtom);
        const [paymentMethod] = useAtom(PaymentMethodAtom);
        const [showPaymentProcessingOverlay] = useAtom(ShowPaymentProcessingOverlayAtom);


        Log.debug('Render overlays...');
        return (
            <React.Fragment>
                {addToCartOverlay === true && <Suspense fallback={renderLoading()}><AddedToCartOverlay /></Suspense>}
                {/*{checkoutOverlay && <Suspense fallback={renderLoading()}><InkypenCheckout /></Suspense>}*/}
            </React.Fragment>
        );
    }

    /**
     * Login overlay only. Leave this outside the stripe wrapper or it breaks the login.
     * @constructor
     */
    function LoginOverlay(): JSX.Element {
        const loginOverlay = useAtomValue(LoginOverlayVisible);
        if (loginOverlay) {
            return (
                <Suspense fallback={renderLoading()}><InkypenAuth /></Suspense>
            );
        }
        return null;
    }

    function CookiesAndConsent() {
        const [CookiesConsentValue, setCookiesConsentValue] = useLocalStorage(LocalStorageKeys.CookiesConsent.toString(), false);

        return CookiesConsentValue ? null : (
            <Suspense fallback={renderLoading()}><CookiesConsent
                setCookiesConsentValue={setCookiesConsentValue} /></Suspense>
        );
    }

    function AccountInfo() {
        const [accountInfo, setAccountInfo] = useAtom(AccountInfoAtom);

        const updateVerification = (event) => {
            if (event.key === 'email_verified') {
                const accountInfoTemp = { ...accountInfo, verified: true };
                setAccountInfo(accountInfoTemp);
                window.removeEventListener('storage', updateVerification);
                localStorage.removeItem('email_verified');
            }
        };

        useEffect(() => {
            window.addEventListener('storage', updateVerification);

            return () => {
                window.removeEventListener('storage', updateVerification);
            };
        }, [accountInfo]);

        Log.debug('Render Account Info...');
        return null;
    }

    //ToDO: Since there is no UI logic here, Could this be turned into an atom?
    function Analytics() {
        const [analyticsId, setAnalyticsId] = useAtom(AnalyticsIdAtom);
        const [analyticsUserProxy, setAnalyticsUserProxy] = useAtom(AnalyticsUserProxyAtom);
        const googleAnalyticsID = process.env.GA_TRACKING_ID;
        const location = useLocation();
        const [accountInfo] = useAtom(AccountInfoAtom);
        const [loginStatus] = useAtom(LoginStatusAtom);

        function generateSessionId(): string {
            const randomId = uuidv4();
            const time = dayjs(Date.now()).unix(); // 1548381600
            const hash = Md5.hashStr(randomId.toString() + time.toString());
            return hash;
        }

        if (navigator.userAgent !== 'RenderBot') {
            // react-ga4 apparently have no way to defer,
            // so we are explicitly holding the initialization back.
            const timer = setTimeout(() => {
                Log.info('Loading GA');
                // Do something if the user agent is not RenderBot
                // ReactGA.initialize(googleAnalyticsID);
            }, 2000);
        }

        function sendEvent() {
            if (navigator.userAgent !== 'RenderBot') {
                apiClient.sendEvent(accountInfo, new AnalyticsEvent(analyticsId.toString(),
                    'PAGE_VIEW',
                    location.pathname, window.location.href, analyticsUserProxy.toString())).then(res => {
                    //console.log(res.data);
                });
            }
        }

        useEffect(() => {
            Log.debug('ANALYTICS SESSION:', analyticsId);
            if (analyticsId === '') {
                Log.debug('Generating Analytics Id.');
                setAnalyticsId(generateSessionId());
            }
        }, [analyticsId]);
        useEffect(() => {
            if (localStorage.getItem('userProxy') === null)
                setAnalyticsUserProxy(generateSessionId());
        }, [analyticsUserProxy]);

        useEffect(() => {
            Log.error('Location changed');
            console.log(accountInfo);

            if (loginStatus === 'Unknown')
                return null;

            else if (analyticsId.toString() !== '' && loginStatus.toString() !== 'Unknown') {
                sendEvent();
                return null;
            }

        }, [location, analyticsId, loginStatus]);


        return null;
    }


    function useQuery(): URLSearchParams {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    /**
     *
     * @constructor
     */
    function MainOrPage(): JSX.Element {
        const query = useQuery();
        // if (query.has('page_id')) {
        //     Log.info(`Found page_id=${query.get('page_id')}`);
        //     return <InkypenArticle overrideUrl={`%3Fpage_id=${query.get('page_id')}`} />;
        //     //return <Redirect to={`%3Fpage_id=${query.get('page_id')}`} />;
        // }
        // if (query.has('p')) {
        //     Log.info(`Found p=${query.get('p')}`);
        //     return <InkypenArticle overrideUrl={`%3Fp=${query.get('p')}`} />;
        //     // return <Redirect to={`%3Fp=${query.get('p')}`} />;
        // }

        return <InkypenHome />;
    }

    Log.info('Render Whole App...');
    useEffect(() => {
        GTagManagerTriggerLoad();
    }, []);


    return (<React.Fragment>
        <Helmet>
            <title>{'InkyPen'}</title>
        </Helmet>
        <AccountInfo />
        <LoginOverlay />
        <SubscriptionModal />
        <StripeWrapper>
            <Overlays />
            {/* ONLY ADD OVERLAYS HERE. */}
            {/* DO NOT ADD NAVBAR HERE. THAT IS SUPPOSED TO BE IN THE VIEWCOMPONENTS */}
            <Suspense fallback={renderLoading()}>
                <Switch>
                    <Route
                        path='/search'
                        render={(): JSX.Element => <InkypenSearch />} />
                    <Route
                        exact
                        path='/faq'
                        render={(): JSX.Element => <InkyFAQ />} />

                    <Route
                        exact
                        path='/publish'
                        render={(): JSX.Element => <InkyPenPublish />} />

                    <Route
                        exact
                        path='/press-kit'
                        render={(): JSX.Element => <PressKit />} />

                    <Route
                        exact
                        path='/terms-of-use'
                        render={(): JSX.Element => <InkyTOS />} />

                    <Route
                        exact
                        path='/privacy'
                        render={(): JSX.Element => <InkyPrivacy />} />
                    {/*<Route*/}
                    {/*    exact={true}*/}
                    {/*    path='/'*/}
                    {/*    render={(): JSX.Element => <InkyPenHome />} />*/}
                    <Route
                        exact
                        path='/'
                        render={(): JSX.Element => <MainOrPage />} />
                    <Route
                        exact
                        path='/product/:pid'
                        render={(): JSX.Element => <InkypenProduct />} />
                    <Route
                        exact
                        path='/series/:sid'
                        render={(): JSX.Element => <InkypenSeries />} />

                    <Route
                        exact
                        path='/subscribe'
                        render={(): JSX.Element => <InkypenNintendoCode />} />

                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path='/news'*/}
                    {/*    render={(): JSX.Element => <InkypenNews />} />*/}
                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path='/news/articles/:aid'*/}
                    {/*    render={(): JSX.Element => <InkypenArticle />} />*/}
                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path='/:year/:month/:day/:aid'*/}
                    {/*    render={(): JSX.Element => <InkypenArticle />} />*/}
                    {/*<Route*/}
                    {/*    exact*/}
                    {/*    path='/library'*/}
                    {/*    render={(): JSX.Element => <InkypenLibrary />} />*/}
                    <Route
                        path='/main/emailConfirmed'
                        render={(): JSX.Element => <InkypenAccountVerified />} />
                    {/* NB: Since this is parameterized it will consume all routes. As such the 404 is rendered from */}
                    {/* within the InkyPenArticle component */}
                    <Route
                        path='/:aid'
                        render={(): JSX.Element => <InkypenArticle />} />
                </Switch>
            </Suspense>
            {/* Cookies overlay should be after content for SEO reasons */}
            <CookiesAndConsent />
        </StripeWrapper>
        {/*<Analytics />*/}
        {/*<GTagManager scriptId='gtm-script-container' Id={'GTM-N4GJK84'} />*/}
    </React.Fragment>);
}

/**
 * Only load Stripe when needed as defined by UseStripeWrapperAtom
 */
function StripeWrapper(props: React.PropsWithChildren<{}>): JSX.Element {
    const useStripeWrapper = useAtomValue(UseStripeWrapperAtom);

    const [stripe, setStripe] = React.useState<Promise<Stripe> | null>(null);
    React.useEffect(() => {
        if (useStripeWrapper) {
            Log.info('Loading stripe...');
            setStripe(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
        } else {
            Log.info('No need for stripe yet...');
        }
    }, [useStripeWrapper]);

    Log.info('render StripeWrapper...');
    return <Elements stripe={stripe}>{props.children}</Elements>;
}
