import React from 'react';
import InkypenLogoGrey from '../Assets/inky-icons/Inky_InkyPen.svg';
import './ImagePlaceholder.scss';

interface InkypenImagePlaceholderProps {
    alt: string;
}

export function ImagePlaceholder(props: InkypenImagePlaceholderProps){

    return (
        <div className={"ImagePlaceholder"}>
            <img src={InkypenLogoGrey} alt={props.alt} />
        </div>
    );

}
