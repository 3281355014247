import React, { ReactElement, useEffect, useState } from 'react';
import './InkyPenSignUp.scss';
import InkyPenEmail from '../../InkyPenUIKit/InkyPenEmail/InkyPenEmail';
import InkyPenArrow from '../../InkyPenUIKit/InkyPenArrow/InkyPenArrow';
import useLazyFetch from '../../Hooks/useLazyFetch';
import CreateUserPayload from '../../../Models/CreateUserPayload';
import apiClient from '../../../API/InkyAPI';
import InkyPenCheckBox from '../../InkyPenUIKit/InkyPenCheckBox/InkyPenCheckBox';
import { Link } from '../../../Devon/Components/InkyLink';
import { useAtom } from 'jotai';
import { LoginAtom, LoginStatus } from '../../../store/AccountStore';
import { LoginType, LoginTypeStore } from '../../../store/OverlayStore';
import KlaviyoClient from '../../../Components/Klaviyo/KlaviyoManager';
import AnalyticsEvent from '../../../Models/Analytics/AnalyticsEvent';
import { AnalyticsIdAtom, AnalyticsUserProxyAtom } from '../../../store/AnalyticsStore';
import { AuthGuide } from '../InkyPenAuth';

enum ErrorType {
    USERNAME,
    EMAIL,
    PASSWORD,
    CONFIRM_PASSWORD,
    NONE
}

const InkyPenSignUp = ({ setStep, email, setValidEmailError }): ReactElement => {
    const [user, setUser] = useState({
        Username: email.split('@')[0],
        Email: email,
        Password: '',
        ConfirmPassword: '',
    });
    const [sessionId] = useAtom(AnalyticsIdAtom);
    const [errorHandler, setErrorHandler] = useState({ type: ErrorType.NONE, errorMessage: '', key: '' });
    const [isChecked, setIsChecked] = useState(false);
    const [showStrength, setShowStrength] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState([
        { status: 1, text: 'Needs to be minimum 8 characters' },
        { status: 1, text: 'Needs at least one special character or number' },
        { status: 1, text: 'Needs at least one uppercase character' },
        // { status: 1, text: 'Needs at least one number character' },
    ]);
    const [analyticsUserProxy] = useAtom(AnalyticsUserProxyAtom);
    const [loginStatus, login] = useAtom(LoginAtom);
    const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/;

    const {
        data,
        loading,
        error,
        fetch,
    } = useLazyFetch(() => apiClient.signup(new CreateUserPayload(user.Email, user.Password)), true);

    function trackAnalyticsSignup(): void {
        apiClient.sendEvent(null,new AnalyticsEvent(sessionId.toString(), "SIGN_UP", window.location.pathname, "", analyticsUserProxy.toString()))
            .then(res => {
                console.log(res);
            }).catch(e => {
            console.error(e);
        });
    }

    useEffect(() => {
        if (error) {
            // console.log('error', error);
            // console.log('error', error.response.data);

            const errorResponse = error.response.data;

            if (!errorResponse) {
                return
            }

            if (errorResponse.includes('PasswordStrengthError')) {
                setErrorHandler({ type: ErrorType.PASSWORD, errorMessage: 'Password is too weak.', key: 'Password' });

            } else if (errorResponse.includes('User exists')) {
                setErrorHandler({ type: ErrorType.USERNAME, errorMessage: 'The user already exists.', key: 'Email' });

            } else if (errorResponse.includes('Please provide a real email address.') || errorResponse === 'That does not look like a real email') {
                setValidEmailError('Please enter a valid email.');
                setStep(0);
                setErrorHandler({ type: ErrorType.EMAIL, errorMessage: 'Please enter a valid email.', key: 'Email' });
            }
        } else {
            console.log('error', error);
        }


    }, [error]);

    useEffect(() => {
        if (data) {
            // setStep(user.Username);
            // setLoginType(LoginType.Normal);
            LoginTypeStore.setLoginType(LoginType.Signup);
            login({ email: user.Email, password: user.Password });
            KlaviyoClient.register(user.Email);
            if(data === 200)
            {
                trackAnalyticsSignup();
            }
        }

    }, [data, user]);

    useEffect(() => {
        if (loginStatus === "LoggedIn") {
            setStep('Finished');
        }
    }, [loginStatus]);

    const onInputValueChanged = (value: string, key: string): void => {
        setErrorHandler({ type: ErrorType.NONE, errorMessage: '', key: '' });
        setUser(user => ({ ...user, [key.replace(/\s/g, '')]: value }));

        if (key === 'Password') {
            const updatedPasswordStrength = [...passwordStrength];

            if (value.length <= 7) {
                updatedPasswordStrength[0].status = 1;
            } else {
                updatedPasswordStrength[0].status = 0;
            }

            if (!format.test(value)) {
                updatedPasswordStrength[1].status = 1;
            } else {
                updatedPasswordStrength[1].status = 0;
            }


            if (!/[A-Z]/.test(value)) {
                updatedPasswordStrength[2].status = 1;
            } else {
                updatedPasswordStrength[2].status = 0;
            }


            // if (!/\d/.test(value)) {
            //     updatedPasswordStrength[3].status = 1;
            // } else {
            //     updatedPasswordStrength[3].status = 0;
            // }

            setPasswordStrength(updatedPasswordStrength);
        }
    };

    const onSubmitClicked = (): void => {
        // if (user.Password !== user.ConfirmPassword) {
        //     setErrorHandler({
        //         type: ErrorType.CONFIRM_PASSWORD,
        //         errorMessage: 'Password and confirm password don\'t match.',
        //         key: 'Confirm Password',
        //     });
        //     return;
        // }
        fetch();
    };

    const onEnter = (e) => {
        if (e.key === 'Enter' && isChecked) {
            onSubmitClicked();
        }
    };

    const onPasswordFocus = (): void => {
        setShowStrength(true);
    };

    const onPasswordBlur = (): void => {
        setShowStrength(false);
    };

    return (
        <div className='auth-login-container'>
            <span className='auth-login-title'>Create account</span>
            <span className='auth-signup-text'>Enter and confirm your password to get started with your new account!</span>

            {/* Removed { label: 'Username', placeholder: 'Username' } */}
            {[{ label: 'Email', placeholder: 'Email' },
                { label: 'Password', placeholder: 'Password' }].map((item, index) => {
                return <React.Fragment key={item.label}>
                    <span className='auth-signup-email-label'>
                        {item.label}
                    </span>


                    <div className='auth-login-email-wrapper'>
                        <InkyPenEmail active={item.label !== 'Email'} value={user[item.label]}
                                      onEnterPushed={onEnter}
                                      onFocus={item.label === 'Password' && onPasswordFocus}
                                      onBlur={item.label === 'Password' && onPasswordBlur}
                                      withFocus={item.label === 'Password' && true}
                                      type={item.label === 'Password' || item.label === 'Confirm Password' ? 'password' : ''}
                                      errorMessage={item.label === errorHandler.key ? errorHandler.errorMessage : ''}
                                      onChange={(value) => onInputValueChanged(value, item.label)}
                                      placeHolder={item.placeholder}
                                      instructions={item.label === errorHandler.key ? [] : []} />

                        {/*{index === 1 && showStrength && <ul className='signup-password-strength-wrapper'>*/}

                        {index === 1 && <div className={'password-strength'}>
                            Include at least 2 of the followings:
                        </div>}

                        {index === 1 && <ul className='signup-password-strength-wrapper'>
                            {passwordStrength.map((item, index) => {
                                return <li
                                    className={`signup-password-strength-list-item ${item.status === 1 && 'signup-password-strength-list-item-red'}`}
                                    key={index}>{item.text}</li>;
                            })}
                        </ul>}

                    </div>
                </React.Fragment>;
            })}

            <div className='signup-accept-container'>
                <InkyPenCheckBox
                    ariaLabel={'checkbox for terms of use'}
                    label={
                        <>
                            I am 13 years of age or older and agree to
                            the <Link target={'_blank'} to={'/terms-of-use'}>
                            <strong><u>Terms of Use</u></strong></Link> and&nbsp;
                            <Link target={'_blank'} to={'/privacy-policy'}><strong><u>Privacy Policy</u></strong></Link>.
                        </>
                    }
                    checked={isChecked}
                    onChange={() => setIsChecked(isChecked => !isChecked)} />
            </div>

            <div className='auth-login-submit-wrapper-outer'>
                <div className='auth-login-submit-wrapper'>
                    <InkyPenArrow active={user.Password !== '' && isChecked}
                                  onClick={onSubmitClicked}
                                  text={loginStatus === "LoggingIn" ? 'Wait' : loginStatus === "LoggedIn" ? 'Finish' : 'SIGN UP'} />
                </div>
            </div>

            <div className={'auth-guide-wrapper-top auth-modal-cancel-wrapper'}>
                <AuthGuide />
            </div>
        </div>
    );
};

export default InkyPenSignUp;


// const passwordErrors = [];
// if (errorHandler.key !== undefined) {
//     if (errorHandler.errorMessage === 'Password is too weak.') {
//         const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
//         if (user.Password.length <= 7) {
//             passwordErrors.push('Needs to be minimum 8 characters');
//         }
//         if (!format.test(user.Password)) {
//             passwordErrors.push('Needs at least one special character');
//         }
//         if (!/[A-Z]/.test(user.Password)) {
//             passwordErrors.push('Needs at least one uppercase character');
//         }
//         if (!/\d/.test(user.Password)) {
//             passwordErrors.push('Needs at least one number character');
//         }
//
//         if (passwordErrors.length === 0 && errorHandler.key == 'Password') {
//             errorHandler.key = '';
//         }
//     }
// }