export const InkypenColors = {
    blue: 'rgb(60, 200, 225)',
    green: 'rgb(20, 191, 161)',
    navy: 'rgb(0, 26, 112)',
    orange: 'rgb(255, 159, 55)',
    pink: 'rgb(246, 117, 153)',
    purple: 'rgb(201, 100, 207)',
    red: 'rgb(245, 51, 63)',
    yellow: 'rgb(255, 230, 0)',
    none: null,
    grayscale: 'rgba(255, 230, 0, 0)',
}